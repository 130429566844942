/* jshint esversion: 6 */
const BaseManager = require('./BaseManager');
const CreditScore = require('../Models/CreditScore');

class CreditScoreManager extends BaseManager {
    constructor() {
        super();
        this.scores = [];
    }

    add(score) {
        const newScore = new CreditScore(score);
        this.scores.push(newScore);
    }

    getAverageCreditScore() {
        return Math.floor(this.scores.reduce((acc, score) => {
            return acc + score.score
        }, 0)/this.scores.length);
    }

    getLatest() {
        let latestDate = 0;
        let recentScore;
        this.scores.forEach(score => {
            if (score.date > latestDate) {
                latestDate = score.date;
                recentScore = score.score;
            }
        });
        return recentScore;
    }

    getSortedByScores(dir) {
        if (dir !== 'asc' && dir !== 'desc') {
            return "Invalid parameter";
        }
        return this.scores.sort((scoreA, scoreB) => {
            if (dir === 'asc') {
                return scoreA.score - scoreB.score
            }
            if (dir === 'desc') {
                return scoreB.score - scoreA.score
            }
            return 0;
        });
    }
}

module.exports = CreditScoreManager;
