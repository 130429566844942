
function parseQueryParams(querystring){
    return querystring.split('&').reduce(function (qs, query) {
        const chunks = query.split('=');
        const key = chunks[0];
        let value = decodeURIComponent(chunks[1] || '');
        const valueLower = value.trim().toLowerCase();
        if (valueLower === 'true' || value === 'false') {
            value = Boolean(value);
        } else if (!isNaN(Number(value))) {
            value = Number(value);
        }
        return (qs[key] = value, qs);
    }, {});
}

module.exports = {
    parseQueryParams,
};
