const { DeepCompare } = require('./DeepCompare');

class PlanDeepCompare extends DeepCompare {
    constructor(updatedObj, originalObj) {
        super(updatedObj, originalObj);
        this.dontConvertThese = [
            'id',
            'date_created',
            'meeting_date',
            'nudge_email_two_weeks',
            'nudge_email_four_weeks',
            'nudge_email_eight_weeks',
            'quarter_review_meeting_date',
            'second_birth_date'
        ];

        this.payload.plan = {};
        this.payload.pages = [];

        if (updatedObj.plan.id === undefined) {
            this.payload.pages = originalObj.pages;
            this.savePayload = true;
        }

        this.deepCompare(updatedObj, originalObj);
    }

    deepCompareObj(updatedObj, originalObj, index = null, arrayElement) {
        Object.keys(updatedObj).forEach(key => {
            if (key === 'plan' || key === 'pages') {
                this.tracker = key;
                this.deepCompare(updatedObj[key], originalObj[key], index, arrayElement);
                return false;
            }

            if (this.tracker === 'pages') {
                if (this.arrayTracker !== index) {
                    if (key === 'title') {
                        if (updatedObj[key].includes('_') && originalObj[key].includes('_')) {
                            originalObj.data = JSON.stringify(originalObj.data);
                        }
                    }
                    if (updatedObj[key] !== originalObj[key]) {
                        this.payload[this.tracker].push(updatedObj);
                        this.savePayload = true;
                        this.arrayTracker = index;
                        return false;
                    }
                }
            }

            if (this.tracker === 'plan') {
                if (!this.dontConvertThese.includes(key)) {
                    if (!isNaN(parseFloat(updatedObj[key])) || !isNaN(parseFloat(originalObj[key]))) {
                        updatedObj[key] = parseFloat(updatedObj[key]);
                        originalObj[key] = parseFloat(originalObj[key]);
                    }
                }

                if (updatedObj[key] !== originalObj[key]) {
                    this.payload[this.tracker][key] = updatedObj[key];
                    this.savePayload = true;
                    return false;
                }
            }
        });
    }

    deepCompareArrayOfObj(updatedObj, originalObj) {
        updatedObj.forEach((element, index) => {
            if (originalObj[index] !== undefined) {
                this.deepCompare(element, originalObj[index], index);
            } else {
                this.payload[this.tracker].push(element);
                this.savePayload = true;
                return false;
            }
        });
    }
}

module.exports = {
    PlanDeepCompare
};
