<script>
    import { intakeDataStore } from '../../../shared/store';
    import accounting from '../../../shared/accounting_config';

    import NumberInput from '../../UXElements/NumberInput.svelte';

    export let wizard;

    // Init the values in store
    $: petGoals = wizard.goalManager.getByTypes(['pet_care'], 'activeGoals');

    $: petGoals.forEach(goal => {
        goal.planTotalAmount = getAmountByDate(goal.planMonthAmount, goal.planMonthsRemaining);
    });

    // Amount saved for up front costs
    // [monthly pet expenses budget] X [number of months from goal] = [estimated lump-sum amount saved for upfront costs
    // Done inside function to keep a good pattern incase future calculations become complex
    function getAmountByDate(amount, monthsRemaining) {
        if (monthsRemaining === 0) {
            // Dividing by 0 should give the answer of:
            // You need this much money in less then a month
            monthsRemaining = 1;
        }
        return amount * monthsRemaining;
    }

    // Reactivly update the store whenever a goal's new contribution is changed for the plan side bar
    // Doing this in the PlanGenerator level doesn't work...?
    $: $intakeDataStore.goals = wizard.goalManager.convertToDataStore();
</script>

<div id="pet-care" class="stop-page-break life-money">
    <h2>Pets</h2>
    <ul>
        <li>
            Being a pet owner isn’t cheap!  Depending on the animal and if you choose to adopt or go through a breeder, the upfront costs can be fairly significant.  After covering the upfront costs, you need to consider the ongoing costs of care, food, and vet expenses.
        </li>
        <li>
            Here’s a breakdown of how to save for becoming a pet owner and practice covering the additional costs.
            <ul>
                {#each petGoals as petGoal}
                    <!-- [monthly pet expenses budget] X [number of months from goal] = [estimated lump-sum amount saved for upfront costs -->
                    <li class="grid-row grid-4 hide-print">
                        <div class="input-group">
                            <NumberInput type="dollar" bind:value={petGoal.planMonthAmount} />
                        </div>
                        <span>x</span>
                        <div class="input-group">
                            <NumberInput type="months" bind:value={petGoal.planMonthsRemaining} />
                        </div>
                        <span>= {accounting.formatMoney(petGoal.planTotalAmount)}</span>
                    </li>
                    <li class="show-print">
                        {accounting.formatMoney(petGoal.planMonthAmount)} x {petGoal.planMonthsRemaining} months = {accounting.formatMoney(petGoal.planTotalAmount)}
                    </li>
                {/each}
            </ul>
        </li>
    </ul>
</div>
