<script>
    import { location } from 'svelte-spa-router';

    export let additionalClass = false;
    export let disabled = null;
    export let max = null;
    export let min = null;
    export let placeholder = null;
    export let prependCompares = null;
    export let required = false;
    export let type = null;
    export let retirementSlide = null;
    export let value = null;
    export let id = '';

    let hidePrint = false;
    let step = '0.01'
    let monthText = 'months';

    $: if (type === 'months' || type === 'target') {
        step = "1"

        if (type === 'months') {
            if (parseInt(value) === 1 || parseInt(value) === -1) {
                monthText = 'month';
            } else {
                monthText = 'months';
            }
        }
    }

    $: if ($location.split('/')[1] === 'plan') {
        hidePrint = true;
    }
</script>

{#if type === 'dollar'}
    <div class="number hide-print">
        <span class="prepend">
            $
        </span>
        <input
            {id}
            class="{required ? 'required' : ''} {hidePrint ? 'hide-print' : ''} {additionalClass ? 'adjust-input' : ''}"
            disabled={disabled}
            type="number"
            step={step}
            min={min}
            max={max}
            placeholder={placeholder}
            bind:value="{value}" />
    </div>
{:else if prependCompares}
    <div class="number number-both hide-print">
        <div class="prepend">
            <span class="input-group-text">{prependCompares}</span>
        </div>
        <input
            id={id}
            class="{required ? 'required' : ''} {hidePrint ? 'hide-print' : ''} {additionalClass ? 'adjust-input' : ''}"
            disabled={disabled}
            type="number"
            step={step}
            min={min}
            max={max}
            placeholder={placeholder}
            bind:value="{value}" />
        {#if type === 'percent'}
            <div class="append">
                %
            </div>
        {/if}
    </div>
{:else if type === 'months'}
    <div class="number-append hide-print">
        <input
            id={id}
            class="{required ? 'required' : ''} {hidePrint ? 'hide-print' : ''} {additionalClass ? 'adjust-input' : ''}"
            disabled={disabled}
            type="number"
            step={step}
            min={min}
            max={max}
            placeholder={placeholder}
            bind:value="{value}" />
        <div class="append">
            <span class="input-group-text">{monthText}</span>
        </div>
    </div>
{:else if type === 'percent'}
    <div class="number-append hide-print {retirementSlide ? 'number-append-retirement' : ''}">
        <input
            id={id}
            class="{required ? 'required' : ''} {hidePrint ? 'hide-print' : ''} {additionalClass ? 'adjust-input' : ''}"
            disabled={disabled}
            type="number"
            step={step}
            min={min}
            max={max}
            placeholder={placeholder}
            bind:value="{value}" />
        <div class="append">
            %
        </div>
    </div>
{:else}
    <input
        id={id}
        class="{required ? 'required' : ''} {hidePrint ? 'hide-print' : ''} {additionalClass ? 'adjust-input' : ''}"
        disabled={disabled}
        type="number"
        step={step}
        min={min}
        max={max}
        placeholder={placeholder}
        bind:value="{value}" />
{/if}

<style lang="stylus" scoped>
    .number
        display grid
        grid-template-columns 1fr 10fr
        width 100%

        &-append
            display grid
            grid-template-columns 10fr 2fr

            &-retirement
                grid-template-columns 4fr 1fr

            .append
                align-self center
                padding-left 0.5em
                text-align left

        &-both
            grid-template-columns 2fr 10fr 2fr

            .append
                align-self center
                padding-left 0.5em
                text-align left

        .field
            grid-columns 1 / span 2

        .prepend
            align-self center
            padding-right 0.5em
            text-align right

    .adjust-input
        display inline-block
</style>
