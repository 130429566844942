<script>
    import { location, link } from 'svelte-spa-router';
    import { intakeDataStore, showNotes } from '../../shared/store';

    let sidebarLocation = $location;

    $: if ($location.split('/')[3]) {
        sidebarLocation = `/${$location.split('/')[1]}/${$location.split('/')[2]}`;
    }
</script>

<div class="w90">
    <h3 class="panel-title">
        Intake
    </h3>

    <h4 class="panel-title">
        <a class="see-all" href="{sidebarLocation}/income" use:link>
            Income
        </a>
    </h4>
    <h4 class="panel-title">
        <a class="see-all" href="{sidebarLocation}/credit-scores" use:link>
            Credit Scores
        </a>
    </h4>
    <h4 class="panel-title">
        <a class="see-all" href="{sidebarLocation}/assets" use:link>
            Assets
        </a>
    </h4>
    <h4 class="panel-title">
        <a class="see-all" href="{sidebarLocation}/liabilities" use:link>
            Liabilities
        </a>
    </h4>
    <h4 class="panel-title">
        <a class="see-all" href="{sidebarLocation}/risks" use:link>
            Risk Management
        </a>
    </h4>
    <h4 class="panel-title">
        <a class="see-all" href="{sidebarLocation}/expenses" use:link>
            Expenses
        </a>
    </h4>
    <h4 class="panel-title">
        <a class="see-all" href="{sidebarLocation}/goals" use:link>
            Goals
        </a>
    </h4>
    <h4 class="panel-title">
        <a class="see-all" href="{sidebarLocation}/non-negotiables" use:link>
            Non-Negotiables
        </a>
    </h4>
</div>

<style lang="stylus" scoped>
    .w90
        margin 0 auto

    .panel-title
        margin 1.5em 0
        padding 0 1em

    .panel-title + .panel
        margin-bottom: 0

    .panel-title .see-all::after
        content "View"
        width 3em

    textarea
        height 20em

    @media screen and (max-width: 1024px)
        .w90
            display none

</style>
