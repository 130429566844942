const dayjs = require('dayjs');

const inputNumbersOnly = () => {
    document.querySelectorAll('input[type=number]').forEach(numberInput => {
        numberInput.addEventListener('keypress', (evt) => {
            if (evt.which < 45 || evt.which > 57) {
                evt.preventDefault();
            }
        });
    });
};

const promptUponClose = (e) => {
    e.preventDefault();
    e.returnValue = '';
}

const scrollIntoView = (selector) => {
    const element = document.getElementById(selector);

    if (element) {
        element.scrollIntoView();
    }
}

const setBrowserTitle = (location, name = null, date = null) => {
    const locationArray = location.split('/');

    switch (locationArray[1]) {
    case 'new-client':
        document.title = 'Create Client';
        break;
    case 'clients':
        document.title = 'Loading...';
        if (name) {
            document.title = `Edit ${name}'s Client Info`;
        }
        break;
    case 'intake':
        document.title = 'Loading...';
        if (name) {
            document.title = `${name}'s Intake`;
        }
        break;
    case 'plan':
        document.title = 'Loading...';
        if (name) {
            date = dayjs(date).format('MMMM D, YYYY');
            if (date === 'Invalid Date') {
                date = dayjs().format('MMMM D, YYYY');
            }
            document.title = `${name} Financial Plan - ${date}`;
        }   
        break;
    default:
        document.title = 'Financial Gym Plan Generator';
        break;
    }
}

module.exports = {
    inputNumbersOnly,
    promptUponClose,
    scrollIntoView,
    setBrowserTitle
};