<script>
    import PayOffCreditCardGoal from './DebtManagementGoal/PayOffCreditCardGoal.svelte';
    import CreditScoreGoal from './DebtManagementGoal/CreditScoreGoal.svelte';
    import TrainerCommentComponent from './TrainerCommentComponent.svelte';

    export let wizard;

    $: creditScore = wizard.getCreditScore();
    $: totalLiabilities = wizard.getLiabilities(['charge_card', 'credit_card','auto_loan','family','medical','mortgage','personal_loan','tax','other'], 'all');
    $: totalCreditCardDebt = wizard.getLiabilities(['charge_card', 'credit_card'], 'all');
</script>

{#if creditScore >= 680 && totalCreditCardDebt > 0}
    <PayOffCreditCardGoal />
    <TrainerCommentComponent type="pay_off_credit_card_goal_trainer_comments" />
{/if}

{#if (!creditScore || creditScore < 680) && totalLiabilities === 0}
    <CreditScoreGoal type="{'establish'}" />
{/if}

{#if creditScore >= 700 && totalCreditCardDebt === 0}
    <CreditScoreGoal type="{'maximize'}" />
    <TrainerCommentComponent type="maximize_credit_score_goal_trainer_comments" />
{/if}
