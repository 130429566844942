<script>
    import { push, link } from 'svelte-spa-router';

    import * as api from '../../shared/api_access';
    import { loggedIn, userDataStore, trainerDataStore, rerouteLink } from '../../shared/store';

    import FeatherIcon from '../UXElements/FeatherIcon.svelte';

    let email = '';
    let password = '';
    let error = false;
    let loggingIn = false;
    let errorMessage = 'Wrong email or password. Please try again.';

    const submitLoginForm = async () => {
        let loginResponse = null;
        loggingIn = true;

        loginResponse = await api.login(email, password);

        // handles when the server is down
        if (!loginResponse) {
            error = true;
            loggingIn = false;
            errorMessage = "We're currently experiencing high levels of traffic. Please try again in a moment.";
            password = '';
            return;
        }

        if (loginResponse.status >= 400) {
            error = true;
        } else {
            const { data: userData } = await api.getUserInfo();
            if (userData.trainer === null && userData.client === null) {
                error = true;
                localStorage.removeItem('token');
                errorMessage = `Your account is not valid yet. Please contact <a href="mailto:tech@fingyms.com">tech@fingyms.com</a>.`;
            } else if (userData.trainer === null && userData.client !== null) {
                error = true;
                localStorage.removeItem('token');
                errorMessage = `Sorry, clients aren't allowed to access Training Zone yet! Please contact your trainer or send a request to <a href="mailto:tech@fingyms.com">tech@fingyms.com</a>.`;
            } else {
                const { status: trainerStatus, data: trainerData } = await api.request('get', `trainers/${userData.trainer}/`);
                if (trainerStatus >= 400) {
                    error = true;
                    errorMessage = `There is something wrong with your account. Please contact <a href="mailto:tech@fingyms.com">tech@fingyms.com</a>.`;
                } else {
                    $userDataStore = userData;
                    $trainerDataStore = trainerData;
                    error = false;
                    loggedIn.set(true);
                    if ($rerouteLink) {
                        push($rerouteLink);
                        $rerouteLink = null;
                    } else {
                        push('/');
                    }
                }
            }
        }

        if (error) {
            password = '';
        }

        loggingIn = false;
    };
</script>

<div class="column column-full container w90">
    <div class="page-content clearfix">
        <div class="form-wrapper">
            <form>
                <h1 class="page-title">Log In</h1>
                {#if error}
                    <div class="alert">
                        {@html errorMessage}
                    </div>
                {/if}
                <div class="field">
                    <label for="email">Email address</label>
                    <input
                        type="email"
                        class="form-control"
                        id="email"
                        aria-describedby="email-help"
                        bind:value="{email}" />
                    <small id="email-help" class="form-text text-muted">
                        Please enter a valid email address
                    </small>
                </div>
                <div class="field">
                    <label for="password">Password</label>
                    <input
                        type="password"
                        class="form-control"
                        id="password"
                        bind:value="{password}" />
                </div>
                <button
                    type="submit"
                    class="button small primary"
                    title="Log In"
                    id="submit-button"
                    disabled="{loggingIn}"
                    on:click|preventDefault="{e => submitLoginForm(e)}">
                    {#if loggingIn}
                        <span class="spin"><FeatherIcon icon="aperture" /></span>
                    {:else}Log In{/if}
                </button>
                <p>
                    <a
                        class="reset-pass"
                        href="/forgot-password"
                        title="Forgot Your Password?"
                        use:link >
                        Forgot your password?
                    </a>
                </p>
            </form>
        </div>
    </div>
</div>
