<script>
    import { intakeDataStore } from '../../../shared/store';
    import accounting from '../../../shared/accounting_config';

    import NumberInput from '../../UXElements/NumberInput.svelte';

    export let wizard;

    // Init the values in store
    $: weddingGoals = wizard.goalManager.getByTypes(['wedding'], 'activeGoals');

    $: weddingGoals.forEach(goal => {
        goal.planMonthAmount = getSaveForDate(goal.planTotalAmount, goal.planMonthsRemaining);
    });

    // Amount needed to be saved per month to reach goal in time
    // Ballpark Wedding Budget = [median wedding budget in US] / [number of months]  = [monthly amount to save towards goal]
    // Done inside function to keep a good pattern incase future calculations become complex
    function getSaveForDate(amount, monthsRemaining) {
        if (monthsRemaining === 0) {
            // Dividing by 0 should give the answer of:
            // You need this much money in less then a month
            monthsRemaining = 1;
        }
        return accounting.unformat(accounting.toFixed(amount / monthsRemaining, 2));

    }

    // Reactivly update the store whenever a goal's new contribution is changed for the plan side bar
    // Doing this in the PlanGenerator level doesn't work...?
    $: $intakeDataStore.goals = wizard.goalManager.convertToDataStore();
</script>

<div id="wedding" class="stop-page-break life-money">
    <h2>Wedding</h2>
    <ul>
        <li>
            You’d like to begin saving for a wedding! The average cost of a wedding in the US is about
            $35,000. This cost is obviously variable depending on size, location, etc., and your total
            cost will be dependent on whether or not your parents/family would help with some of the
            expenses.
        </li>
        <li>
            The first step for wedding planning is establishing your total budget and the timing for
            your wedding. Based on your specific needs, here's a breakdown of how to save for this
            goal:
            <ul>
                {#each weddingGoals as weddingGoal}
                    <li class="grid-row grid-5 hide-print">
                        <span>Ballpark Wedding Budget =</span>
                        <!-- [estimated wedding budget] / [months until date of wedding] = [monthly planAmount to save] -->
                        <div class="input-group">
                            <NumberInput type="dollar" bind:value={weddingGoal.planTotalAmount} />
                        </div>
                        <span>/</span>
                        <div class="input-group">
                            <NumberInput type="months" bind:value={weddingGoal.planMonthsRemaining} />
                        </div>
                        <span>= {accounting.formatMoney(weddingGoal.planMonthAmount)}</span>
                    </li>
                    <li class="show-print">
                        {accounting.formatMoney(weddingGoal.planTotalAmount)} / {weddingGoal.planMonthsRemaining}
                        months = {accounting.formatMoney(weddingGoal.planMonthAmount)}
                    </li>
                {/each}
            </ul>
        </li>
    </ul>
</div>
