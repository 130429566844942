<script>
    import { intakeDataStore } from '../../../shared/store';
    import accounting from '../../../shared/accounting_config';

    import NumberInput from '../../UXElements/NumberInput.svelte';

    export let wizard;

    // Init the values in store
    $: childrenGoals = wizard.goalManager.getByTypes(['family_planning'], 'activeGoals');

    $: childrenGoals.forEach(goal => {
        goal.planTotalAmount = getAmountByDate(goal.planMonthAmount, goal.planMonthsRemaining);
    });

    // Amount saved by date
    // [monthly childcare expenses budget ($1,000 as suggestion)] X [number of months from goal] = [estimated lump-sum amount saved for upfront costs]
    // Done inside function to keep a good pattern incase future calculations become complex
    function getAmountByDate(amount, monthsRemaining) {
        if (monthsRemaining === 0) {
            // Dividing by 0 should give the answer of:
            // You need this much money in less then a month
            monthsRemaining = 1;
        }
        return amount * monthsRemaining;
    }

    // Reactivly update the store whenever a goal's new contribution is changed for the plan side bar
    // Doing this in the PlanGenerator level doesn't work...?
    $: $intakeDataStore.goals = wizard.goalManager.convertToDataStore();
</script>

<div id="family-planning" class="stop-page-break life-money">
    <h2>Family Planning</h2>
    <ul>
        <li>
            You are starting to think about having a child. Once you have more clarity around the time
            frame, you will need to start proactively planning for the costs that come with having a
            child.
        </li>
        <li>
            Initially, these additional costs can be fairly low, about $500 per month or less, but if
            you need to save for daycare or nannying services it will be significantly more. In order to
            practice for this additional cost, you should begin setting aside money into a separate
            family planning savings account.
            <ul>
                {#each childrenGoals as childrenGoal}
                    <li class="grid-row grid-4 hide-print">
                        <!-- [monthly childcare expenses budget ($1,000 as suggestion)] X [number of months from goal] = [estimated lump-sum amount saved for upfront costs] -->
                        <div class="input-group">
                            <NumberInput type="dollar" bind:value={childrenGoal.planMonthAmount} />
                        </div>
                        <span>x</span>
                        <div class="input-group">
                            <NumberInput type="months" bind:value={childrenGoal.planMonthsRemaining} />
                        </div>
                        <span>= {accounting.formatMoney(childrenGoal.planTotalAmount)}</span>
                    </li>
                    <li class="show-print">
                        {accounting.formatMoney(childrenGoal.planMonthAmount)} x {childrenGoal.planMonthsRemaining}
                        months = {accounting.formatMoney(childrenGoal.planTotalAmount)}
                    </li>
                {/each}
            </ul>
        </li>
        <li>
            You can use this tool to help you more accurately estimate what the cost will be for your
            child’s first year:
            <ul>
                <li>
                    <a href="https://www.babycenter.com/baby-cost-calculator" target="_blank">
                        https://www.babycenter.com/baby-cost-calculator
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>
