/* jshint esversion: 6 */
const BaseManager = require('./BaseManager');
const Risk = require('../Models/Risk');

class RiskManager extends BaseManager {
    constructor() {
        super();
        this.risks = [];
        this.riskIds = [];
        this.types = [];
        this.today = new Date();
    }

    addRisk(account) {
        const risk = new Risk(account);
        this.riskIds.push(risk.id);
        this.types.push(risk.type)
        this.risks.push(risk);

        return risk;
    }

    getRisks(){
        return this.risks;
    }

    getByType(type) {
        let result;
        this.risks.forEach(risk => {
            if (risk.type.toLowerCase() === type) {
                result = risk;
            }
        });
        return result;
    }


}

module.exports = RiskManager;