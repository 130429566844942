class DeepCompare {
    constructor() {
        this.payload = {};
        this.tracker = undefined;
        this.arrayTracker = undefined;
        this.savePayload = false;
    }
    
    deepCompare(updatedObj, originalObj, index = null, arrayElement) {
        if (Array.isArray(updatedObj) && Array.isArray(originalObj)) {
            this.deepCompareArrayOfObj(updatedObj, originalObj);
        }
        if ((typeof updatedObj === 'object' && !Array.isArray(updatedObj)) && (typeof originalObj === 'object' && !Array.isArray(originalObj))) {
            this.deepCompareObj(updatedObj, originalObj, index, arrayElement) 
        }
    }
}

module.exports = {
    DeepCompare
};
