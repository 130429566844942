/* eslint-disable no-param-reassign */
const Base = require('./Base');
const { liabilityCategories, studentLoanTypes } = require('../../../helpers/account_types');

const toFixed = (num, precision = 2) => {
    const power = 10**precision;
    return Math.abs(Math.round(num * power) / power);
}

class Liability extends Base {
    constructor(name) {
        super(name);

        this.serverStructure = {
            balance: 'balance',
            category: 'category',
            creditLimit: 'credit_limit',
            clientId: 'client',
            start: 'date_created',
            deferred: 'deferred',
            id: 'id',
            interestRate: 'interest',
            minimumPayment: 'min_payment',
            name: 'name',
            newPayment: 'new_payment',
            oldPayment: 'old_payment',
            originalBalance: 'original_balance',
            paidInFull: 'paid_in_full',
            type: 'student_loan_type'
        };

        if (typeof name === 'object') {
            // Payment amounts should be on a per-month basis
            this.minimumPayment = parseFloat(name.min_payment ? name.min_payment : 0);
            this.oldPayment = parseFloat(name.old_payment ? name.old_payment : 0);
            this.newPayment = parseFloat(name.new_payment ? name.new_payment : 0);

            this.balance = parseFloat(name.balance ? name.balance : 0);
            this.category = name.category;
            this.clientId = name.client;
            this.creditLimit = parseFloat(name.credit_limit ? name.credit_limit : 0);
            this.deferred = name.deferred;
            this.id = name.id;
            this.interestRate = parseFloat(name.interest ? name.interest : 0);
            this.minPaymentCategory = 'other';
            this.name = name.name;
            this.originalBalance = parseFloat(name.original_balance ? name.original_balance : 0);
            this.paidInFull = name.paid_in_full;
            this.start = name.date_created ? new Date(name.date_created) : new Date();
            this.type = 0;

            liabilityCategories.forEach(category => {
                if (category.value === name.category || category.previousValue === name.category) {
                    this.category = category.value;
                    switch (this.category) {
                    case 'credit_card':
                    case 'charge_card':
                        this.minPaymentCategory = 'credit_card';
                        break;
                    case 'student_loan':
                        this.minPaymentCategory = 'student_loan';
                        break;
                    case 'mortgage':
                        this.minPaymentCategory = 'mortgage';
                        break;
                    default:
                        this.minPaymentCategory = 'other';
                    }
                }
            });

            studentLoanTypes.forEach(type => {
                if (type.index === name.student_loan_type) {
                    this.type = type.value;
                }
                if (type.value === name.student_loan_type) {
                    this.type = type.value;
                }
            });
        }
    }

    getAnnualAmount(type = 'min') {
        if (type === 'min') {
            if (!this.deferred) {
                return this.minimumPayment * 12;
            }
            return 0;
        }
        return type === 'new' ? this.newPayment * 12 : this.oldPayment * 12;
    }

    getBalance(creditUtilizationRatio) {
        if (this.paidInFull && !creditUtilizationRatio) {
            return 0;
        }
        return this.balance;
    }

    /**
     * Return a unique ID for this loan
     */
    getId() {
        let str = this.name.replace(/ /g, '').toLowerCase();
        if (str.length === 0) {
            str = 'liability';
        }
        str += this.balance;
        return str.replace(/\./g, '');
    }

    getInterestRate(date = null) {
        if (typeof this.interestRate === 'number') {
            return this.interestRate / 100 / 12;
        }
        date = typeof date === 'object' ? date : this.today;

        let aprNow = 0;
        this.interestRate.map(obj => {
            const start = new Date(obj.start);
            let end = new Date('2050-12-31');

            if (obj.end !== null) {
                end = new Date(obj.end);
            }

            if (date >= start && date <= end) {
                aprNow = obj.interestRate === 0 ? 0 : obj.interestRate / 100 / 12;
            }
        });

        return aprNow;
    }

    getMonthAmount(type = 'min') {
        if (type === 'min') {
            if (!this.deferred) {
                return this.minimumPayment;
            }
            return 0;
        }
        return type === 'new' ? this.newPayment : this.oldPayment;
    }

    // Calculate the minimum monthly amount
    getMonthlyPayment() {
        // By default, we'll just take 3% of the total balance as the minimum
        // amount. This is the higher end of industry standards, usually between
        // 1% - 3% of balance.
        if (this.payment === null) {
            this.payment = toFixed(this.balance * 0.03);
        }
        return toFixed(this.payment);
    }

    makePayment(date, payment, balance) {
        let principal = 0;
        let interestPayment = toFixed(balance * this.getInterestRate(date));

        if (payment < interestPayment) {
            throw Error("Your payment isn't enough to cover the interest generated on the loan!");
        }

        // Reduce the loan balance by the payment after the interest has been subtracted
        if (payment > balance) {
            payment = balance;
            balance = 0;
        } else {
            if (payment >= interestPayment) {
                principal = toFixed(payment - interestPayment);
            } else {
                principal = payment;
                interestPayment -= principal;
            }
            balance = toFixed(balance - principal);
        }

        // Return a payment object
        return {
            balance,
            date,
            interestPayment,
            payment: toFixed(payment),
            principal
        };
    }

    getPaidOffDateByPayment(paymentAmount) {
        try {
            if (paymentAmount <= 0 || paymentAmount === undefined) {
                throw Error(`Payments must be greater than 0.`);
            }
            if (paymentAmount < this.minimumPayment) {
                throw Error(`Payments must be more than the minimum payment of ${this.minimumPayment}.`);
            }

            // Track the balance of the loan as it's being paid off
            let burnDownBalance = this.balance;

            // Set date to compare if calculation is too long
            const hundredYears = new Date();
            hundredYears.setFullYear(hundredYears.getFullYear() + 100);

            // Set fields to change and return
            const date = new Date();
            const payments = [];

            while (burnDownBalance > 0) {
                if (date > hundredYears) {
                    throw Error('It will take more than 100 years to pay off this debt.');
                }

                // Set this month
                date.setMonth(date.getMonth() + 1);
                const paymentDate = new Date(date);

                /**
                 * Make the payment on the loan
                 */
                const payment = this.makePayment(paymentDate, paymentAmount, burnDownBalance);
                payments.push(payment);

                /**
                 * Update the burn down balance
                 */
                burnDownBalance = payment.balance;
            }
            return payments;
        } catch (error) {
            throw Error(error);
        }
    }
}

module.exports = Liability;
