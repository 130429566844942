<script>
    import { showNotes } from '../../shared/store';

    $: text = $showNotes ? 'Close' : 'Notes';
</script>

<div class="client-summary">
    <div class="panel container w90">
        <button class="button small primary" on:click="{showNotes.set(!$showNotes)}">{ text }</button>
    </div>
</div>

