export default [
    {   
        index: 0,
        title: 'FinancialFitnessPlan',
        data: '',
        show_in_plan: true
    },
    {   
        index: 1,
        title: 'EncouragingNote',
        data: '',
        show_in_plan: true
    },
    {   
        index: 2,
        title: 'CurrentSnapshot',
        data: '',
        show_in_plan: true
    },
    {   
        index: 3,
        title: 'CurrentBudget',
        data: '',
        show_in_plan: true
    },
    {   
        index: 4,
        title: 'WhatYoureWorkingFor',
        data: '',
        show_in_plan: true
    },
    {   
        index: 5,
        title: 'DigitsScorecard',
        data: '',
        show_in_plan: true
    },
    {   
        index: 6,
        title: 'SavingsRate',
        data: '',
        show_in_plan: true
    },
    {   
        index: 7,
        title: 'BreakevenGoal',
        data: '',
        show_in_plan: true
    },
    {   
        index: 8,
        title: 'MonthsOfExpensesSaved',
        data: '',
        show_in_plan: true
    },
    {   
        index: 9,
        title: 'SpendingGoal',
        data: '',
        show_in_plan: true
    },
    {   
        index: 10,
        title: 'DebtCrushingGoal',
        data: '',
        show_in_plan: true
    },
    {   
        index: 11,
        title: 'DebtManagementGoal',
        data: '',
        show_in_plan: true
    },
    {   
        index: 12,
        title: 'StudentLoanManagement',
        data: '',
        show_in_plan: true
    },
    {   
        index: 13,
        title: 'LifeMoneySavingsGoal',
        data: '',
        show_in_plan: true
    },
    {   
        index: 14,
        title: 'HomeownershipGoal',
        data: '',
        show_in_plan: true
    },
    {   
        index: 15,
        title: 'IncomeProducingProperty',
        data: '',
        show_in_plan: true
    },
    {   
        index: 16,
        title: 'EducationSavingsGoal',
        data: '',
        show_in_plan: true
    },
    {   
        index: 17,
        title: 'FireGoal',
        data: '',
        show_in_plan: true
    },
    {   
        index: 18,
        title: 'RetirementPlanningCurrent',
        data: '',
        show_in_plan: true
    },
    {   
        index: 19,
        title: 'RetirementPlanningRecommended',
        data: '',
        show_in_plan: true
    },
    {   
        index: 20,
        title: 'InvestingGoal',
        data: '',
        show_in_plan: true
    },
    {   
        index: 21,
        title: 'IncomeGoalComponent',
        data: '',
        show_in_plan: true
    },
    {   
        index: 22,
        title: 'RiskManagementChecklist',
        data: '',
        show_in_plan: true
    },
    {   
        index: 23,
        title: 'NextSteps',
        data: '',
        show_in_plan: true
    },
    {   
        index: 24,
        title: 'CommunicationTimeline',
        data: '',
        show_in_plan: true
    },
    {   
        index: 25,
        title: 'MoneyMotivationPlaylist',
        data: '',
        show_in_plan: true
    }
];
