<script>
    import dayjs from 'dayjs';

    import { intakeDataStore, planGeneratorStore } from '../../shared/store';
    import accounting from '../../shared/accounting_config';

    export let wizard;

    $: city = $intakeDataStore.client_info.city;
    $: birth_date = $intakeDataStore.client_info.birth_date;
    $: state = $intakeDataStore.client_info.state;
    $: dependents = $intakeDataStore.client_info.dependents;
    $: occupation = $intakeDataStore.client_info.occupation;
    $: employer = $intakeDataStore.client_info.employer;

    $: annualGrossIncome = wizard.getIncome('gross', 'annually');
    $: annualNetIncome = wizard.getIncome('net', 'annually');
    $: creditScores = wizard.creditScoreManager.getSortedByScores('desc');
    $: cashTotal = wizard.getAssets(['checking', 'savings'], 'all');
    $: retirementTotal = wizard.getAssets([
        '401k_trad',
        '401k_roth',
        'trad_ira',
        'roth_ira',
        'sep',
        '403b_trad',
        '403b_roth',
        '457b',
        'pension',
        'solo_401k',
        'simple_ira',
        'tsp'
    ], 'all');
    $: otherInvestmentsTotal = wizard.getAssets([
        'taxable',
        'hsa',
        '529',
        'coverdell_esa',
        'crypto',
        'family_trust'
    ], 'all');
    $: otherAssetsTotal = wizard.getAssets(['other'], 'all');
    $: realEstateTotal = wizard.getAssets(['real_estate'], 'all');
    $: creditCardTotal = wizard.getLiabilities(['charge_card', 'credit_card'], 'all');
    $: studentLoanTotal = wizard.getLiabilities(['student_loan'], 'all');
    $: otherDebtTotal = wizard.getLiabilities([
        'auto_loan',
        'personal_loan',
        'medical',
        'tax',
        'family',
        'other'
    ], 'all');
    $: mortgageTotal = wizard.getLiabilities(['mortgage'], 'all');

    $: assetsTotal = cashTotal + retirementTotal + otherInvestmentsTotal + otherAssetsTotal + realEstateTotal;
    $: liabilitiesTotal = creditCardTotal + studentLoanTotal + otherDebtTotal + mortgageTotal;
</script>

<h1 class="page-title">Current Snapshot</h1>

<table class="two-column-table">
    <thead>
        <tr>
            <th colspan="2">The Basics</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th>Location</th>
            <td>{city ? `${city}, ${state}` : ''}</td>
        </tr>
        <tr>
            <th>Birthdate (Age)</th>
            <td>
                <div class="hide-print grid-row">
                    <div>
                        {birth_date ? `${dayjs(birth_date).format('MM/DD/YYYY')} (${(dayjs(new Date()).diff(
                            dayjs($intakeDataStore.client_info.birth_date),
                            'years'
                        ))})`  : ``}
                    </div>
                    <div  class="hide-print grid-row grid-3">
                        2nd:
                        <div>
                            <input
                            id="second-birthday"
                            class="hide-print"
                            type="date"
                            bind:value="{$planGeneratorStore.plan.second_birth_date}" />
                        </div>
                        <div>
                            {$planGeneratorStore.plan.second_birth_date ? `(${(dayjs(new Date()).diff(
                                dayjs($planGeneratorStore.plan.second_birth_date),
                                'years'
                            ))})`  : ``}
                        </div>
                    </div>
                </div>
                <div class="show-print">
                    {birth_date ? `${dayjs(birth_date).format('MM/DD/YYYY')} (${(dayjs(new Date()).diff(
                        dayjs($intakeDataStore.client_info.birth_date),
                        'years'
                    ))})`  : ``}
                    <span>
                        {$planGeneratorStore.plan.second_birth_date ? `& ${dayjs($planGeneratorStore.plan.second_birth_date).format('MM/DD/YYYY')} (${(dayjs(new Date()).diff(
                            dayjs($planGeneratorStore.plan.second_birth_date),
                            'years'
                        ))})`  : ``}
                    </span>
                </div>
            </td>
        </tr>
        {#if dependents > 0}
            <tr>
                <th>Kids/Dependents</th>
                <td>{dependents}</td>
            </tr>
        {/if}
        <tr>
            <th>Occupation/Employer</th>
            <td>
                {!occupation ? '' : occupation} {employer ? `/ ${employer}` : ``}
            </td>
        </tr>
        <tr>
            <th>Annual Household Income</th>
            <td>
                {accounting.formatMoney(annualGrossIncome)} gross and {accounting.formatMoney(annualNetIncome)}
                after taxes/deductions
            </td>
        </tr>
        <tr>
            <th>Monthly Net Income</th>
            <td>{accounting.formatMoney(annualNetIncome / 12)}</td>
        </tr>
        <tr>
            <th>Credit Score</th>
            <td>
                {#if creditScores.length > 0}
                    {creditScores[0].score}
                    {creditScores.length > 1 ? ` / ${creditScores[1].score}` : ''}
                {/if}
            </td>
        </tr>
    </tbody>
</table>

<table class="two-column-table">
    <thead>
        <tr>
            <th colspan="2">Assets</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th>Cash</th>
            <td>{accounting.formatMoney(cashTotal)}</td>
        </tr>
        <tr>
            <th>Retirement</th>
            <td>{accounting.formatMoney(retirementTotal)}</td>
        </tr>
        <tr>
            <th>Other Investments</th>
            <td>{accounting.formatMoney(otherInvestmentsTotal)}</td>
        </tr>
        <tr>
            <th>Other Assets</th>
            <td>{accounting.formatMoney(otherAssetsTotal)}</td>
        </tr>
        <tr>
            <th>Real Estate (Est. Value)</th>
            <td>{accounting.formatMoney(realEstateTotal)}</td>
        </tr>
        <tr>
            <th>Total</th>
            <td>{accounting.formatMoney(assetsTotal)}</td>
        </tr>
    </tbody>
</table>

<table class="two-column-table">
    <thead>
        <tr>
            <th colspan="2">Liabilities</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th>Credit Card Debt</th>
            <td>{accounting.formatMoney(creditCardTotal)}</td>
        </tr>
        <tr>
            <th>Student Loans</th>
            <td>{accounting.formatMoney(studentLoanTotal)}</td>
        </tr>
        <tr>
            <th>Other Debt</th>
            <td>{accounting.formatMoney(otherDebtTotal)}</td>
        </tr>
        <tr>
            <th>Mortgage Balance</th>
            <td>{accounting.formatMoney(mortgageTotal)}</td>
        </tr>
        <tr>
            <th>Total</th>
            <td>{accounting.formatMoney(liabilitiesTotal)}</td>
        </tr>
    </tbody>
</table>

<style lang="stylus" scoped>
    .grid-row
        margin-top .5em
        margin-bottom .5em
        grid-template-rows 1fr
    .grid-row.grid-3
        margin-top 0
        grid-template-columns 1fr 5fr 1fr


    @media screen and (max-width 480px)
        table
            display block
            overflow-x auto
</style>
