<script>
    import dayjs from 'dayjs';

    import { planGeneratorStore } from '../../../shared/store';

    export let wizard;
    
    let meetingDate = dayjs();

    $: mindsetGoals = wizard.goalManager.getByTypes(['mindset'], 'activeGoals');
    
    $: if (dayjs($planGeneratorStore.plan.meeting_date).isValid()) {
        meetingDate = dayjs($planGeneratorStore.plan.meeting_date);
    }
</script>


<h2>Mindset</h2>
<ul>
    {#each mindsetGoals as mindsetGoal}
        <li>
            <strong>
                {mindsetGoal.name}
            </strong> - projected completion - 
            <strong>
                {meetingDate
                    .add(mindsetGoal.monthsRemaining, 'month')
                    .format('MM-DD-YYYY')}
            </strong>
            <p>{mindsetGoal.description}</p>
        </li>
    {/each}
</ul>