<script>
    import QuillComponent from '../UXElements/QuillComponent.svelte';
    import { planGeneratorStore } from '../../shared/store';

    export let type;

    let showPrint = true;
    let breakevenGoal = false;

    $: if (!type.includes('quill')) {
        showPrintHandler($planGeneratorStore.plan[type]);
    } else {
        // Handles Duplicate Pages
        const page = $planGeneratorStore.pages.find(page => 
            page.title === type.split('_').slice(0, 2).join('_')
        );

        showPrintHandler(page.data);
    }

    const showPrintHandler = (JSONText) => {
        if (JSONText[0] === '{' || JSONText[0] === '[') {
            if (JSON.parse(JSONText)) {
                if (JSON.parse(JSONText).ops.length > 0) {
                    if (JSON.parse(JSONText).ops[0].insert.length === 1) {
                        showPrint = false;
                    } else {
                        showPrint = true;
                    }
                }
            }
        }
    }

    $: if (type === 'breakeven_goal_trainer_comments') {
        breakevenGoal = true;
    }
</script>

<div class="{!showPrint ? 'hide-print' : ''} {breakevenGoal ? 'breakeven-goal-trainer-comments' : ''}">
    <div>
        {#if type.includes('trainer_comments')}
            <h3>Trainer Comments:</h3>
        {/if}
        <QuillComponent {type} />
    </div>
</div>

<style lang="stylus" scoped>
    @media print
        .breakeven-goal-trainer-comments
            h3
                margin .5em 0 .5em
</style>