/* eslint-disable max-classes-per-file */
class Link {
    constructor(parent, child, title, data, show_in_plan, duplicate) {
        this.parent = parent;
        this.child = child;
        this.title = title;
        this.data = data;
        this.show_in_plan = show_in_plan;
        this.duplicate = duplicate;
    }
}

class LinkedList {
    constructor() {
        this.head = null;
    }

    initLinkedList(pages) {
        // Sort the data from the server
        pages.sort((a, b) => {
            return a.index - b.index;
        });
        // Add the pages to the linked list
        let previousLink = null;
        pages.forEach(page => {
            const link = new Link(previousLink, null, page.title, page.data, page.show_in_plan, page.duplicate);
            // Add an id only if there is one, this is for the sever to know to post/put
            if (page.id) {
                link.id = page.id;
            }
            if (previousLink === null) {
                this.head = link;
            } else {
                previousLink.child = link;
            }
            previousLink = link;
        });
        // console.log('init');
        // console.table(this.getArray());
    }

    add(parent, title, data, show_in_plan = true, duplicate = false) {
        const link = new Link(parent, null, title, data, show_in_plan, duplicate);
        // Link parent and parents old child to the new link
        if (parent !== null) {
            link.child = parent.child;
            parent.child = link;
            if (link.child !== null) {
                link.child.parent = link;
            }
        } else {
            this.head = link;
        }
        // console.log('page added');
        // console.table(this.getArray());
    }

    remove(link) {
        // Set parent and child links to each other
        if (link.title === '' || link.title.includes('_')) {
            const { parent } = link;
            const { child } = link;
            if (this.head === link) {
                this.head = child;
            }
            if (parent !== null) {
                parent.child = child;
            }
            if (child !== null) {
                child.parent = parent;
            }
        }
        // console.log('page removed');
        // console.table(this.getArray());
    }

    getArray() {
        const array = [];
        let index = 0;
        let link = this.head;
        // Itterate though the linkedList and return them in an array ordered and numbered
        while (link !== null) {
            const linkCopy = { ...link };
            delete linkCopy.parent;
            delete linkCopy.child;
            linkCopy.index = index;
            array.push(linkCopy);
            index += 1;
            link = link.child;
        }
        return array;
    }

    iterateThroughList() {
        let page = this.head;
        const array = [];
        while (page !== null) {
            array.push(page);
            page = page.child;
        }
        return array;
    }
}

module.exports = {
    LinkedList
};