<script>
    import { intakeDataStore } from '../../../shared/store';
    import accounting from '../../../shared/accounting_config';

    import NumberInput from '../../UXElements/NumberInput.svelte';

    export let wizard;

    // Init the values in store
    $: carGoals = wizard.goalManager.getByTypes(['car_purchase'], 'activeGoals');

    $: carGoals.forEach(goal => {
        goal.planTotalAmount = getAmountByDate(goal.planMonthAmount, goal.planMonthsRemaining);
    });

    // Amount saved for a down paymeny by date
    // [monthly car payment budget] X [number of months from goal] = [estimated down-payment amount saved]
    // Done inside function to keep a good pattern incase future calculations become complex
    function getAmountByDate(amount, monthsRemaining) {
        if (monthsRemaining === 0) {
            // Dividing by 0 should give the answer of:
            // You need this much money in less then a month
            monthsRemaining = 1;
        }
        return amount * monthsRemaining;
    }

    // Reactivly update the store whenever a goal's new contribution is changed for the plan side bar
    // Doing this in the PlanGenerator level doesn't work...?
    $: $intakeDataStore.goals = wizard.goalManager.convertToDataStore();
</script>

<div id="car-purchase" class="stop-page-break life-money">
    <h2>Car</h2>
    <ul>
        <li>
            You are considering purchasing or leasing a new car and need to prepare for this additional
            cost. In order to do so, we recommend that our clients set up a separate savings account and
            make ongoing monthly contributions that mimic the expected cost of the new car. This not
            only allows you to adjust to your new budget, but also save up for the down payment.
        </li>
        <li>
            If you are unsure about the costs, you can use the below resources to help estimate the
            monthly car payment as well as the additional insurance costs.
            <ul>
                <a href="https://www.kbb.com/new-cars/" target="_blank">
                    https://www.kbb.com/new-cars/
                </a>
                <br/>
                <a href="https://www.policygenius.com/thefinancialgym" target="_blank">
                    https://www.policygenius.com/thefinancialgym
                </a>
            </ul>
        </li>
        <li>
            Here’s our recommendation for how to save for this goal:
            <ul>
                {#each carGoals as carGoal}
                    <li class="grid-row grid-4 hide-print">
                        <div class="input-group">
                            <NumberInput type="dollar" bind:value={carGoal.planMonthAmount} />
                        </div>
                        <span>x</span>
                        <div class="input-group">
                            <NumberInput type="months" bind:value={carGoal.planMonthsRemaining} />
                        </div>
                        <span>= {accounting.formatMoney(carGoal.planTotalAmount)}</span>
                    </li>
                    <li class="show-print">
                        {accounting.formatMoney(carGoal.planMonthAmount)} x {carGoal.planMonthsRemaining} months
                        = {accounting.formatMoney(carGoal.planTotalAmount)}
                    </li>
                {/each}
            </ul>
        </li>
    </ul>
</div>

<style lang="stylus" scoped>
    @media screen and (max-width 480px)
        a
            word-break break-word
</style>
