<script>
    import { location, push } from 'svelte-spa-router';
    import { loggedIn } from '../shared/store';

    let currentTab = '';
    $: {
        switch ($location) {
            case '/':
                currentTab = 'clients';
                break;
            default:
                currentTab = '';
        }
    }
</script>

<nav class="main-nav">
    <div class="flex-wrapper">
        {#if $loggedIn }
            <button
                class:tab-current={currentTab === 'clients'}
                class="tab button icon-button"
                on:click|preventDefault="{() => {
                    currentTab = 'clients';
                    push('/');
                }}"
            >
                All Clients
            </button>
            <!-- No milestones yet
            <a class="tab tab-milestones" href="#">Milestones &amp; Alerts</a>
            -->
        {/if}
    </div>
</nav>
