class BaseManager {
    constructor() {
        this.totalAnnual = 0;
        this.totalMonth = 0;
    }

    getTotalAnnualAmount() {
        return this.totalAnnual;
    }

    getTotalMonthAmount() {
        return this.totalMonth;
    }
}

module.exports = BaseManager;
