const Base = require('./Base');

class CreditScore extends Base {
    constructor(score) {
        super(score);
        this.clientId = score.client;
        this.date = new Date(score.date_created);
        this.id = score.id;
        this.score = score.credit_score;
        this.bureau = score.bureau;
    }
}

module.exports = CreditScore;
