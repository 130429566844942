<script>
    import TravelSavingsGoal from './LifeMoneySavingsGoals/TravelSavingsGoal.svelte';
    import WeddingGoal from './LifeMoneySavingsGoals/WeddingGoal.svelte';
    import CarGoal from './LifeMoneySavingsGoals/CarGoal.svelte';
    import MovingRentalGoal from './LifeMoneySavingsGoals/MovingRentalGoal.svelte';
    import PetsGoal from './LifeMoneySavingsGoals/PetsGoal.svelte';
    import ChildrenGoal from './LifeMoneySavingsGoals/ChildrenGoal.svelte';
    import GradSchoolContinuingEdGoal from './LifeMoneySavingsGoals/GradSchoolContinuingEdGoal.svelte';
    import MindsetGoal from './LifeMoneySavingsGoals/MindsetGoal.svelte';

    export let wizard;
</script>


<h1 class="page-title">Life Money</h1>

{#if wizard.goalManager.hasGoals(['travel_savings'], 'activeGoals')}
    <TravelSavingsGoal {wizard} />
{/if}

{#if wizard.goalManager.hasGoals(['wedding'], 'activeGoals')}
    <WeddingGoal {wizard} />
{/if}

{#if wizard.goalManager.hasGoals(['car_purchase'], 'activeGoals')}
    <CarGoal {wizard} />
{/if}

{#if wizard.goalManager.hasGoals(['moving'], 'activeGoals')}
    <MovingRentalGoal {wizard} />
{/if}

{#if wizard.goalManager.hasGoals(['pet_care'], 'activeGoals')}
    <PetsGoal {wizard} />
{/if}

{#if wizard.goalManager.hasGoals(['family_planning'], 'activeGoals')}
    <ChildrenGoal {wizard} />
{/if}

{#if wizard.goalManager.hasGoals(['grad_school'], 'activeGoals')}
    <GradSchoolContinuingEdGoal {wizard} />
{/if}

{#if wizard.goalManager.hasGoals(['mindset'], 'activeGoals')}
    <MindsetGoal {wizard} />
{/if}
