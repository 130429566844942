<script>
    import FeatherIcon from '../UXElements/FeatherIcon.svelte';

    export let text = '';
    export let title = '';
</script>

<h1 class="page-title">{text} {title} Goal Page </h1>
<div class="spin"><FeatherIcon icon="aperture" /></div>

<style lang="stylus" scoped>
    .spin
        text-align center
        display block
</style>
