const Base = require('./Base');
const { assetCategories, investmentTypes } = require('../../../helpers/account_types');

class Asset extends Base {
    constructor(name) {
        super(name);
        if (typeof name === 'object') {
            this.balance = parseFloat(name.balance ? name.balance : 0);
            this.category = name.category;
            this.clientId = name.client;
            this.contribution = parseFloat(name.contribution ? name.contribution : 0);
            this.contributionType = name.contribution_type;
            this.employerContribution = parseFloat(
                name.employer_contribution ? name.employer_contribution : 0
            );
            this.employerContributionType = name.employer_contribution_type;
            this.frequency = name.frequency;
            this.id = name.id;
            this.interestRate = parseFloat(name.interest ? name.interest : 0);
            this.investmentType = '';
            this.name = name.name;
            this.newContribution = parseFloat(name.new_contribution ? name.new_contribution : 0);
            this.previous = name.previous;
            this.targetBalance = parseFloat(name.target_balance ? name.target_balance : 0);
            this.dateCreated = name.date_created ? new Date(name.date_created) : new Date();

            assetCategories.forEach(category => {
                if (category.value === name.category || category.previousValue === name.category) {
                    this.category = category.value;
                }
            });

            investmentTypes.forEach(type => {
                if (type.value === name.investment_type || type.index === name.investment_type) {
                    this.investmentType = type.value;
                }
            });
        }
    }

    getBalance() {
        return this.balance;
    }

    getAnnualAmount(totalMonthlyGrossIncome, employerContribution = false) {
        if (employerContribution) {
            if (this.employerContributionType === 'percent') {
                return totalMonthlyGrossIncome * (this.employerContribution/100) * 12;
            }
            return this.employerContribution * 12;
        }

        if (this.contributionType === 'percent') {
            return totalMonthlyGrossIncome * (this.contribution/100) * 12;
        }
        return this.contribution * 12;
    }

    getMonthAmount(monthlyIncome) {
        if (this.contributionType === 'percent') {
            return monthlyIncome * (this.contribution/100);
        }
        return this.contribution;
    }
}

module.exports = Asset;
