<script>
    import { intakeDataStore, planGeneratorStore } from '../../../shared/store';
    import accounting from '../../../shared/accounting_config';
    import NumberInput from '../../UXElements/NumberInput.svelte';

    export let wizard;

    let isInitValueSet = false;
    let setAfterGetPlan = false;
    $: if (wizard) {
        isInitValueSet = false;
    }

    // Init the values in store
    $: movingRentalGoals = wizard.goalManager.getByTypes(['moving'], 'activeGoals');
    $: rentExpense = wizard.expenseManager.getByName('rent');
    $: if (
        $planGeneratorStore.plan.current_monthly_rent === null &&
        rentExpense.length > 0 &&
        !isInitValueSet
    ) {
        isInitValueSet = true;
        $planGeneratorStore.plan.current_monthly_rent = rentExpense[0].getMonthAmount();
    }

    $: if ($planGeneratorStore.plan.id) {
        if (isNaN(parseFloat($planGeneratorStore.plan.current_monthly_rent))) {
            if (rentExpense.length > 0 && !setAfterGetPlan) {
                $planGeneratorStore.plan.current_monthly_rent = rentExpense[0].getMonthAmount();
            }
        }
        setAfterGetPlan = true;
    }

    $: if ($planGeneratorStore.plan.current_monthly_rent === undefined) {
        $planGeneratorStore.plan.current_monthly_rent = 0;
    }

    // We should have a more full proof way of getting the rent amount
    // $: $planGeneratorStore.plan.current_monthly_rent = $planGeneratorStore.plan.current_monthly_rent === 0 && rentExpense.length > 0;
    // Amount needed as an upfront cost for the move
    // [future monthly rent] + [security deposit] + [broker’s fee (optional)] + [moving costs] = [total estimated upfront moving costs]
    // Done inside function to keep a good pattern incase future calculations become complex
    function getTotalCost(monthlyProjectedRent, securityDeposit, brokerFee, movingCost) {
        monthlyProjectedRent =
            isNaN(monthlyProjectedRent) || monthlyProjectedRent === null
                ? 0
                : parseFloat(monthlyProjectedRent);
        securityDeposit =
            isNaN(securityDeposit) || securityDeposit === null ? 0 : parseFloat(securityDeposit);
        brokerFee = isNaN(brokerFee) || brokerFee === null ? 0 : parseFloat(brokerFee);
        movingCost = isNaN(movingCost) || movingCost === null ? 0 : parseFloat(movingCost);
        return monthlyProjectedRent + securityDeposit + brokerFee + movingCost;
    }

    // Difference between current and future rent
    // [future monthly rent] - [current monthly rent] = [difference between current housing costs and future housing costs]
    // Done inside function to keep a good pattern incase future calculations become complex
    function getRentDifference(monthlyProjectedRent, monthlyCurrentRent) {
        monthlyProjectedRent =
            isNaN(monthlyProjectedRent) || monthlyProjectedRent === null
                ? 0
                : parseFloat(monthlyProjectedRent);
        monthlyCurrentRent =
            isNaN(monthlyCurrentRent) || monthlyCurrentRent === null
                ? 0
                : parseFloat(monthlyCurrentRent);
        return monthlyProjectedRent - monthlyCurrentRent;
    }

    // Find the number of months until enough money is saved up to move from the difference between current and future rent
    // [total estimated upfront moving costs] / [difference between current housing costs and future housing costs] = [months until you can move]
    // Done inside function to keep a good pattern incase future calculations become complex
    function getNumberOfMonthsTillMove(totalCost, rentDifference) {
        if (parseFloat(totalCost) / parseFloat(rentDifference) === Infinity) {
            return 1;
        }
        return totalCost / rentDifference;
    }

    $: movingRentalGoals.forEach(goal => {
        goal.planMonthAmount = getRentDifference(
            goal.planMonthlyProjectedRent,
            $planGeneratorStore.plan.current_monthly_rent
        );
    });

    // Reactivly update the store whenever a goal's new contribution is changed for the plan side bar
    // Doing this in the PlanGenerator level doesn't work...?
    $: if (!isNaN($planGeneratorStore.plan.current_monthly_rent)) {
        $intakeDataStore.goals = wizard.goalManager.convertToDataStore();
    }
    $: $intakeDataStore.goals = wizard.goalManager.convertToDataStore();
</script>

<div id="moving" class="stop-page-break life-money">
    <h2>Moving (Rental)</h2>
    <ul>
        <li>
            You are planning to move in the near future, and need to prepare for this cost. In order
            to do so, we recommend that our clients set up a separate savings account and make
            ongoing monthly contributions that mimic the expected cost of the new rent. This not
            only allows you to adjust to your new budget, but also save up for the upfront cost.
            <ul>
                {#each movingRentalGoals as movingRentalGoal}
                    <li>
                        Upfront Costs:
                        <ul>
                            <p>
                                Future Rent + Security Deposit
                                <span
                                    class="{parseFloat(movingRentalGoal.planBrokersFee) === 0 || isNaN(movingRentalGoal.planBrokersFee) ? 'text-muted hide-print' : ''}">
                                    + Broker's Fee
                                </span>
                                + Moving Cost = Total Estimated Upfront Moving Costs
                            </p>
                            <li class="grid-row grid-4 hide-print moving-rental-upfront-costs">
                                <!-- [future monthly rent] + [security deposit] + [broker’s fee (optional)] + [moving costs] = [total estimated upfront moving costs] -->
                                <div class="input-group">
                                    <NumberInput placeholder="Future Rent" type="dollar" bind:value={movingRentalGoal.planMonthlyProjectedRent} />
                                </div>
                                <span>+</span>
                                <div class="input-group">
                                    <NumberInput placeholder="Security Deposit" type="dollar" bind:value={movingRentalGoal.planSecurityDeposit} />
                                </div>
                                <span>+</span>
                                <div class="input-group">
                                    <div class="number {parseFloat(movingRentalGoal.planBrokersFee) === 0 || isNaN(movingRentalGoal.planBrokersFee) ? 'hide-input' : ''}">
                                        <span class="prepend">
                                            $
                                        </span>
                                        <input
                                            class="form-control"
                                            type="number"
                                            step="0.01"
                                            placeholder="Broker's Fee"
                                            bind:value="{movingRentalGoal.planBrokersFee}" />
                                    </div>
                                </div>
                                <span>+</span>
                                <div class="input-group hide-print">
                                    <NumberInput placeholder="Moving Cost" type="dollar" bind:value={movingRentalGoal.planMoversCost} />
                                </div>
                                <span>
                                    = {accounting.formatMoney(getTotalCost(movingRentalGoal.planMonthlyProjectedRent, movingRentalGoal.planSecurityDeposit, movingRentalGoal.planBrokersFee, movingRentalGoal.planMoversCost))}
                                </span>
                            </li>
                            <li class="show-print">
                                {accounting.formatMoney(movingRentalGoal.planMonthlyProjectedRent)}
                                + {accounting.formatMoney(movingRentalGoal.planSecurityDeposit)}
                                {#if parseFloat(movingRentalGoal.planBrokersFee) !== 0 && !isNaN(movingRentalGoal.planBrokersFee)}
                                    + {accounting.formatMoney(movingRentalGoal.planBrokersFee)}
                                {/if}
                                + {accounting.formatMoney(movingRentalGoal.planMoversCost)} = {accounting.formatMoney(getTotalCost(movingRentalGoal.planMonthlyProjectedRent, movingRentalGoal.planSecurityDeposit, movingRentalGoal.planBrokersFee, movingRentalGoal.planMoversCost))}
                            </li>
                        </ul>
                    </li>
                    <li>
                        Monthly Costs:
                        <ul>
                            <p>
                                Future Rent - Current Rent = Difference Between Current and Future
                                Housing Costs
                            </p>
                            <li class="grid-row grid-4 hide-print">
                                <!-- [future monthly rent] -- [current monthly rent] = [difference between current housing costs and future housing costs] -->
                                <div class="input-group">
                                    <NumberInput placeholder="Future Rent" type="dollar" bind:value={movingRentalGoal.planMonthlyProjectedRent} />
                                </div>
                                <span>-</span>
                                <div class="input-group">
                                    <NumberInput placeholder="Current Rent" type="dollar" bind:value={$planGeneratorStore.plan.current_monthly_rent} />
                                </div>
                                <span>= {accounting.formatMoney(getRentDifference(movingRentalGoal.planMonthlyProjectedRent, $planGeneratorStore.plan.current_monthly_rent))}</span>
                            </li>
                            <li class="show-print">
                                {accounting.formatMoney(movingRentalGoal.planMonthlyProjectedRent)}
                                - {accounting.formatMoney($planGeneratorStore.plan.current_monthly_rent)}
                                = {accounting.formatMoney(getRentDifference(movingRentalGoal.planMonthlyProjectedRent, $planGeneratorStore.plan.current_monthly_rent))}
                            </li>
                        </ul>
                    </li>
                    <li>
                        Total Estimated Upfront Moving Costs / Difference Between Current and Future
                        Housing Costs = Months Until You Can Move
                        <ul>
                            <p>
                                <!-- [total estimated upfront moving costs] / [difference between current housing costs and future housing costs] = [months until you can move] -->
                                {accounting.formatMoney(getTotalCost(movingRentalGoal.planMonthlyProjectedRent, movingRentalGoal.planSecurityDeposit, movingRentalGoal.planBrokersFee, movingRentalGoal.planMoversCost))}
                                / {accounting.formatMoney(getRentDifference(movingRentalGoal.planMonthlyProjectedRent, $planGeneratorStore.plan.current_monthly_rent))}
                                = {Math.ceil(getNumberOfMonthsTillMove(getTotalCost(movingRentalGoal.planMonthlyProjectedRent, movingRentalGoal.planSecurityDeposit, movingRentalGoal.planBrokersFee, movingRentalGoal.planMoversCost), getRentDifference(movingRentalGoal.planMonthlyProjectedRent, $planGeneratorStore.plan.current_monthly_rent)))}
                                months
                            </p>
                        </ul>
                    </li>
                {/each}
            </ul>
        </li>
    </ul>
</div>

<style lang="stylus" scoped>
    .hide-input
        opacity 50%
        background-color #f5f5f5

    .moving-rental-upfront-costs .input-group
        display inline-block

    .number
        display grid
        grid-template-columns 1fr 10fr

        .prepend
            align-self center
            padding-right 0.5em
            text-align right
</style>
