<script>
    import { onMount, onDestroy, afterUpdate } from 'svelte';
    import dayjs from 'dayjs';
    import { location, push } from 'svelte-spa-router';

    import {
        statusHandler,
        intakeDataStore,
        trainerDataStore,
        clientAfterUpdate
    } from '../shared/store';
    import * as api from '../shared/api_access';
    import { default as states } from '../shared/states';
    import { ClientInfoDeepCompare } from '../classes/DeepCompare/ClientInfoDeepCompare';
    import Request from '../classes/Request/Request';
    import * as domFunctions from '../helpers/dom_functions';

    import FormButtons from './UXElements/FormButtons.svelte';
    import FeatherIcon from './UXElements/FeatherIcon.svelte';
    import LimitedCharInput from './UXElements/LimitedCharInput.svelte';
    import Modal from './UXElements/Modal.svelte';


    export let params;

    //  Client and form info
    let clientId;
    let officeLocations = [];
    let trainersList = [];
    const clientInfoTemplate = {
        users: [
            {
                email: '',
                gravatar_url: ''
            }
        ],
        name: '',
        phone_number: '',
        city: '',
        state: '',
        postal_code: '',
        dependents: '',
        employer: '',
        occupation: '',
        birth_date: '',
        start_date: dayjs().format('YYYY-MM-DD'),
        referral: '',
        monthly_rate: '',
        office: null,
        trainer: null,
        reason: ''
    };
    let clientInfo = clientInfoTemplate;
    let originalClientInfo;
    let clientTransferred = false;
    // Page
    let page;
    let pageTitle = '';
    // Error
    let error = false;
    let errorMessage;
    // Form status
    let loadedSelectData = false;
    let loadedClient = false;
    let updating = false;
    let message = 'Save';
    // Modal
    let openModal = false;
    let modalBody = '';

    // Conditional reactives
    $: if ($location !== '/new-client' && params.id === undefined) {
        push('/not-found');
    }

    $: if ($location !== '/new-client' && params.id !== clientId) {
        if (clientId !== undefined && clientId !== null) {
            updateClientInfo();
        }
        page = true;
        clientId = params.id;
        loadedClient = false;
        getClientInfo();
    } else if ($location === '/new-client') {
        if (clientId !== undefined && clientId !== null) {
            updateClientInfo();
        }
        page = null;
        clientId = null;
        clientInfo = clientInfoTemplate;
        clientInfo.trainer = $trainerDataStore.id;
        loadedClient = true;
        pageTitle = 'Create Client';
    }

    $: if ($location) {
        domFunctions.setBrowserTitle($location, clientInfo.name);
    }

    onMount(async () => {
        // Location data
        const { data } = await api.getLocations();
        officeLocations = data.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        officeLocations.unshift({ name: '--', value: null });

        // Trainer data
        const { data: trainers } = await api.request('get', 'trainers/');
        trainersList = trainers.sort((a, b) => {
            if (a.last_name < b.last_name) {
                return -1;
            }
            if (a.last_name > b.last_name) {
                return 1;
            }
            return 0;
        });

        loadedSelectData = true;

        window.addEventListener('beforeunload', domFunctions.promptUponClose);
    });

    afterUpdate(async () => {
        // prevent non-numeric texts to be typed
        domFunctions.inputNumbersOnly();
    });

    onDestroy(async () => {
        if (clientId !== null && clientId !== undefined && !clientTransferred) {
            await updateClientInfo(true);
        }

        window.removeEventListener('beforeunload', domFunctions.promptUponClose);
    });

    // Client Calls
    const createNewClient = async () => {
        updating = true;
        const { data, status } = await api.createNewClient(clientInfo);

        if (status >= 400) {
            error = true;
            errorMessage = data;
            return 'error';
        } else {
            updating = false;
            push('/');
            return 'success';
        }
    };

    const updateClientInfo = async (destroyed = false, confirmedTransfer = false) => {
        // If the user has switch away to another client or gone to another page
        // and destroyed, don't show modal and save everything but the transfer
        if (clientInfo.trainer !== $trainerDataStore.id && !confirmedTransfer) {
            if (clientId === params.id && !destroyed) {
                openModal = true;
                return;
            }
            delete clientInfo.trainer;
            delete clientInfo.reason;
            if ($location === '/') {
                $statusHandler = {
                    type: "error",
                    message: "Please select 'Save' to transfer your client."
                }
            }
        }

        let clientInfoResponse = null;
        error = false;
        const clientInfoPayload = new ClientInfoDeepCompare(
            clientInfo,
            JSON.parse(originalClientInfo)
        );

        if (clientInfoPayload.savePayload) {
            clientInfoPayload.prunePayload();
            if ($location !== '/new-client' && clientId === params.id) {
                updating = true;
            }
            clientInfoResponse = await api.updateClientInfo(clientInfoPayload.payload, clientId);
        }
        if ($location !== '/new-client' && clientId === params.id) {
            if (clientInfoResponse === null) {
                return 'synced';
            }
            if (clientInfoResponse === undefined) {
                error = true;
                errorMessage = `An unexpected error occurred.`;
            }

            if (clientInfoResponse !== undefined) {
                if (clientInfoResponse.status < 400) {
                    if (confirmedTransfer) {
                        clientTransferred = true;
                        const trainer = trainersList.find(
                            trainer => trainer.id === clientInfo.trainer
                        );
                        $statusHandler = {
                            type: 'success',
                            message: `Your client <strong>${clientInfo.name}</strong> has been successfully transferred to <strong>${trainer.first_name} ${trainer.last_name}</strong>`
                        }
                        push('/');
                        return;
                    }

                    // User is still on this page, get the client info
                    if (!destroyed) {
                        await getClientInfo();
                    }
                    error = false;

                    // User is going back to the dashboard, we need
                    // to ensure they get this user after it's updated
                    if (destroyed && $location === '/') {
                        await getClientInfoForDashboard();
                    }
                    return 'success';
                }
                if (clientInfoResponse.status >= 400) {
                    error = true;
                    errorMessage = clientInfoResponse.data;

                    if (destroyed && $location === '/') {
                        $statusHandler = {
                            type: 'error',
                            message: `Something happened when we tried to update client, <strong>${clientInfo.name}</strong>`
                        }
                    }
                }
            }
            if (error) {
                if (confirmedTransfer) {
                    message = 'Error';
                    document.getElementById('save-button').classList.add('btn-danger');
                    document.getElementById('save-button').classList.remove('btn-primary');
                    document.getElementById('save-button').classList.remove('primary');

                    setTimeout(() => {
                        message = 'Save';
                        document.getElementById('save-button').classList.add('btn-primary');
                        document.getElementById('save-button').classList.add('primary');
                        document.getElementById('save-button').classList.remove('btn-danger');
                        document.getElementById('save-button').classList.remove('btn-success');
                        updating = false;
                    }, 2000);
                }
                return 'error';
            }
        }
    };

    const getClientInfo = async () => {
        const requestClassProps = {
            url: `clients/${clientId}/`
        };

        requestClassProps.successHandler = (successResponse) => {
            if (successResponse.data.users.length === 0) {
                successResponse.data.users.push({
                    email: ''
                });
            }

            // Only use the value part of the location object
            if (successResponse.data.office !== null) {
                successResponse.data.office = successResponse.data.office.value;
            }

            // Append a 'reason' field that the html can hook onto
            successResponse.data.reason = '';

            $intakeDataStore.client_info = successResponse.data;
            clientInfo = $intakeDataStore.client_info;
            originalClientInfo = JSON.stringify(clientInfo);

            // Page settings
            page = 'clients';
            pageTitle = `Edit ${clientInfo.name}'s Client Info`;
            return true;
        }

        requestClassProps.errorHandler = () => {
            push('/not-found');
        }

        const getClientInfoRequest = new Request(requestClassProps);

        loadedClient = await getClientInfoRequest.requestData();
    };

    const getClientInfoForDashboard = async() => {
        // Always replace the data for the updated client on the dashboard
        const getClientResponse = await api.getClientData(clientId);
        if (getClientResponse !== undefined && getClientResponse.status < 400) {
            $clientAfterUpdate = getClientResponse.data;
        } else {
            $statusHandler = {
                type: 'error',
                message: `Something happened when we tried to get client, <strong>${clientInfo.name}</strong>`
            }
        }
    };

    // Formating
    const requiredFields = async () => {
        // Button UX
        updating = true;

        // Required Fields
        if (clientInfo.users[0].email.length === 0 && clientInfo.name.length === 0) {
            error = true;
            errorMessage = 'Name and E-mail must be filled out';
            return 'error';
        }
        if (clientInfo.users[0].email.length === 0) {
            error = true;
            errorMessage = 'E-mail cannot be blank';
            return 'error';
        }
        if (clientInfo.name.length === 0) {
            error = true;
            errorMessage = 'Name cannot be blank';
            return 'error';
        }
        if (clientInfo.office === null) {
            error = true;
            errorMessage = 'You must select an office location';
            return 'error';
        }
        if (clientInfo.users[0].email.split('@')[0].length > 64) {
            error = true;
            errorMessage = 'Your e-mail exceeds the valid length';
            return 'error';
        }
        if (
            $location !== '/new-client' &&
            clientInfo.trainer !== $trainerDataStore.id &&
            clientInfo.reason === ''
        ) {
            error = true;
            errorMessage = "Please enter a reason for the client's transfer";
            return 'error';
        }

        if ($location === '/new-client') {
            return createNewClient();
        } else {
            return updateClientInfo();
        }
    };

    const isNumericInput = event => {
        const key = event.keyCode;
        return (key >= 48 && key <= 57) || (key >= 96 && key <= 105);
    };

    const isModifierKey = event => {
        const key = event.keyCode;
        return (
            event.shiftKey === true ||
            key === 35 ||
            key === 36 ||
            (key === 8 || key === 9 || key === 13 || key === 46) ||
            (key > 36 && key < 41) ||
            ((event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
        );
    };

    const enforceFormat = event => {
        if (!isNumericInput(event) && !isModifierKey(event)) {
            event.preventDefault();
        }
    };

    const formatToPhone = event => {
        if (isModifierKey(event)) return;

        const { target } = event;
        const input = target.value.replace(/\D/g, '').substring(0, 10);
        const zip = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);

        if (input.length > 6) {
            target.value = `(${zip}) ${middle} - ${last}`;
        } else if (input.length > 3) {
            target.value = `(${zip}) ${middle}`;
        } else if (input.length > 0) {
            target.value = `${zip}`;
        }

        clientInfo.phone_number = target.value;
    };

    const formatModalBody = () => {
        const trainer = trainersList.find(trainer => trainer.id === clientInfo.trainer);
        if (trainer === undefined) {
            modalBody = 'Trainer selected was not found';
        } else {
            modalBody = `Are you sure you want to transfer <b>${clientInfo.name}</b> to <b>${trainer.first_name} ${trainer.last_name}</b>?`;
        }
    };
</script>

<Modal
    on:showModal="{e => (openModal = e.detail)}"
    modalTitle="{'warning'}"
    secondaryModalButtonStyle="{'danger'}"
    {modalBody}
    {openModal}
    cancelButtonText="{'Cancel'}"
    confirmButtonText="{'Transfer'}"
    confirmButtonFunction="{() => {
        updateClientInfo(false, true);
        openModal = false;
    }}" />

<div class="column column-full container w90">
    <div class="page-content clearfix">
        <FormButtons
            saveFn="{requiredFields}"
            id="{clientId}"
            {errorMessage}
            {error}
            hasPlan="{clientInfo.has_plan}"
            {page}
            bind:message="{message}"
            bind:saving="{updating}" />

        {#if loadedSelectData && loadedClient}
            <h1 class="page-title">{pageTitle}</h1>
            
            <div class="form-wrapper">
                <form>
                    <div class="field">
                        <label for="client-name">
                            Name
                            <span class="required">*</span>
                        </label>
                        <input
                            id="client-name"
                            type="text"
                            required
                            placeholder="Name"
                            bind:value="{clientInfo.name}"
                            data-cy="name" />
                    </div>

                    <div class="field">
                        <label for="client-email">
                            Email
                            <span class="required">*</span>
                        </label>
                        <input
                            id="client-email"
                            type="email"
                            required
                            placeholder="Email"
                            bind:value="{clientInfo.users[0].email}"
                            data-cy="email" />
                    </div>

                    <div class="field">
                        <label for="client-phone-number">Phone Number</label>
                        <input
                            type="text"
                            id="client-phone-number"
                            placeholder="Phone Number"
                            on:keydown="{enforceFormat}"
                            on:keyup="{formatToPhone}"
                            bind:value="{clientInfo.phone_number}" />
                    </div>

                    <div class="field">
                        <label for="client-city">City</label>
                        <input 
                            type="text" 
                            placeholder="City"
                            id="client-city"
                            bind:value="{clientInfo.city}" />
                    </div>

                    <div class="field split half-width">
                        <label for="client-state">State</label>
                        <select id="client-state" bind:value="{clientInfo.state}">
                            <option value="">--</option>
                            {#each states as state}
                                <option value="{state.abbreviation}">{state.name}</option>
                            {/each}
                        </select>
                    </div>

                    <div class="field split half-width last">
                        <label for="client-postal-code">Postal Code</label>
                        <input
                            id="client-postal-code"
                            type="text"
                            placeholder="Postal Code"
                            bind:value="{clientInfo.postal_code}" />
                    </div>

                    <div class="field">
                        <label for="client-dependents">Kids/Dependents</label>
                        <input
                            id="client-dependents"
                            type="number" 
                            placeholder="0" 
                            bind:value="{clientInfo.dependents}" />
                    </div>

                    <div class="field split half-width">
                        <label for="client-employer">Employer</label>
                        <input
                            id="client-employer"
                            type="text"
                            placeholder="Employer"
                            bind:value="{clientInfo.employer}" />
                    </div>

                    <div class="field split half-width last">
                        <label for="client-occupation">Occupation</label>
                        <input
                            id="client-occupation"
                            type="text"
                            placeholder="Occupation"
                            bind:value="{clientInfo.occupation}" />
                    </div>

                    <div class="field split half-width">
                        <label for="client-birthday">Birthday</label>
                        <input
                            id="client-birthday"
                            type="date"
                            placeholder="MM/DD/YYYY"
                            bind:value="{clientInfo.birth_date}" />
                    </div>

                    <div class="field split half-width last">
                        <label for="client-start-date">Start Date</label>
                        <input
                            id="client-start-date"
                            type="date" 
                            bind:value="{clientInfo.start_date}" />
                    </div>

                    <div class="field">
                        <label for="client-referral">Referral</label>
                        <input
                            id="client-referral"
                            type="text"
                            placeholder="Referral"
                            bind:value="{clientInfo.referral}" />
                    </div>

                    <div class="field split half-width">
                        <label for="client-monthly-rate">Monthly Rate $</label>
                        <input
                            id="client-monthly-rate"
                            type="number"
                            placeholder="0.00"
                            bind:value="{clientInfo.monthly_rate}" />
                    </div>

                    <div class="field split half-width last">
                        <label for="client-office">
                            Office
                            <span class="required">*</span>
                        </label>
                        <select id="client-office" bind:value="{clientInfo.office}">
                            {#each officeLocations as office}
                                <option value="{office.value}">{office.name}</option>
                            {/each}
                        </select>
                    </div>

                    {#if $location !== '/new-client'}
                        <div class="group">
                            <div
                                id="trainer-input"
                                class="{clientInfo.trainer !== $trainerDataStore.id ? 'field split half-width' : 'field'}">
                                <label for="client-trainer">Trainer</label>
                                <!-- svelte-ignore a11y-no-onchange --> 
                                <select
                                    id="client-trainer"
                                    bind:value="{clientInfo.trainer}"
                                    on:change="{formatModalBody}">
                                    {#each trainersList as trainer}
                                        <option value="{trainer.id}">
                                            {trainer.last_name}, {trainer.first_name}
                                        </option>
                                    {/each}
                                </select>
                            </div>

                            {#if clientInfo.trainer !== $trainerDataStore.id}
                                <div id="reason-input" class="field last split half-width">
                                    <LimitedCharInput
                                        label="client-reason"
                                        maxLength="200"
                                        placeholder="Reason"
                                        bind:value="{clientInfo.reason}"
                                        required="{true}" />
                                </div>
                            {/if}
                        </div>
                    {/if}
                </form>
            </div>
        {:else}
            <div class="spin">
                <FeatherIcon icon="aperture" />
            </div>
        {/if}
    </div>
</div>

<style lang="stylus" scoped>
    .spin
        text-align center
        display block

    @media screen and (min-width 1025px)
        .page-content
            padding-bottom 100px

    @media screen and (max-width 480px)
        #trainer-input
            width 100%
        #reason-input
            width 100%
            margin-left 0
</style>
