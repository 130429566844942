<script>
    import { onMount, onDestroy } from 'svelte';
    import { push } from 'svelte-spa-router'
    import { loggedIn, trainerDataStore } from '../../shared/store';
    import FeatherIcon from '../../components/UXElements/FeatherIcon.svelte';
    export let logOut;

    const closeMobileProfileActions = () => {
        document.getElementById("mobProfile").classList.remove("open");
    };

    onMount(() => {
        window.addEventListener("click", handleClick);
        window.addEventListener("touchstart", handleClick);
    });
    onDestroy(() => {
        window.removeEventListener("click", handleClick);
        window.removeEventListener("touchstart", handleClick);
    });

    const handleClick = (e) => {
        if ($loggedIn) {
            const link = document.getElementById("mobProfileLink");
            const menu = document.getElementById("mobProfile");
            if (menu.classList.contains('open') && !link.contains(e.target) && !menu.contains(e.target)) {
                closeMobileProfileActions();
            }
        }

    };

    $: trainerName = $trainerDataStore.first_name + ' ' + $trainerDataStore.last_name;
</script>

<div class="profile-actions" id="mobProfile">
    {#if $loggedIn}
        <div class="profile-info">
            <div class="container">
                <button 
                    class="close close-profile button icon-button" 
                    title="close panel"
                    aria-label="close panel"
                    rel="mobProfile" >
                    <span class="screen-reader-text">Close Panel</span>
                    <FeatherIcon icon="x" />
                </button>
                <img
                    class="image-round image-round-med"
                    src="{$trainerDataStore.users.length > 0 ? $trainerDataStore.users[0].gravatar_url : `${S3BUCKET_URL}/logo-mark.jpg`}"
                    alt="User's Name" />
                <h3>{ trainerName }</h3>
                <!--
                <h4 class="gray-dark">Manhattan</h4>
                <p class="accent">Level 2 Trainer</p>
                -->
            </div>
            <hr/>

            <!--
            <div class="profile-links container">
                <p><a href="#">My Profile</a></p>
                <p><a href="#">Switch to User Account</a></p>
            </div>
            <hr/>
            -->

            <div class="logout container">
                <button
                    class="button icon-button"
                    on:click|preventDefault="{() => {
                    closeMobileProfileActions();
                    logOut();
                }}">
                    Log Out
                </button>
            </div>
        </div>
    {/if}
</div>
<!-- end .profile-actions -->

<style lang="stylus" scoped>
    .icon-button
        &:hover
            color var(--color-green-light)

    .profile-info
        margin-top 1em
</style>
