const accounting = require('../shared/accounting_config');

const getMinMonthlyPayment = (homePrice, mortgageInterestRate, yearlyMortgageTerm, downPayment) => {
    if (downPayment === undefined) {
        downPayment = homePrice * 0.2;
    }
    const principal = homePrice - downPayment; // 80% of house cost after 20% is used as a down payment
    const monthlyMortgageRate = mortgageInterestRate / 100 / 12;
    const monthMortgageTerm = yearlyMortgageTerm * 12;

    // M = P[   r(1+r)^n   /   ((1+r)^n)-1)   ]
    const preRoundedMinMonthlyPayment = (
        principal *
        ((monthlyMortgageRate * Math.pow(1 + monthlyMortgageRate, monthMortgageTerm)) /
            (Math.pow(1 + monthlyMortgageRate, monthMortgageTerm) - 1))
    );
    return isNaN(preRoundedMinMonthlyPayment) ? 0 : accounting.toFixed(preRoundedMinMonthlyPayment, 2);
};

const getMonthlyPropertyTaxes = (homePrice, taxRate) => {
    const result = (homePrice * (taxRate / 100)) / 12;
    return isNaN(result) ? 0 : result;
}

const getMonthlyHomeownersInsurance = (annualHomeownersInsurance) => {
    const result = annualHomeownersInsurance / 12;
    return isNaN(result) ? 0 : result
}

const getOtherCosts = (annualMaintenanceFee) => {
    const result = accounting.unformat(
        accounting.toFixed(
            annualMaintenanceFee / 12,
            2)
    );

    return isNaN(result) ? 0 : result;
}

// Get Monthly Amount

const getMonthlyAmount = (homePrice, mortgageInterestRate, taxRate, annualHomeownersInsurance, annualMaintenanceFee, yearlyMortgageTerm = 30, downPayment) => {
    const minMonthlyPayment = getMinMonthlyPayment(homePrice, mortgageInterestRate, yearlyMortgageTerm, downPayment);
    const monthlyPropertyTaxes = getMonthlyPropertyTaxes(homePrice, taxRate);
    const monthlyHomeownersInsurance = getMonthlyHomeownersInsurance(annualHomeownersInsurance);
    const otherCosts = getOtherCosts(annualMaintenanceFee);

    return accounting.unformat(
        accounting.toFixed(
            (parseFloat(minMonthlyPayment) +
            parseFloat(monthlyPropertyTaxes) +
            parseFloat(monthlyHomeownersInsurance) +
             parseFloat(otherCosts)), 2)
    )
}

const getPMT = (months, presentValue, futureValue, interest = 7, type) => {
    /*
     * Formula:
     *      Compound interest for principal:
     *      P(1+r/n)^(nt)
     *
     *      Compound interest for additional payments (Series Calculation) for payment at beginning of month:
     *      PMT × {[(1 + r/n)^(nt) - 1] / (r/n)} × (1+r/n)
     *
     *      Future Value = Compound interest for principal + Compound interest for additional payments
     *
     * type = when the payments are due:
     *        0: end of the period
     *        1: beginning of period (default)
     * P = principal amount
     * PMT = monthly contributions
     * r = annual interest rate as decimal
     * n = number of times compounded per unit t (ex 12 times per year (t is in years))
     * t = time the money is invested for
     */

    const r = interest / 100 / 12;
    const n = 1;
    const t = months;

    if (!futureValue) {
        futureValue = 0;
    }
    if (!type) {
        type = 1;
    }
    if (r === 0) {
        return -(presentValue + futureValue) / (n * t);
    }

    const compoundInterest = Math.pow(1 + (r / n), (n * t));
    const seriesCalculation = (compoundInterest - 1) / (r / n);

    let pmt = (futureValue - (presentValue * compoundInterest)) / seriesCalculation;

    if (type === 1) {
        pmt /= (1 + (r / n));
    }

    return pmt;
}

const getMonthlyEducationsSavingsAmount = (monthsRemaining, balance, amount) => {
    return accounting.unformat(
        accounting.toFixed(
            getPMT(monthsRemaining, balance, amount)
            , 0)
    )
}

function getFutureValue(years, contribution, presentValue, interest = 7, type) {
    presentValue = presentValue || 0;
    type = type || 1;

    interest /= 100;
    const pow = Math.pow(1 + interest, years);
    let futureValue;

    futureValue =
        (contribution * (1 + interest * type) * (1 - pow)) / interest - presentValue * pow;

    return futureValue * -1;
}

function getRetirementSavings(current, other, preTax, postTax, currentContribution, currentSalary, recommendedSalary) {
    let futureRetirementSavingsPerMonth = 0;

    const recommendedCurrentContributionPercentage = parseFloat(current) || 0;
    const recommendedOtherContributionPercentage = parseFloat(other) || 0;
    const recommendedPreTaxContributionPercentage = parseFloat(preTax) || 0;
    const recommendedPostTaxContributionPercentage = parseFloat(postTax) || 0;
    const currentContributionPercentage = parseFloat(currentContribution) || 0;
    const currentRetirementSalary = parseFloat(currentSalary) || 0;
    const recommendedRetirementSalary = parseFloat(recommendedSalary) || 0;

    const recommendedTotalContributionPercentage = recommendedCurrentContributionPercentage + recommendedOtherContributionPercentage + recommendedPreTaxContributionPercentage + recommendedPostTaxContributionPercentage;

    // New retirement contribution should be 0 to start, no matter what the current percent is.
    // If the trainer changes this percentage, then the sidebar line item for retirement contribution should be calculated as follows:
    // [(Rec contribution% * Salary on Recommended slide) -  (Current contribution% * Salary on Current slide)]   /   12
    if (recommendedTotalContributionPercentage === 0) {
        futureRetirementSavingsPerMonth = 0;
    } else {
        futureRetirementSavingsPerMonth = ((recommendedTotalContributionPercentage * recommendedRetirementSalary) - (currentContributionPercentage * currentRetirementSalary)) / 100 / 12;
    }

    return futureRetirementSavingsPerMonth;
}

module.exports = {
    getFutureValue,
    getMinMonthlyPayment,
    getMonthlyPropertyTaxes,
    getMonthlyHomeownersInsurance,
    getOtherCosts,
    getMonthlyAmount,
    getPMT,
    getMonthlyEducationsSavingsAmount,
    getRetirementSavings
}