<script>
    import accounting from '../../shared/accounting_config';
    import DebtMinimumsTable from './DebtMinimumsTable.svelte';

    export let wizard;
    let minDebtPayments;

    $: fixedExpenses = wizard.expenseManager.getByType('fixed');
    $: variableExpenses = wizard.expenseManager.getByType('variable');

    $: fixedTotal =
        fixedExpenses.reduce((total, expense) => {
            return total + parseFloat(accounting.toFixed(expense.getMonthAmount(), 0));
        }, 0) + parseFloat(minDebtPayments);
    $: variableTotal = variableExpenses.reduce((total, expense) => {
        return total + parseFloat(accounting.toFixed(expense.getMonthAmount(), 0));
    }, 0);
    $: expenseTotal = parseFloat(fixedTotal) + parseFloat(variableTotal);
</script>

<h1 class="page-title">Current Budget</h1>

<table class="two-column-table">
    <thead>
        <tr>
            <th colspan="2">Fixed</th>
        </tr>
    </thead>
    <tbody>
        {#each fixedExpenses as fixedExpense}
            <tr>
                <th>{fixedExpense.name}</th>
                <td>{accounting.formatMoney(fixedExpense.getMonthAmount())}</td>
            </tr>
        {/each}
        <DebtMinimumsTable {wizard} bind:minDebtPayments />
        <tr>
            <th>Total</th>
            <td>{accounting.formatMoney(fixedTotal)}</td>
        </tr>
    </tbody>
</table>

<table class="two-column-table">
    <thead>
        <tr>
            <th colspan="2">Variable</th>
        </tr>
    </thead>
    <tbody>
        {#each variableExpenses as variableExpense}
            <tr>
                <th>{variableExpense.name}</th>
                <td>{accounting.formatMoney(variableExpense.getMonthAmount())}</td>
            </tr>
        {/each}
        <tr>
            <th>Total</th>
            <td>{accounting.formatMoney(variableTotal)}</td>
        </tr>
    </tbody>
</table>

<table class="two-column-table">
    <tbody>
        <tr>
            <th>Total</th>
            <td>{accounting.formatMoney(expenseTotal)}</td>
        </tr>
    </tbody>
</table>
