/* jshint esversion: 6 */
const BaseManager = require('./BaseManager');
const Goal = require('../Models/Goal');

class GoalManager extends BaseManager {
    constructor() {
        super();
        this.allGoals = [];
        this.activeGoals = [];
        this.idealGoals = [];
        this.goalIds = [];
        this.today = new Date();
        this.allTotalMonth = 0;
        this.allTotalAnnual = 0;
        this.activeTotalAnnual = 0;
        this.activeTotalMonth = 0;
        this.idealTotalAnnual = 0;
        this.idealTotalMonth = 0;
    }

    addGoal(account) {
        const goal = new Goal(account);
        // All Goals
        this.allGoals.push(goal);
        this.allTotalAnnual += goal.newContribution * 12;
        this.allTotalMonth += goal.newContribution;
        // Active Goals
        if (goal.include) {
            this.activeGoals.push(goal);
            this.activeTotalAnnual += goal.newContribution * 12;
            this.activeTotalMonth += goal.newContribution;
        } else {
            // Ideal Goals
            this.idealGoals.push(goal);
            this.idealTotalAnnual += goal.newContribution * 12;
            this.idealTotalMonth += goal.newContribution;
        }
        return goal;
    }

    convertToDataStore(onSave) {
        const planFields = ['plan_month_amount', 'plan_monthly_projected_rent', 'plan_security_deposit', 'plan_brokers_fee', 'plan_movers_cost'];
        const dataStore = [];
        this.allGoals.forEach(goal => {
            const tempGoal = {};
            Object.entries(goal.serverStructure).forEach(entry => {
                if (onSave && goal[entry[0]] === undefined && planFields.includes(entry[1])) {
                    goal[entry[0]] = 0;
                }
                tempGoal[entry[1]] = goal[entry[0]];
            });
            dataStore.push(tempGoal);
        });
        return dataStore;
    }

    hasGoals(types, includedOption) {
        // Find array to check
        const arrayToCheck = this[includedOption];
        if (arrayToCheck === undefined) {
            // eslint-disable-next-line no-console
            console.error('Invalid includedOption');
            return false;
        }
        // Check the array for a goal type
        if (types.includes('all')) {
            return arrayToCheck.length > 0;
        }
        return arrayToCheck.filter(goal => types.includes(goal.type.name)).length > 0;
    }

    getByTypes(types, includedOption) {
        const arrayToCheck = this[includedOption];
        if (arrayToCheck === undefined) {
            // eslint-disable-next-line no-console
            console.error('Invalid includedOption');
            return null;
        }
        return arrayToCheck.filter(goal => types.includes(goal.type.name));
    }

    getMonthsToFurthestCompletion() {
        let furthest = 0;
        this.allGoals.forEach(goal => {
            const initial = goal.getMonthsToCompletion();
            if (initial >= goal.getMonthsToCompletion()) {
                furthest = goal.getMonthsToCompletion();
            }
        });
        return furthest;
    }

    getYearsToFurthestCompletion() {
        let furthest = 0;
        let current = 0;
        this.allGoals.forEach(goal => {
            current = goal.getMonthsToCompletion();
            if (current >= furthest) {
                furthest = goal.getMonthsToCompletion();
            }
        });
        return Math.round(furthest / 12);
    }

    getMostRecentByType(type) {
        let mostRecent = null;
        this.allGoals.forEach(goal => {
            if (goal.type.name.toLowerCase() === type) {
                if (
                    mostRecent === null ||
                    new Date(goal.dateCreated) < new Date(mostRecent.dateCreated)
                ) {
                    mostRecent = goal;
                }
            }
        });
        return mostRecent;
    }

    getTotalNewContibution() {
        return this.allGoals.reduce((total, goal) => {
            return total + goal.newContribution;
        }, 0);
    }

    getByLength(length) {
        return this.allGoals.filter(goal => goal.getGoalLength() === length);
    }
}

module.exports = GoalManager;
