/* eslint-disable indent */
/* eslint-disable camelcase */
import { push } from 'svelte-spa-router';
import dayjs from 'dayjs';
import api from './api_config';

/**
 * @API Functions Son
 */
export const login = async (email, password) => {
    return api
        .post('api-token-auth/', { email, password })
        .then(response => {
            // set Authorization token to axios API instance
            api.defaults.headers.Authorization = `JWT ${response.data.token}`;
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('expiring', dayjs().add(23, 'h', 58, 'm', 30, 's'));
            setRefreshTimeout();
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

export const sso_authenticate = async (email, token) => {
    return api
        .get(`sso/authenticate?email=${encodeURIComponent(email)}&user_token=${token}`)
        .then(response => {
            // set Authorization token to axios API instance
            api.defaults.headers.Authorization = `JWT ${response.data.token}`;
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('expiring', dayjs().add(23, 'h', 58, 'm', 30, 's'));
            setRefreshTimeout();
            return response;
        })
        .catch(error => {
            return error.response;
        });
}

export const refreshToken = async (token) => {
    return api
        .post('api-token-refresh/', { token })
        .then(response => {
            api.defaults.headers.Authorization = `JWT ${response.data.token}`;
            localStorage.setItem('token', response.data.token);
            return response;
        })
        .catch(error => {
            localStorage.removeItem('token');
            push('/login');
            return error.response;
        });
};

let refreshTimeout = null;
const setRefreshTimeout = () => {
    clearTimeout(refreshTimeout);
    // Refresh every 2 minutes
    refreshTimeout = setTimeout(runRefreshTimeout, 120000);
}
const runRefreshTimeout = () => {
    const token = localStorage.getItem('token');
    if (token !== null) {
        refreshToken(token);
    }
    setRefreshTimeout();
}
runRefreshTimeout();

/**
 * @function getUserInfo
 * gets initial user response object
 * includes admin, client, trainer permissions and email
 */
export const getUserInfo = async token => {
    if (token) {
        api.defaults.headers.Authorization = `JWT ${token}`;
    }
    return api
        .get('users/')
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

/**
 * @function getUserData
 * takes @param type
 * which will specify whehter the user is a
 * trainers or clients
 * and make the call to /trainers or /clients
 */
export const getUserData = async(type, id) => {
    return api
        .get(`${type}/${id}/`)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

export const getTrainerClients = async(trainerId) => {
    return api
        .get(`trainers/${trainerId}/clients/`)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

/**
 * @function updateTrainerInfo
 * takes @param trainerData and @param trainer_id
 * updates an existing client
 */
export const updateTrainerInfo = async (trainerData, trainer_id) => {
    return api
        .put(`trainers/${trainer_id}/`, trainerData)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

/**
 * @function forgotPassword
 * takses @param email
 * which will send a reset link to email
 * which will include the key
 */
export const forgotPassword = async email => {
    return api
        .post('password-reset/', { email })
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

/**
 * @function resetPassword
 * takes @param token and @param password
 * to reset a user's password
 */
export const resetPassword = async (token, password) => {
    return api
        .post('password-reset/confirm/', { token, password })
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

/**
 * @function getLocations
 * gets all locations
 */
export const getLocations = async ()  => {

    return api
        .get(`locations/`)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

/**
 * @function getClientData
 * takes @param client_id
 * gets individual client data
 */
export const getClientData = async client_id => {
    return api
        .get(`clients/${client_id}/`)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

/**
 * @function createNewClient
 * takes @param clientData
 * creates a new client
 */
export const createNewClient = async clientData => {
    return api
        .post('clients/', clientData)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

/**
 * @function updateClientInfo
 * takes @param clientData and @param client_id
 * updates an existing client
 */
export const updateClientInfo = async (clientData, client_id) => {
    return api
        .put(`clients/${client_id}/`, clientData)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

/**
 * @function get
 * @function create
 * @function update
 * @function remove
 * for individual intake items
 */

export const get = async (client_id, type) => {
    return api
        .get(`clients/${client_id}/${type}/`)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

export const getIntake = async client_id => {
    const [ intakeResponse, clientResponse ] = await Promise.all([
        get(client_id, 'intake'),
        getClientData(client_id)
    ]);

    return {
        intakeResponse,
        clientResponse
    }
};

export const updateIntake = async (client_id, formData) => {
    delete formData.client_info;
    return api
        .post(`clients/${client_id}/intake/`, formData)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};


export const createPlan = async (planData, client_id) => {
    Object.keys(planData.plan).forEach(planKey => {
        planData.plan[planKey] = planData.plan[planKey] === undefined ? null : planData.plan[planKey];
    });

    return api
        .post(`clients/${client_id}/plans/`, planData)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

export const updatePlan = async (planData) => {
    Object.keys(planData.plan).forEach(planKey => {
        planData.plan[planKey] = planData.plan[planKey] === undefined ? null : planData.plan[planKey];
    });

    return api
        .put(`plans/${planData.plan.id}/`, planData)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

export const remove = async (type_id, type) => {
    return api
        .delete(`${type}/${type_id}/`)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};

// Modular function
export const request = async (type, url, params={}) => {
    return api[type](url, params)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
};
