<script>
    /* eslint-disable import/first */
    import { afterUpdate } from 'svelte';

    import FeatherIcon from '../UXElements/FeatherIcon.svelte';
    import NumberInput from '../UXElements/NumberInput.svelte';

    import { intakeDataStore } from '../../shared/store';
    import * as api from '../../shared/api_access';
    import * as timeFrequencies from '../../helpers/time_frequencies';
    import * as domFunctions from '../../helpers/dom_functions';

    const type = 'incomes';
    const { frequencyChoices } = timeFrequencies;
    const payCycleOptions = timeFrequencies.timeFrequencies;

    export let show;

    const addIncome = () => {
        $intakeDataStore[type].push({
            name: '',
            gross_amount: null,
            gross_frequency: 'annual',
            net_amount: null,
            net_frequency: 'paycheck',
            gross_hours: null,
            gross_hours_frequency: 'weekly',
            net_hours: null,
            net_hours_frequency: 'weekly',
            pay_cycle: 'biweekly'
        });
        incomes = $intakeDataStore[type];
    };

    const removeIncome = async (index, id) => {
        $intakeDataStore[type].splice(index, 1);
        incomes = $intakeDataStore[type];
        if (id) {
            await api.remove(id, type);
        }
    };

    afterUpdate(() => {
        // prevent non-numeric texts to be typed
        domFunctions.inputNumbersOnly();
    });

    $: incomes = $intakeDataStore[type];
</script>

<div class="{show ? 'show' : 'collapse'}" aria-labelledby="Income" data-parent="#income">
    <!-- Added income form -->
    {#each incomes as income, index}
        <form class="panel">
            <div class="field">
                <label for="income-name-{index}">
                    Name
                    <button
                        class="button icon-button alert remove"
                        title="Remove this income source"
                        aria-label="Remove this income source"
                        on:click|preventDefault="{async () => removeIncome(index, income.id)}"
                    >
                        <FeatherIcon icon="x" />
                    </button>
                </label>
                <input
                    id="income-name-{index}"
                    type="text"
                    bind:value="{income.name}"
                    name="name" />
            </div>

            <div class="group">
                <div class="field split half-width">
                    <label for="income-gross-{index}">Gross Income</label>
                    <NumberInput id="income-gross-{index}" type={"dollar"} bind:value={income.gross_amount}/>
                </div>
                <div class="field split half-width last">
                    <label for="income-gross-frequency-{index}">Frequency</label>
                    <select id="income-gross-frequency-{index}" bind:value="{income.gross_frequency}">
                        {#each frequencyChoices as option}
                            <option value="{option.value}">{option.name}</option>
                        {/each}
                    </select>
                </div>
            </div>

            {#if income.gross_frequency === 'hour'}
                <div class="group">
                    <div class="field split half-width">
                        <label for="income-gross-hours-{index}">How many hours?</label>
                        <NumberInput id="income-gross-hours-{index}" bind:value={income.gross_hours}/>
                    </div>
                    <div class="field split half-width last">
                        <label for="income-gross-hours-frequency-{index}">Per?</label>
                        <select
                            id="income-gross-hours-frequency-{index}"
                            bind:value="{income.gross_hours_frequency}">
                            {#each payCycleOptions as option}
                                <option value="{option.value}">{option.name}</option>
                            {/each}
                        </select>
                    </div>
                </div>
            {/if}

            <div class="group">
                <div class="field split half-width">
                    <label for="income-net-{index}">Net Income</label>
                    <NumberInput id="income-net-{index}" type={"dollar"} bind:value={income.net_amount}/>
                </div>
                <div class="field split half-width last">
                    <label for="income-net-frequency-{index}">Frequency</label>
                    <select id="income-net-frequency-{index}" bind:value="{income.net_frequency}">
                        {#each frequencyChoices as option}
                            <option value="{option.value}">{option.name}</option>
                        {/each}
                    </select>
                </div>
            </div>

            {#if income.net_frequency === 'hour'}
                <div class="group">
                    <div class="field split half-width">
                        <label for="income-net-hours-{index}">How many hours?</label>
                        <NumberInput id="income-net-hours-{index}" bind:value={income.net_hours}/>
                    </div>
                    <div class="field split half-width last">
                        <label for="income-net-hours-frequency-{index}">Per?</label>
                        <select id="income-net-hours-frequency-{index}" bind:value="{income.net_hours_frequency}">
                            {#each payCycleOptions as option}
                                <option value="{option.value}">{option.name}</option>
                            {/each}
                        </select>
                    </div>
                </div>
            {/if}

            <div class="field">
                <label for="income-pay-cycle-{index}">Payment Cycle</label>
                <select id="income-pay-cycle-{index}" bind:value="{income.pay_cycle}">
                    {#each payCycleOptions as option}
                        <option value="{option.value}">{option.name}</option>
                    {/each}
                </select>
            </div>

        </form>
    {/each}

    <!-- Add Income -->
    <button 
        class="button small primary" 
        on:click|preventDefault="{() => addIncome()}">
        Add Source of Income
    </button>

</div>
