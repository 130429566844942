<script>
    import Fuse from 'fuse.js';
        
    import FeatherIcon from './FeatherIcon.svelte';

    export let searchableArr = [];
    export let searchedResults = [];
    export let input = '';
    export let placeholder = 'Search...'
    export let searchFn = () => {
        let result;
        if (input != '') {
            const options = {
                threshold: 0.1,
                keys: ['name']
            };

            const fuse = new Fuse(searchableArr, options);

            result = fuse.search(input);
        } 
        searchedResults = result || searchableArr;
    }
    export let clearSearchFn = () => {
        if (input !== '') {
            input = '';
        }
    }
</script>

<div class="search field">
    <input 
        type="text" 
        placeholder={placeholder} 
        bind:value={input} 
        on:input={searchFn} />
    {#if input.length > 0}
        <button
            class="alert button icon-button"
            title="Clear Search"
            aria-label="Clear search"
            on:click|preventDefault="{() => clearSearchFn()}">
            <FeatherIcon icon="x" />
        </button>
    {/if}
</div>

<style lang="stylus" scoped>
    .search
        position relative

        .icon-button
            position absolute
            top 8px
            right 8px
</style>
