export default {
    moving: [
        {
            name: 'monthly_projected_rent',
            type: 'number'
        },
        {
            name: 'brokers_fee',
            type: 'number'
        },
        {
            name: 'movers_cost',
            type: 'number'
        },
    ],
    income_producing_property: [
        {
            name: 'interest_rate',
            type: 'number'
        },
        {
            name: 'tax_rate',
            type: 'number'
        },
        {
            name: 'annual_homeowners_insurance',
            type: 'number'
        },
        {
            name: 'monthly_projected_rent',
            type: 'number'
        }
    ],
    home_savings: [
        {
            name: 'interest_rate',
            type: 'number'
        },
        {
            name: 'tax_rate',
            type: 'number'
        },
        {
            name: 'annual_homeowners_insurance',
            type: 'number'
        },
        {
            name: 'annual_maintenance_fee',
            type: 'number'
        }
    ],
    travel_savings: [
        {
            name: 'general_travel',
            type: 'checkbox'
        }
    ]
};
