const Base = require('./Base');
const timeFrequencies = require('../../../helpers/time_frequencies');

class Expense extends Base {
    constructor(name) {
        super(name);
        if (typeof name === 'object') {
            this.amount = parseFloat(name.amount ? name.amount : 0);
            this.clientId = name.client;
            this.frequency = name.frequency;
            this.id = name.id;
            this.name = name.name;
            this.dateCreated = name.date_created ? new Date(name.date_created) : new Date();
            this.timesPerYear = 1;
            this.type = name.expense_type;

            timeFrequencies.timeFrequencies.forEach(choice => {
                if (choice.value === name.frequency) {
                    this.timesPerYear = choice.timesPerYear;
                }
            });
        }
    }

    // Used by SavingsRate component
    getMonthAmount() {
        const monthAmount = parseFloat((this.amount * this.timesPerYear) / 12);
        return parseFloat(monthAmount.toFixed(2));
    }
}

module.exports = Expense;
