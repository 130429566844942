<script>
    import { onMount } from 'svelte';
    import dayjs from 'dayjs';

    import { intakeDataStore, planGeneratorStore } from '../../shared/store';
    import accounting from '../../shared/accounting_config';
    import calculations from '../../helpers/calculations';

    import TrainerCommentComponent from './TrainerCommentComponent.svelte';
    import TopTable from './RetirementPlanning/TopTable.svelte';
    import BottomTable from './RetirementPlanning/BottomTable.svelte';
    import AssetImage from '../UXElements/AssetImage.svelte';

    export let wizard;
    export let slide;
    export let page;

    // Duplicate Page Vars
    let duplicateMounted = false;

    let totalAnnualContribution = 0;

    let recommendedCurrentContributionPercentage = 0;
    let recommendedOtherContributionPercentage = 0;
    let recommendedPreTaxContributionPercentage = 0;
    let recommendedPostTaxContributionPercentage = 0;
    let recommendedTotalContributionPercentage = 0;

    let retirementImageSrc = null;

    $: assetManager = wizard.assetManager;

    $: currentBalance = wizard.getAssets(
        ['401k_trad', '401k_roth', '403b_trad', '403b_roth', '457b', 'tsp'],
        'all', false, false
    );
    $: previousBalance = wizard.getAssets(
        ['401k_trad', '401k_roth', '403b_trad', '403b_roth', '457b', 'simple_ira', 'tsp', 'pension'],
        'all', true, false
    );
    $: otherBalance = wizard.getAssets(
        ['pension'],
        'all', false, false
    );
    $: preTaxBalance = wizard.getAssets(
        ['trad_ira', 'sep', 'simple_ira', 'solo_401k'],
        'all', false, false
    );
    $: postTaxBalance = wizard.getAssets(
        ['roth_ira'],
        'all', false, false
    );

    // Current
    $: if (slide === 'current') {
        totalAnnualContribution = accounting.toFixed(
            (isFinite($planGeneratorStore.plan.retirement_current_salary) ? parseFloat($planGeneratorStore.plan.retirement_current_salary) : 0)
            *
            (isFinite($planGeneratorStore.plan.retirement_current_contribution_percentage) ? parseFloat($planGeneratorStore.plan.retirement_current_contribution_percentage) : 0) / 100
            , 0);

        retirementImageSrc = "retirement_current_allocation.svg";
    }

    // Set years_until_retirement if: (it is the first time loading the plan) and (there is an age entered)
    $: {
        if (
            $planGeneratorStore.plan.years_until_retirement === null &&
            $intakeDataStore.client_info.birth_date !== null
        ) {
            const currentAge = dayjs(new Date()).diff(
                dayjs($intakeDataStore.client_info.birth_date),
                'years'
            );
            $planGeneratorStore.plan.years_until_retirement = 62 - currentAge;
        }
    }

    // Recommended
    $: if (slide === 'recommended') {
        recommendedCurrentContributionPercentage = $planGeneratorStore.plan.target_401k_contribution ? parseFloat($planGeneratorStore.plan.target_401k_contribution) : 0;

        recommendedOtherContributionPercentage = $planGeneratorStore.plan.target_other_contribution ? parseFloat($planGeneratorStore.plan.target_other_contribution) : 0;

        recommendedPreTaxContributionPercentage = $planGeneratorStore.plan.target_ira_contribution ? parseFloat($planGeneratorStore.plan.target_ira_contribution) : 0;

        recommendedPostTaxContributionPercentage = $planGeneratorStore.plan.target_roth_contribution ? parseFloat($planGeneratorStore.plan.target_roth_contribution) : 0;

        recommendedTotalContributionPercentage = recommendedCurrentContributionPercentage + recommendedOtherContributionPercentage + recommendedPreTaxContributionPercentage + recommendedPostTaxContributionPercentage;

        totalAnnualContribution = accounting.toFixed(
            (isFinite($planGeneratorStore.plan.retirement_recommended_salary) ? parseFloat($planGeneratorStore.plan.retirement_recommended_salary) : 0)
            * recommendedTotalContributionPercentage / 100
            , 0 );

        retirementImageSrc = "retirement_recommended_allocation.svg"
    }

    $: totalBalance = currentBalance + previousBalance + otherBalance + preTaxBalance + postTaxBalance;
    $: futureValue = Math.round(
        calculations.getFutureValue(
            $planGeneratorStore.plan.years_until_retirement
                ? $planGeneratorStore.plan.years_until_retirement
                : 0,
            totalAnnualContribution,
            totalBalance
        )
    );
    $: if (page.duplicate) {
        if (!page.id && page.data.length === 0) {
            page.data = {
                name: `Retirement Planning: ${slide} - ${parseInt(page.title.split('_')[1]) + 1}`,
                show_image: true
            };

            if (slide === 'current') {
                page.data[`${page.title}_quill_text_0`] = JSON.stringify({"ops":[{"insert":"Saving for retirement is one of the most important long-term financial goals. Conventional wisdom tells us that we should make contributing to our retirement plans a top priority, and this is true, but ONLY IF you can afford to do so without putting unnecessary strain on your day to day finances. Let’s review your current retirement planning strategy and see how we can optimize it!\n"}]});

                page.data[`${page.title}_quill_text_1`] = JSON.stringify({"ops":[{"insert":"Based on this breakdown, you are saving "},{"attributes":{"bold":true},"insert":"XX"},{"insert":"% per year towards retirement, this equates to $"},{"attributes":{"bold":true},"insert":"XXX"},{"insert":"per year. Assuming you continue to contribute this same amount for the next "},{"attributes":{"bold":true},"insert":`${!isNaN(parseInt($planGeneratorStore.plan.years_until_retirement)) ? ($planGeneratorStore.plan.years_until_retirement !== -1) && ($planGeneratorStore.plan.years_until_retirement !== 1) ? $planGeneratorStore.plan.years_until_retirement + ' years' : $planGeneratorStore.plan.years_until_retirement + ' year' : "PLEASE ADJUST YEARS UNTIL RETIREMENT"}`},{"insert":`, how much will you have when you retire?\n`}]});
            } else {
                page.data[`${page.title}_quill_text_0`] = $planGeneratorStore.plan.retirement_savings_trainer_comments
            }
        }
    }

    onMount(() => {
        if (page.duplicate) {
            duplicateMounted = true;
        }
    });
</script>
<div id="retirement-planning">
    <h1 class="page-title">
        {#if !page.duplicate}
            Retirement Planning: {slide}
        {:else}
            <div class="input-group hide-print">
                <input type="text" placeholder="Name" bind:value={page.data.name} />
            </div>
            <span class="show-print">{page.data.name}</span>
        {/if}
    </h1>

    {#if slide === 'current'}
        {#if !page.duplicate}
            <p>
                Saving for retirement is one of the most important long-term financial goals. Conventional
                wisdom tells us that we should make contributing to our retirement plans a top priority, and
                this is true, but ONLY IF you can afford to do so without putting unnecessary strain on your day
                to day finances. Let’s review your current retirement planning strategy and see how we can
                optimize it!
            </p>
        {:else}
            <TrainerCommentComponent type="{page.title}_quill_text_0" />
        {/if}
    {/if}

    {#if page.duplicate}
        <div class="hide-print field checkboxes">
            <label class="check-label">
                <input
                    type="checkbox"
                    bind:checked="{page.data.show_image}" />
                <span>
                    {#if page.data.show_image}
                        Hide
                    {:else}
                        Show
                    {/if} Image
                </span>
            </label>
        </div>
    {/if}

    <div class="image-center {page.duplicate ? page.data.show_image ? '' : 'hide' : ''}">
        <AssetImage
            src="svgs/{retirementImageSrc}"
            alt="RecommendedRetirementSnapshotChart"
            imgClass="img-fluid mr-auto ml-auto retirement-image" />
    </div>

    <TopTable
        {duplicateMounted}
        bind:page
        {slide}
        {wizard}
        {currentBalance}
        {previousBalance}
        {otherBalance}
        {preTaxBalance}
        {postTaxBalance} />

    {#if slide === 'current'}
        {#if !page.duplicate}
            <p>
                Based on this breakdown, you are saving {($planGeneratorStore.plan.retirement_current_contribution_percentage ? parseFloat($planGeneratorStore.plan.retirement_current_contribution_percentage) : 0)}% per year towards
                retirement, this equates to {accounting.formatMoney(totalAnnualContribution)} per year. Assuming
                you continue to contribute this same amount for the next 
                {#if !isNaN(parseInt($planGeneratorStore.plan.years_until_retirement))}
                    {$planGeneratorStore.plan.years_until_retirement} year{($planGeneratorStore.plan.years_until_retirement !== 1) && ($planGeneratorStore.plan.years_until_retirement !== -1) ? 's' : ''},
                {:else}
                    <strong>PLEASE ADJUST YEARS UNTIL RETIREMENT</strong>,
                {/if} how much will you have when
                you retire?
            </p>
        {:else}
            <TrainerCommentComponent type="{page.title}_quill_text_1" />
        {/if}
    {/if}

    <BottomTable
        {duplicateMounted}
        bind:page
        {slide}
        {recommendedTotalContributionPercentage}
        {totalBalance}
        {totalAnnualContribution}
        {futureValue} />

    {#if slide === 'recommended'}
        {#if !page.duplicate}
            <TrainerCommentComponent type='retirement_savings_trainer_comments' />
        {:else}
            <TrainerCommentComponent type="{page.title}_quill_text_0" />
        {/if}
    {/if}
</div>

<style lang="stylus" scoped>
    .hide
        display none
    .image-center img
        width 50%
    @media screen and (max-width: 480px)
        .image-center img
            width 100%
</style>
