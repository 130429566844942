<script>
    export let value = null;
    export let selectOptions = null;
    export let type = null;
    export let id = '';
    export let onChangeFn = () => {
        return;
    }
</script>

<!-- svelte-ignore a11y-no-onchange --> 
<select id={id} class="form-control" bind:value on:change={onChangeFn}>
    <option disabled selected style="display: none">Please Select {type}</option>
    {#each selectOptions as choice, index}
        <option value="{choice.value}">{choice.name}</option>
    {/each}
</select>
