<script>
    import { onMount, afterUpdate } from 'svelte';
    import dayjs from 'dayjs';

    import { planGeneratorStore } from '../../shared/store';
    
    import AssetImage from '../UXElements/AssetImage.svelte';

    // Track what the last value of $planGeneratorStore.plan.meeting_date was
    let previousMeetingDate = null;

    // Only fire when we can calculate dates from $planGeneratorStore.plan.meeting_date
    // and when there has been a change to $planGeneratorStore.plan.meeting_date
    $: if (dayjs($planGeneratorStore.plan.meeting_date).isValid() && previousMeetingDate !== $planGeneratorStore.plan.meeting_date) {

        // If any of the dates have not been set yet or if there has been a change to $planGeneratorStore.plan.meeting_date
        if ($planGeneratorStore.plan.nudge_email_two_weeks === null || previousMeetingDate !== null) {
            $planGeneratorStore.plan.nudge_email_two_weeks = dayjs($planGeneratorStore.plan.meeting_date)
            .add('14', 'day')
            .format('YYYY-MM-DD');
        }
        if ($planGeneratorStore.plan.nudge_email_four_weeks === null || previousMeetingDate !== null) {
            $planGeneratorStore.plan.nudge_email_four_weeks = dayjs($planGeneratorStore.plan.meeting_date)
            .add('1', 'month')
            .format('YYYY-MM-DD');
        }
        if ($planGeneratorStore.plan.nudge_email_eight_weeks === null || previousMeetingDate !== null) {
            $planGeneratorStore.plan.nudge_email_eight_weeks = dayjs($planGeneratorStore.plan.meeting_date)
            .add('2', 'month')
            .format('YYYY-MM-DD');
        }
        if ($planGeneratorStore.plan.quarter_review_meeting_date === null || previousMeetingDate !== null) {
            $planGeneratorStore.plan.quarter_review_meeting_date = dayjs($planGeneratorStore.plan.meeting_date)
            .add('3', 'month')
            .format('YYYY-MM-DD');
        }

        // Update our tracking of $planGeneratorStore.plan.meeting_date
        previousMeetingDate = $planGeneratorStore.plan.meeting_date;
    }

    // Format the dates for client/trainer reading
    $: printTwo = dayjs($planGeneratorStore.plan.nudge_email_two_weeks).format('MMMM D, YYYY');
    $: printFour = dayjs($planGeneratorStore.plan.nudge_email_four_weeks).format('MMMM D, YYYY');
    $: printEight = dayjs($planGeneratorStore.plan.nudge_email_eight_weeks).format('MMMM D, YYYY');
    $: printQuarter = dayjs($planGeneratorStore.plan.quarter_review_meeting_date).format('MMMM D, YYYY');
</script>

<h1 class="page-title">Communication Timeline</h1>

<div class="grid communication-timeline">
    <div class="m">
        <div class="vertical-bar"></div>
        <div class="circle top"></div>
        <div class="circle bottom"></div>
    </div>
    <div class="a">
        <div class="horizontal-bar"></div>
        <AssetImage
            imgClass="communication-image" 
            alt="A hand shake" 
            src="images/agreement.png" />
        <input
            class="hide-print form-control"
            type="date"
            bind:value="{$planGeneratorStore.plan.meeting_date}" />
        <h3 class="show-print">{dayjs($planGeneratorStore.plan.meeting_date).format('MMMM D, YYYY')}</h3>
        <h4 class="text-muted">Financial Plan Meeting</h4>
    </div>

    <div class="b">
        <div class="horizontal-bar"></div>
        <AssetImage
            imgClass="communication-image" 
            alt="An envelope" 
            src="images/envelope.png" />
        <input
            class="hide-print form-control"
            type="date"
            bind:value="{$planGeneratorStore.plan.nudge_email_two_weeks}" />
        <h3 class="show-print">{printTwo}</h3>
        <h4 class="text-muted">2 week nudge email*</h4>
    </div>

    <div class="c">
        <div class="horizontal-bar"></div>
        <AssetImage
            imgClass="communication-image" 
            alt="A document and pencil" 
            src="images/contract.png" />
        <input
            class="hide-print form-control"
            type="date"
            bind:value="{$planGeneratorStore.plan.nudge_email_four_weeks}" />
        <h3 class="show-print">{printFour}</h3>
        <h4 class="text-muted">1 month nudge email*</h4>
    </div>

    <div class="d">
        <div class="horizontal-bar"></div>
        <AssetImage
            imgClass="communication-image" 
            alt="A hand holding a coin" 
            src="images/hand.png" />
        <input
            class="hide-print form-control"
            type="date"
            bind:value="{$planGeneratorStore.plan.nudge_email_eight_weeks}" />
        <h3 class="show-print">{printEight}</h3>
        <h4 class="text-muted">2 month nudge email*</h4>
    </div>

    <div class="e">
        <div class="horizontal-bar"></div>
        <AssetImage
            imgClass="communication-image" 
            alt="Money" 
            src="images/money.png" />
        <input
            class="hide-print form-control"
            type="date"
            bind:value="{$planGeneratorStore.plan.quarter_review_meeting_date}" />
        <h3 class="show-print">{printQuarter}</h3>
        <h4 class="text-muted">Quarter Review Meeting*</h4>
    </div>
</div>

<div class="footnote">*Communication dates are estimates only</div>

<style lang="stylus" scoped>
    .footnote {
        margin-top: 2rem
    }

    @media print {
        .footnote {
            margin-top: 10rem
        }
    }
    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr) 10px repeat(3, 1fr);
        grid-auto-rows: 100px;
        grid-gap: 10px 0;
        grid-template-areas: 'a a a m y y y' 'a a a m b b b' 'c c c m b b b' 'c c c m d d d' 'e e e m d d d' 'e e e m z z z';
    }

    .a,
    .b,
    .c,
    .d,
    .e {
        padding: 1rem;
        position: relative;
    }

    .a,
    .c,
    .e {
        padding-right: 2rem;
        text-align: right;

        .horizontal-bar {
            right: -4px;
            top: 36px;
        }
    }

    .b,
    .d {
        padding-left: 2rem;
        text-align: left;

        .horizontal-bar {
            left: -4px;
            top: 36px;
        }
    }

    .a {
        grid-area: a;
    }
    .b {
        grid-area: b;
    }
    .c {
        grid-area: c;
    }
    .d {
        grid-area: d;
    }
    .e {
        grid-area: e;
    }

    h3.hide-print {
        font-size: 1.25rem;
    }

    h4 {
        font-size: 1rem;
    }

    div img {
        margin-bottom: 1rem;
        max-height: 50px;
        max-width: 75px;
    }

    .horizontal-bar {
        background-color: rgba(0, 217, 131, 1);
        height: 2px;
        position: absolute;
        width: 32px;
    }

    .m {
        grid-area: m;
        position: relative;
        text-align: center;

        .vertical-bar {
            background-color: rgba(0, 217, 131, 1);
            position: absolute;
            top: 0;
            right: 4px;
            bottom: 0;
            left: 4px;
            width: 2px;
            z-index: 10;
        }

        .circle {
            background-color: #fff;
            border: 2px solid rgba(0, 217, 131, 1);
            border-radius: 10px;
            height: 10px;
            width: 10px;
            position: absolute;
            z-index: 100;
        }

        .top {
            top: 0;
        }

        .bottom {
            bottom: 0;
        }
    }
</style>
