<script>
    import TrainerCommentComponent from './TrainerCommentComponent.svelte';
</script>

<div>
  <h1 class="page-title">Next 30 Days</h1>
  <TrainerCommentComponent type="{'next_steps_30_days'}" />
</div>

<div>
  <h1 class="page-title">Next 60-90 Days</h1>
  <TrainerCommentComponent type="{'next_steps_60_days'}" />

  <section class="footer">
    <h2>Additional Ways to Make Money at The Gym</h2>
    <p>For more information and to sign up, visit <a href="https://financialgym.com/makemoney">FinancialGym.com/MakeMoney</a></p>

    <h3>Warm-Up Call Associate ($20/hr)</h3>
    <p>Warm-up callers (must be a client for at least 3 months) work on an hourly basis around their own schedules (Monday - Sunday). There is training and on-going assistance led by our Client Experience Team.</p>

    <h3>Refer Friends and Family ($50/ referral)</h3>
    <p>Sign up for our referral program and you could get $50 per referral. A client must stay with The Gym for at least 2 months for you to collect your cash (payouts are monthly).</p>

    <h3>Refer The Gym to Your Employer ($200/ program booked)</h3>
    <p>Bring financial wellness to your office or organization! Refer The Gym to your HR/ Benefits or Employee Experience Manager and receive $200 if a program is booked! Note that if you don’t work at a corporate company but perhaps are involved in volunteer or social organizations, you can use that instead. For sample copy and additional information, visit <a href="https://financialgym.com/makemoney">FinancialGym.com/MakeMoney</a></p>
  </section>
</div>

<style lang="stylus" scoped>
    div
        margin-bottom 3em
</style>
