<script>
    import feather from 'feather-icons';
    export let attrs = {};
    export let icon;
    $: svg = feather.icons[icon].toSvg(attrs);
</script>

<span>
    {@html svg}
</span>
