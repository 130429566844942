<script>
    import { planGeneratorStore } from '../../shared/store';

    import NumberInput from '../UXElements/NumberInput.svelte';

    export let wizard;

    $: currentMonthsOfExpensesSaved = wizard.getCurrentMonthsOfExpensesSaved()
        ? Math.floor(wizard.getCurrentMonthsOfExpensesSaved())
        : 0;
    $: currentCreditScore = isNaN(wizard.getCreditScore()) ? 'TBD' : wizard.getCreditScore();
    $: currentCreditUtilizationRatio =
        wizard.liabilityManager.getCreditUtilizationRatio() !== null
            ? `${Math.round(wizard.liabilityManager.getCreditUtilizationRatio() * 100) / 100}%`
            : 'TBD';
    $: currentDebtToIncomeRatio =
        wizard.getDebtToIncomeRatio() !== null
            ? `${Math.round(wizard.getDebtToIncomeRatio() * 100) / 100}%`
            : 'TBD';
    $: currentHasTaxAdvantagedInvestmentAccount = false;
    $: currentHasTaxableInvestmentAccount = false;

    $: if (
        wizard.assetManager.getByInvestmentType(
            [
                '401k_trad',
                '401k_roth',
                'trad_ira',
                'roth_ira',
                'sep',
                '403b_trad',
                '403b_roth',
                '457b',
                'pension',
                'solo_401k',
                'simple_ira',
                'hsa',
                '529',
                'coverdell_esa',
                'tsp'
            ],
            'all'
        ).length > 0
    ) {
        currentHasTaxAdvantagedInvestmentAccount = 'Yes';
    } else {
        currentHasTaxAdvantagedInvestmentAccount = 'No';
    }

    $: if (wizard.assetManager.getByInvestmentType(['taxable'], 'all').length > 0) {
        currentHasTaxableInvestmentAccount = 'Yes';
    } else {
        currentHasTaxableInvestmentAccount = 'No';
    }
</script>

<h1 class="page-title">The Digits: Scorecard</h1>

<table class="three-column-table">
    <thead>
        <tr>
            <th>Assets</th>
            <th>Current</th>
            <th>Target</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th>Savings Rate</th>
            <td>
                <div class="input-group hide-print">
                    <NumberInput
                        placeholder="Current Savings Rate"
                        type="percent"
                        bind:value="{$planGeneratorStore.plan.current_savings_rate}" />
                </div>
                <span class="show-print">
                    {$planGeneratorStore.plan.current_savings_rate ? `${$planGeneratorStore.plan.current_savings_rate}%` : ''}
                </span>
            </td>
            <td>
                <div class="input-group hide-print">
                    <NumberInput
                        placeholder="10"
                        type="percent"
                        bind:value="{$planGeneratorStore.plan.target_savings_rate}" />
                </div>
                <span class="show-print">
                    {$planGeneratorStore.plan.target_savings_rate ? `${$planGeneratorStore.plan.target_savings_rate}%` : ''}
                </span>
            </td>
        </tr>
        <tr>
            <th>Months of Expenses Saved</th>
            <td>{currentMonthsOfExpensesSaved}</td>
            <td>
                <NumberInput
                    placeholder="6"
                    type="target"
                    bind:value="{$planGeneratorStore.plan.target_months_of_expenses_saved}" />
                <span class="show-print">
                    {$planGeneratorStore.plan.target_months_of_expenses_saved}
                </span>
            </td>
        </tr>
        <tr>
            <th>Tax Advantaged Investment Account</th>
            <td>{currentHasTaxAdvantagedInvestmentAccount}</td>
            <td>
                <div class="field">
                    <select
                        class="hide-print"
                        bind:value="{$planGeneratorStore.plan.target_has_tax_advantaged_investment_account}"
                        data-cy="scorecard-tax-advantaged-investment-account-input">
                        <option value="">Please Select</option>
                        <option
                            value="{true}"
                            selected="{$planGeneratorStore.plan.target_has_tax_advantaged_investment_account}">
                            Yes
                        </option>
                        <option
                            value="{false}"
                            selected="{$planGeneratorStore.plan.target_has_tax_advantaged_investment_account}">
                            No
                        </option>
                    </select>
                </div>
                <span class="show-print">
                    {$planGeneratorStore.plan.target_has_tax_advantaged_investment_account ? 'Yes' : 'No'}
                </span>
            </td>
        </tr>
        <tr>
            <th>Taxable Investment Account</th>
            <td>{currentHasTaxableInvestmentAccount}</td>
            <td>
                <div class="field">
                    <select
                        class="hide-print"
                        bind:value="{$planGeneratorStore.plan.target_has_taxable_investment_account}"
                        data-cy="scorecard-taxable-investment-account-input">
                        <option value="">Please Select</option>
                        <option
                            value="{true}"
                            selected="{$planGeneratorStore.plan.target_has_taxable_investment_account}">
                            Yes
                        </option>
                        <option
                            value="{false}"
                            selected="{$planGeneratorStore.plan.target_has_taxable_investment_account}">
                            No
                        </option>
                    </select>
                </div>
                <span class="show-print">
                    {$planGeneratorStore.plan.target_has_taxable_investment_account ? 'Yes' : 'No'}
                </span>
            </td>
        </tr>
    </tbody>
</table>

<table class="three-column-table">
    <thead>
        <tr>
            <th>Liabilities</th>
            <th>Current</th>
            <th>Target</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th>Credit Score</th>
            <td>{currentCreditScore}</td>
            <td>
                <div class="input-group hide-print">
                    <NumberInput
                        placeholder="750"
                        prependCompares=">"
                        type="target"
                        bind:value="{$planGeneratorStore.plan.target_credit_score}" />
                </div>
                <span class="show-print">> {$planGeneratorStore.plan.target_credit_score}</span>
            </td>
        </tr>
        <tr>
            <th>Credit Utilization Ratio</th>
            <td>{currentCreditUtilizationRatio}</td>
            <td>
                <div class="input-group hide-print">
                    <NumberInput
                        placeholder="35"
                        prependCompares="&lt;"
                        type="percent"
                        bind:value="{$planGeneratorStore.plan.target_credit_utilization_ratio}" />
                </div>
                <span class="show-print">
                    &lt; {$planGeneratorStore.plan.target_credit_utilization_ratio}%
                </span>
            </td>
        </tr>
        <tr>
            <th>Debt to Income Ratio</th>
            <td>{currentDebtToIncomeRatio}</td>
            <td>
                <div class="input-group hide-print">
                    <NumberInput
                        placeholder="35"
                        prependCompares="&lt;"
                        type="percent"
                        bind:value="{$planGeneratorStore.plan.target_debt_to_income_ratio}" />
                </div>
                <span class="show-print">
                    &lt; {$planGeneratorStore.plan.target_debt_to_income_ratio}%
                </span>
            </td>
        </tr>
    </tbody>
</table>

<style lang="stylus" scoped>
    @media screen and (max-width 480px)
        table
            display block
            overflow-x auto
</style>
