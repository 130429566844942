/* jshint esversion: 6 */
const CreditScoreManager = require('./ModelManagers/CreditScoreManager');
const ExpenseManager = require('./ModelManagers/ExpenseManager');
const GoalManager = require('./ModelManagers/GoalManager');
const IncomeManager = require('./ModelManagers/IncomeManager');
const AssetManager = require('./ModelManagers/AssetManager');
const LiabilityManager = require('./ModelManagers/LiabilityManager');
const NonNegotiableManager = require('./ModelManagers/NonNegotiableManager');
const RiskManager = require('./ModelManagers/RiskManager');


/**
 * The class `Wizard` handles the raw data and creates
 * the necessary data that the Plan Generator, or any
 * other file requiring manipulated data.
 */
class Wizard {
    constructor(data = {}) {
        try {
            this.data = data;
            this.creditScoreManager = new CreditScoreManager();
            this.expenseManager = new ExpenseManager();
            this.goalManager = new GoalManager();
            this.incomeManager = new IncomeManager();
            this.assetManager = new AssetManager();
            this.liabilityManager = new LiabilityManager();
            this.nonNegotiableManager = new NonNegotiableManager();
            this.riskManager = new RiskManager();

            if (data.credit_scores !== undefined) {
                data.credit_scores.forEach(score => {
                    this.creditScoreManager.add(score);
                });
            }

            if (data.incomes !== undefined) {
                data.incomes.forEach(income => {
                    this.incomeManager.addIncome(income);
                });
            }

            if (data.expenses !== undefined) {
                data.expenses.forEach(expense => {
                    this.expenseManager.addExpense(expense);
                });
            }

            if (data.assets !== undefined) {
                data.assets.forEach(asset => {
                    this.assetManager.addAsset(asset);
                });
            }

            if (data.liabilities !== undefined) {
                data.liabilities.forEach(liability => {
                    this.liabilityManager.addLiability(liability);
                });
            }

            if (data.goals !== undefined) {
                data.goals.forEach(goal => {
                    this.goalManager.addGoal(goal);
                });
            }

            if (data.nonnegotiables !== undefined) {
                data.nonnegotiables.forEach(nonnegotiable => {
                    this.nonNegotiableManager.addNonNegotiable(nonnegotiable);
                });
            }

            if (data.risks !== undefined) {
                data.risks.forEach(risk => {
                    this.riskManager.addRisk(risk);
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    getCreditScore() {
        return this.creditScoreManager.getAverageCreditScore();
    }

    getIncome(type = 'gross', timeRange = 'annually') {
        if (type === 'gross') {
            return timeRange === 'annually'
                ? this.incomeManager.getTotalAnnualGross()
                : this.incomeManager.getTotalMonthGross();
        }
        if (type === 'net') {
            return timeRange === 'annually'
                ? this.incomeManager.getTotalAnnualNet()
                : this.incomeManager.getTotalMonthNet();
        }
        return 0;
    }

    getFirstAnnualGrossIncomeAmount() {
        if (this.incomeManager.incomes.length > 0) {
            return this.incomeManager.incomes[0].grossAmount
        }
        return 0;
    }

    getExpenses(type = 'all', timeRange = 'monthly', breakeven = false) {
        if (type !== 'all' && timeRange === 'monthly') {
            if (breakeven) {
                const liabilities = this.liabilityManager.getTotal();
                const expenses = this.expenseManager.getTotalMonthAmount(type);
                return liabilities + expenses;
            }
            return this.expenseManager.getTotalMonthAmount(type);
        }
        if (type !== 'all' && timeRange === 'annually') {
            return this.expenseManager.getTotalAnnualAmount(type);
        }
        if (type === 'all' && timeRange === 'monthly') {
            const liabilities = this.liabilityManager.getTotal();
            const expenses = this.expenseManager.getTotalMonthAmount();
            return liabilities + expenses;
        }
    }

    getAssets(category, timeRange = 'month', previous = false, includePreviousAndCurrent = true, employerContribution = false) {
        const totalMonthlyGrossIncome = this.getIncome('gross', 'monthly');
        return this.assetManager.getTotal(category, timeRange, totalMonthlyGrossIncome, previous, includePreviousAndCurrent, employerContribution);
    }

    getLiabilities(category = [], timeRange) {
        return this.liabilityManager.getTotal(category, timeRange);
    }

    getRisks() {
        return this.riskManager.getRisks();
    }

    getGoalsByLength(length = 'short') {
        return this.goalManager.getByLength(length);
    }

    getLiabilityPayOffDate(liabilityType = null, paymentToUse = 'min') {
        if (!liabilityType) {
            return this.liabilityManager.getPaidOffDate();
        }

        const lm = new LiabilityManager();
        if (this.data.liabilities !== undefined) {
            this.data.liabilities.forEach(liability => {
                if (liability.category.includes(liabilityType)) {
                    lm.addLiability(liability);
                }
            });
        }
        return lm.getPaidOffDate();
    }

    getNewPaymentAmount(type = null, timeRange = 'monthly') {
        return this.liabilityManager.getTotalNewPayment(type);
    }

    getDebtToIncomeRatio() {
        if (!isNaN(this.getLiabilities('', 'month') / this.getIncome('gross', 'monthly')) && this.getIncome('gross', 'monthly') !== 0) {
            return this.getLiabilities('', 'month') / this.getIncome('gross', 'monthly') * 100;
        }
        return null;
    }

    getSavingsRate() {
        const grossMonthlyIncome = this.getIncome('gross', 'monthly');
        const getAssetContributions = this.assetManager.getContributionByType(['savings', 'investment'], false, grossMonthlyIncome);

        if (grossMonthlyIncome !== 0 && isFinite(getAssetContributions / grossMonthlyIncome)) {
            return getAssetContributions / grossMonthlyIncome * 100;
        }
        return null;
    }

    getCurrentMonthsOfExpensesSaved() {
        if (!isNaN(this.getAssets(['checking', 'savings'], 'all')  / this.getExpenses('all', 'monthly')) && this.getExpenses('all', 'monthly') !== 0) {
            return this.getAssets(['checking', 'savings'], 'all') / this.getExpenses('all', 'monthly');
        }
        return null;
    }

    getCurrentContribution() {
        return this.getAssets(
            ['401k_trad', '401k_roth', '403b_trad', '403b_roth', '457b', 'tsp'],
            'annual', false, false);
    }

    getEmployerContribution() {
        return this.getAssets(
            ['401k_trad', '401k_roth', '403b_trad', '403b_roth', '457b', 'simple_ira', 'tsp'],
            'annual', false, false, true);
    }

    getOtherContribution() {
        return this.getAssets(
            ['pension'],
            'annual', false, false);
    }

    getPreTaxContribution() {
        return this.getAssets(
            ['trad_ira', 'sep', 'simple_ira', 'solo_401k'],
            'annual', false, false);
    }

    getPostTaxContribution() {
        return this.getAssets(
            ['roth_ira'],
            'annual', false, false);
    }

    getEmployerContributionPercentage() {
        return (
            isFinite(this.getEmployerContribution() / this.getIncome('gross', 'annually')) ?
                Math.round(this.getEmployerContribution() / this.getIncome('gross', 'annually') * 1000) / 10 : 0
        );
    }

    getCurrentContributionPercentage() {
        return (
            isFinite(this.getCurrentContribution() / this.getIncome('gross', 'annually')) ?
                Math.round(this.getCurrentContribution() / this.getIncome('gross', 'annually') * 1000) / 10 : 0
        );
    }

    getOtherContributionPercentage() {
        return (
            isFinite(this.getOtherContribution() / this.getIncome('gross', 'annually')) ?
                Math.round(this.getOtherContribution() / this.getIncome('gross', 'annually') * 1000) / 10 : 0
        );
    }

    getPreTaxContributionPercentage(){
        return (
            isFinite(this.getPreTaxContribution() / this.getIncome('gross', 'annually')) ?
                Math.round(this.getPreTaxContribution() / this.getIncome('gross', 'annually') * 1000) / 10 : 0
        );
    }

    getPostTaxContributionPercentage() {
        return (
            isFinite(this.getPostTaxContribution() / this.getIncome('gross', 'annually')) ?
                Math.round(this.getPostTaxContribution() / this.getIncome('gross', 'annually') * 1000) / 10 : 0
        );
    }

    getCurrentTotalContributionPercentage() {
        return (
            Math.round((
                this.getCurrentContributionPercentage() +
                this.getEmployerContributionPercentage() +
                this.getOtherContributionPercentage() +
                this.getPreTaxContributionPercentage() +
                this.getPostTaxContributionPercentage()
            ) * 100) / 100
        );
    }


}

module.exports = Wizard;
