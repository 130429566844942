<script>
    import { planGeneratorStore } from '../../shared/store';
    import accounting from '../../shared/accounting_config';

    import NumberInput from '../UXElements/NumberInput.svelte';
    import AssetImage from '../UXElements/AssetImage.svelte';

    export let wizard;

    $: annualGrossIncome = wizard.getIncome('gross', 'annually');
    $: monthlyNetIncome = wizard.getIncome('net', 'monthly');
    $: monthlyFixedExpenses = wizard.getExpenses('fixed', 'monthly', true);
</script>

<h1 class="page-title">Savings Rate</h1>

<p>
    A financially fit savings rate ranges between 10-20% of your gross income. Based on your
    current income, this would be somewhere between {accounting.formatMoney((annualGrossIncome / 12) * 0.1)}
    and {accounting.formatMoney((annualGrossIncome / 12) * 0.2)} per month.
</p>

<h3>How Much Will You Save?</h3>
<!-- <net monthly income > fixed monthly expenses → omit “Breakeven Goal”> -->
{#if monthlyNetIncome > monthlyFixedExpenses}
    <p>
        I recommend you begin by saving
        <span class="hide-print">
            <span class="input-group">
                <NumberInput additionalClass={true} max="100" bind:value={$planGeneratorStore.plan.target_savings_rate} />
            </span>
        </span>
        <span class="show-print">{$planGeneratorStore.plan.target_savings_rate}</span>% of your gross income, which equals {accounting.formatMoney((annualGrossIncome / 12) * (isNaN($planGeneratorStore.plan.target_savings_rate) ? 0 : $planGeneratorStore.plan.target_savings_rate / 100))}.
    </p>
    <!-- <net monthly income < fixed monthly expenses → jump to “Breakeven Goal”> -->
{:else}
    <p>
        Based on your current income and expenses, you do not have any room in your budget to save
        just yet. We’ll need to work on increasing your income and/or reducing your expenses in
        order to create room in your budget to begin saving.
    </p>
{/if}

<h3>Where to Save?</h3>
<div class="image-center">
    <AssetImage 
        src="images/cma_flow_chart.png"
        alt="Green boxes with savings strategies" 
        imgClass="mr-auto ml-auto" />
</div>

<style lang="stylus" scoped>
    .input-group
        display inline-block
        width 40%
</style>
