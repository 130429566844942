<script>
    import { replace, querystring } from 'svelte-spa-router';

    import * as api from '../../shared/api_access';
    import { loggedIn, userDataStore, trainerDataStore } from '../../shared/store';
    import { parseQueryParams } from '../../helpers/utils';

    const params = parseQueryParams($querystring);
    const user_token = params.user_token;
    const email = params.email;

    const sso_auth = async (email, user_token) => {
        const response = await api.sso_authenticate(email, user_token);
        if (response.status === 200){
            const data = await get_user_data();
            if (!data) {
                console.error("could not get user data");
                localStorage.removeItem('token');
            } else {
                $userDataStore = data.userData;
                $trainerDataStore = data.trainerData;
                loggedIn.set(true);
                return true;
            }
        }
        return false;
    };

    const get_user_data = async () => {
        const { data: userData } = await api.getUserInfo();
        if (userData.trainer === null && userData.client === null) {
            console.log(`Your account is not valid yet.`);
            return false;
        } 
        if (userData.trainer === null && userData.client !== null) {
            console.log(`Sorry, clients aren't allowed to access Training Zone yet!.`);
            return false;
        }

        const { status: trainerStatus, data: trainerData } = await api.request('get', `trainers/${userData.trainer}/`);
        if (trainerStatus >= 400) {
            console.log(`There is something wrong with your account.`);
            return false;
        }
        return {userData: userData, trainerData: trainerData};
    }

    const main = async () => {
        if ($loggedIn) {
            return "/";
        } else {
            const went_ok = await sso_auth(email, user_token);
            if (went_ok){
                return "/";
            } else {
                return "/login";
            }
        }
    }

</script>

{#await main()}
{:then data}
    {replace(data)}
{:catch error}
    {console.error(error)}
    {replace("/login")}
{/await}