/* jshint esversion: 6 */
const Base = require('./Base');
const timeFrequencies = require('../../../helpers/time_frequencies');

class Income extends Base {
    constructor(name) {
        super(name);
        if (typeof name === 'object') {
            // Need pay cycle first incase a gross or net frequency is set to per paycheck
            const that = this; // need to declare that to reference this object in forEach function
            this.payCycle = name.pay_cycle;
            this.payCycleDisplayName = name.pay_cycle;
            this.timesPerYear = timeFrequencies.timeFrequencies.filter(
                entry => entry.value === name.pay_cycle
            )[0].timesPerYear;

            this.grossAmount = parseFloat(name.gross_amount ? name.gross_amount : 0);
            this.grossFrequency = name.gross_frequency;
            this.grossHours = parseFloat(name.gross_hours ? name.gross_hours : 0);
            this.grossHoursFrequency = name.gross_hours_frequency;

            this.grossTimesPerYear = null;
            timeFrequencies.frequencyChoices.forEach(entry => {
                if (entry.value === name.gross_frequency) {
                    if (name.gross_frequency === 'paycheck') {
                        that.grossTimesPerYear = that.timesPerYear;
                    } else if (name.gross_frequency === 'hour') {
                        timeFrequencies.timeFrequencies.forEach(freq => {
                            if (freq.value === name.gross_hours_frequency) {
                                that.grossTimesPerYear = freq.timesPerYear * that.grossHours; // hours per year
                            }
                        });
                    } else {
                        that.grossTimesPerYear = entry.timesPerYear;
                    }
                }
            });

            this.netAmount = parseFloat(name.net_amount ? name.net_amount : 0);
            this.netFrequency = name.net_frequency;
            this.netHours = parseFloat(name.net_hours ? name.net_hours : 0);
            this.netHoursFrequency = name.net_hours_frequency;

            this.netTimesPerYear = null;
            timeFrequencies.frequencyChoices.forEach(entry => {
                if (entry.value === name.net_frequency) {
                    if (entry.value === 'paycheck') {
                        that.netTimesPerYear = that.timesPerYear;
                    } else if (entry.value === 'hour') {
                        timeFrequencies.timeFrequencies.forEach(freq => {
                            if (freq.value === name.net_hours_frequency) {
                                that.netTimesPerYear = freq.timesPerYear * that.netHours; // hours per year
                            }
                        });
                    } else {
                        that.netTimesPerYear = entry.timesPerYear;
                    }
                }
            });

            timeFrequencies.timeFrequencies.forEach(entry => {
                if (entry.value === name.pay_cycle) {
                    that.payCycleDisplayName = entry.name;
                }
            });

            this.clientId = name.client;
            this.dateCreated = name.date_created ? new Date(name.date_created) : new Date();
            this.id = name.id;
            this.name = name.name;
        }
    }

    // Gross
    getAnnualGross() {
        if (!isNaN(this.grossAmount)) {
            return (
                Math.round(parseFloat(this.grossAmount * this.grossTimesPerYear, 10) * 100) / 100
            );
        }
        return null;
    }

    // how should we handle calculating month gross/net for biweekly payments. Currently, this way will average the 2 extra paychecks over 12 months
    getMonthGross() {
        if (!isNaN(this.grossAmount)) {
            return (
                Math.round(parseFloat((this.grossAmount * this.grossTimesPerYear) / 12, 10) * 100) /
                100
            );
        }
        return null;
    }

    getPaycheckGross() {
        if (!isNaN(this.grossAmount)) {
            return (
                Math.round(
                    parseFloat(
                        (this.grossAmount * this.grossTimesPerYear) / this.timesPerYear,
                        10
                    ) * 100
                ) / 100
            );
        }
        return null;
    }

    // Net
    getAnnualNet() {
        if (!isNaN(this.netAmount)) {
            return Math.round(parseFloat(this.netAmount * this.netTimesPerYear, 10) * 100) / 100;
        }
        return null;
    }

    getMonthNet() {
        if (!isNaN(this.netAmount)) {
            return (
                Math.round(parseFloat((this.netAmount * this.netTimesPerYear) / 12, 10) * 100) / 100
            );
        }
        return null;
    }

    getPaycheckNet() {
        if (!isNaN(this.netAmount)) {
            return (
                Math.round(
                    parseFloat((this.netAmount * this.netTimesPerYear) / this.timesPerYear, 10) *
                        100
                ) / 100
            );
        }
        return null;
    }

    getId() {
        return this.id;
    }
}

module.exports = Income;
