const Base = require('./Base');

class NonNegotiable extends Base {
    constructor(name) {
        super(name);
        if (typeof name === 'object') {
            this.description = name.description;
            this.id = name.id;
            this.name = name.name;
            this.dateCreated = name.date_created ? new Date(name.date_created) : new Date();
        }
    }
}

module.exports = NonNegotiable;
