/* jshint esversion: 6 */
const BaseManager = require('./BaseManager');
const NonNegotiable = require('../Models/NonNegotiable');

class NonNegotiableManager extends BaseManager {
    constructor() {
        super();
        this.nonnegotiables = [];
        this.names = [];
        this.nonnegotiableIds = [];
        this.today = new Date();
    }

    addNonNegotiable(account) {
        const nonnegotiable = new NonNegotiable(account);
        this.names.push(nonnegotiable.name);
        this.nonnegotiables.push(nonnegotiable);

        return nonnegotiable;
    }

    getNonNegotiables(){
        return this.nonnegotiables;
    }
}

module.exports = NonNegotiableManager;
