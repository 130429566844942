<script>
    import { intakeDataStore } from '../../../shared/store';
    import accounting from '../../../shared/accounting_config';

    import NumberInput from '../../UXElements/NumberInput.svelte';

    export let wizard;

    $: travelGoals = wizard.goalManager.getByTypes(['travel_savings'], 'activeGoals');

    $: travelGoals.forEach(goal => {
        goal.planMonthAmount = getSaveForDate(goal.planTotalAmount, goal.planMonthsRemaining);
    });

    // Amount needed to be saved per month to reach goal in time
    // [estimated travel budget] / [months until date of planned trip] = [monthly amount to save]
    // Done inside function to keep a good pattern incase future calculations become complex
    function getSaveForDate(amount, monthsRemaining) {
        if (monthsRemaining === 0) {
            // Dividing by 0 should give the answer of:
            // You need this much money in less then a month
            monthsRemaining = 1;
        }
        return accounting.unformat(accounting.toFixed(amount / monthsRemaining, 2));
    }

    // Reactivly update the store whenever a goal's new contribution is changed for the plan side bar
    // Doing this in the PlanGenerator level doesn't work...?
    $: $intakeDataStore.goals = wizard.goalManager.convertToDataStore();
</script>

<div id="travel-savings" class="stop-page-break life-money">
    <h2>Travel</h2>
    <ul>
        <li>
            Travel is something that is important to you, so we need to begin setting aside money
            intentionally for this purpose. We always recommend that our clients set up a separate
            savings account and make ongoing contributions to fund their travel savings.
            <ul>
                {#each travelGoals as travelGoal}
                    <li>
                        {#if travelGoal.generalTravel}
                            Here’s a breakdown for how you should fund your travel savings account:
                        {:else}
                            You'd like to save enough to cover your upcoming trip, here's a breakdown for how to accomplish this goal:
                        {/if}
                        <ul>
                            <li class="grid-row grid-4 hide-print">
                                <!-- [estimated travel budget] / [months until date of planned trip] = [monthly planTotalAmount to save] -->
                                <div class="input-group">
                                    <NumberInput type="dollar" bind:value={travelGoal.planTotalAmount} />
                                </div>
                                <span>/</span>
                                <div class="input-group">
                                    <NumberInput type="months" bind:value={travelGoal.planMonthsRemaining} />
                                </div>
                                <span>= {accounting.formatMoney(travelGoal.planMonthAmount)}</span>
                            </li>
                            <li class="show-print">
                                {accounting.formatMoney(travelGoal.planTotalAmount)} / {travelGoal.planMonthsRemaining}
                                months = {accounting.formatMoney(travelGoal.planMonthAmount)}
                            </li>
                        </ul>
                    </li>
                {/each}
            </ul>
        </li>
    </ul>
</div>
