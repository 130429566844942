<script>
    import TrainerCommentComponent from './TrainerCommentComponent.svelte';
    import AssetImage from '../UXElements/AssetImage.svelte';
</script>

<h1 class="page-title">Investing Goal</h1>

<p>
    In addition to saving for retirement, general investing is a key piece of every financial
    planning strategy. While both retirement savings and general investing involve purchasing
    stocks, bonds, and other investments, there are several key differences.
</p>

<table class="three-column-table">
    <thead>
        <tr>
            <th></th>
            <th>General Investing</th>
            <th>Retirement Account</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Tax-Advantaged</td>
            <td>No</td>
            <td>Yes</td>
        </tr>
        <tr>
            <td>Withdrawal Restrictions</td>
            <td>No</td>
            <td>Yes</td>
        </tr>
        <tr>
            <td>Early Withdrawal Penalty</td>
            <td>No</td>
            <td>Yes</td>
        </tr>
        <tr>
            <td>Contribution Limits</td>
            <td>No</td>
            <td>Yes</td>
        </tr>
    </tbody>
</table>

<p>
    General Investing allows you to plan for major life goals that are more than one year away.
    The timeline for use is key in determining how the assets should be allocated. We use the
    below guidelines to determine the correct mix of stocks and bonds for your general
    investment accounts.
</p>

<div>
    <AssetImage
        src="svgs/investing_short_term_allocation.svg"
        alt="ShortTermAssetAllocationChart" />
    <AssetImage
        src="svgs/investing_medium_term_allocation.svg"
        alt="MediumTermAssetAllocationChart" />
    <AssetImage
        src="svgs/investing_long_term_allocation.svg"
        alt="LongTermAssetAllocationChart" />
</div>
<TrainerCommentComponent type="{'investing_goal_trainer_comments'}" />

<style lang="stylus" scoped>
    div
        display grid
        grid-template-columns repeat(3, 1fr)

    @media screen and (max-width: 480px)
        table
            display block
            overflow-x auto

        div
            grid-template-columns 1fr
</style>
