const Base = require('./Base');

class Risk extends Base {
    constructor(name) {
        super(name);
        if (typeof name === 'object') {
            this.id = name.id;
            this.clientId = name.client;
            this.description = name.description;
            this.type = name.risk_type.name;
            this.possess = name.possess;
            this.dateCreated = name.date_created ? new Date(name.date_created) : new Date();
        }
    }
}

module.exports = Risk;
