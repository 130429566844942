<script>
    import { onMount, afterUpdate, onDestroy } from 'svelte';
    import { slide } from 'svelte/transition';
    import * as easing from 'svelte/easing';
    import { push, location } from 'svelte-spa-router';

    import { intakeDataStore, planGeneratorStore } from '../../shared/store';
    import * as api from '../../shared/api_access';
    import defaultPages from '../../shared/default_pages';
    import { PlanDeepCompare } from '../../classes/DeepCompare/PlanDeepCompare';
    import { GoalsDeepCompare } from '../../classes/DeepCompare/GoalsDeepCompare';
    import { LinkedList } from '../../helpers/page_helpers';
    import domFunctions from '../../helpers/dom_functions';
    import accounting from '../../shared/accounting_config';

    import FormButtons from '../../components/UXElements/FormButtons.svelte';
    import PageButtons from '../../components/UXElements/PageButtons.svelte';
    import Modal from '../../components/UXElements/Modal.svelte';

    import FinancialFitnessPlan from '../../components/PlanGenerator/FinancialFitnessPlan.svelte';
    import EncouragingNote from '../../components/PlanGenerator/EncouragingNote.svelte';
    import WhatYoureWorkingFor from '../../components/PlanGenerator/WhatYoureWorkingFor.svelte';
    import DigitsScorecard from '../../components/PlanGenerator/DigitsScorecard.svelte';
    import CurrentSnapshot from '../../components/PlanGenerator/CurrentSnapshot.svelte';
    import CurrentBudget from '../../components/PlanGenerator/CurrentBudget.svelte';
    import SavingsRate from '../../components/PlanGenerator/SavingsRate.svelte';
    import BreakevenGoal from '../../components/PlanGenerator/BreakevenGoal.svelte';
    import DebtCrushingGoal from '../../components/PlanGenerator/DebtCrushingGoal.svelte';
    import DebtManagementGoal from '../../components/PlanGenerator/DebtManagementGoal.svelte';
    import EducationSavingsGoal from '../../components/PlanGenerator/EducationSavingsGoal.svelte';
    import FireGoal from '../../components/PlanGenerator/FireGoal.svelte';
    import MonthsOfExpensesSaved from '../../components/PlanGenerator/MonthsOfExpensesSaved.svelte';
    import LifeMoneySavingsGoal from '../../components/PlanGenerator/LifeMoneySavingsGoal.svelte';
    import RealEstateGoal from '../../components/PlanGenerator/RealEstateGoal.svelte';
    import RetirementPlanning from '../../components/PlanGenerator/RetirementPlanning.svelte';
    import InvestingGoal from '../../components/PlanGenerator/InvestingGoal.svelte';
    import IncomeGoalComponent from '../../components/PlanGenerator/IncomeGoalComponent.svelte';
    import SpendingGoal from '../../components/PlanGenerator/SpendingGoal.svelte';
    import StudentLoanManagement from '../../components/PlanGenerator/StudentLoanManagement.svelte';
    import RiskManagementChecklist from '../../components/PlanGenerator/RiskManagementChecklist.svelte';
    import NextSteps from '../../components/PlanGenerator/NextSteps.svelte';
    import MoneyMotivationPlaylist from '../../components/PlanGenerator/MoneyMotivationPlaylist.svelte';
    import CommunicationTimeline from '../../components/PlanGenerator/CommunicationTimeline.svelte';
    import ExtraPage from '../../components/PlanGenerator/ExtraPage.svelte';
    import DuplicateUXPage from '../../components/PlanGenerator/DuplicateUXPage.svelte';

    export let props;
    export let wizard;
    export let getIntake;

    const { browser } = window.navigator.browserInfo;
    const windowsOS = navigator.appVersion.indexOf("Win") !== -1;

    const defaultPlan = JSON.stringify($planGeneratorStore);

    let { id: clientId } = props;
    let saving = false;
    let error = false;
    let errorMessage = '';
    let initLoad = true;
    let openModal = false;
    let originalPlan;
    let originalGoals;
    let noSavedPages = false;

    // Duplicate Pages Variables
    let removingDuplicatePage = false;
    let savingDuplicatePage = false;
    let duplicatedPage = true;
    // Using Arrays for duplicatedPages to prevent indexing issues
    const duplicatablePages = {
        'HomeownershipGoal': {
            duplicatedPages: [],
            name: 'Homeownership'
        },
        'IncomeProducingProperty': {
            duplicatedPages: [],
            name: 'Income-Producing Property'
        },
        'EducationSavingsGoal': {
            duplicatedPages: [],
            name: 'Education Savings'
        },
        'FireGoal': {
            duplicatedPages: [],
            name: 'F.I.R.E.'
        },
        'RetirementPlanningCurrent': {
            duplicatedPages: [],
            name: 'Retirement Planning: Current'
        },
        'RetirementPlanningRecommended': {
            duplicatedPages: [],
            name: 'Retirement Planning: Recommended'
        },
        'SpendingGoal': {
            duplicatedPages: [],
            name: 'Spending'
        }
    }

    $: creditScore = wizard.getCreditScore();
    $: totalLiabilities = wizard.getLiabilities(
        [
            'charge_card',
            'credit_card',
            'auto_loan',
            'family',
            'medical',
            'mortgage',
            'personal_loan',
            'tax',
            'other'
        ],
        'all'
    );
    $: totalCreditCardDebt = wizard.getLiabilities(['charge_card', 'credit_card'], 'all');

    let linkedList = new LinkedList();
    let pages = [];

    onMount(async () => {
        $planGeneratorStore.plan.clientID = $intakeDataStore.client_info.id;
        await getPlan();

    });

    const updatePages = (duplicating = false) => {
        if (duplicating) {
            duplicatedPage = false;
            setTimeout(() => {
                duplicatedPage = true;
            }, 1000)
        }
        pages = linkedList.iterateThroughList();
        $planGeneratorStore.pages = pages;
        
    };

    onDestroy(async () => {
        await savePlan(true);
        $planGeneratorStore = JSON.parse(defaultPlan);
    });

    const getPlan = async () => {
        const planResponse = await api.get(props.id, 'plans');

        if (planResponse.status === 200) {
            if (planResponse.data[0]) {
                $planGeneratorStore.plan = {
                    ...$planGeneratorStore.plan,
                    ...planResponse.data[0].plan
                };
                $planGeneratorStore.pages = planResponse.data[0].pages;
            } else {
                $planGeneratorStore = JSON.parse(defaultPlan);
            }

            if ($planGeneratorStore.pages.length === 0) {
                $planGeneratorStore.pages = defaultPages;
                noSavedPages = true;
            }

            linkedList.initLinkedList($planGeneratorStore.pages);
            updatePages();

            $planGeneratorStore.pages.forEach(page => {
                if (page.title.includes('_')) {
                    const [ pageTitle, index ] = page.title.split('_');
                    duplicatablePages[pageTitle].duplicatedPages[index] = true;

                    page.data = JSON.parse(page.data);
                    page = page;
                }
            });

            savingDuplicatePage = false;
            
            await getIntake();

            if (!$planGeneratorStore.plan.id) {
                wizard.goalManager.allGoals.forEach(goal => {
                    goal.revertToDefaulPlanValues();
                    if (goal.type.name === 'home_savings') {
                        if ($planGeneratorStore.plan.hog_median_home_price === null) {
                            $planGeneratorStore.plan.hog_median_home_price = goal.amount;
                        }
                        if ($planGeneratorStore.plan.hog_current_mortgage_rate === null) {
                            $planGeneratorStore.plan.hog_current_mortgage_rate = goal.interestRate;
                        }

                        if ($planGeneratorStore.plan.hog_property_tax_rate === null) {
                            $planGeneratorStore.plan.hog_property_tax_rate = goal.taxRate;
                        }
                        if ($planGeneratorStore.plan.hog_homeowners_insurance === null) {
                            $planGeneratorStore.plan.hog_homeowners_insurance =
                                goal.annualHomeownersInsurance;
                        }
                        if ($planGeneratorStore.plan.hog_other_costs === null) {
                            $planGeneratorStore.plan.hog_other_costs = accounting.unformat(
                                accounting.toFixed(goal.annualMaintenanceFee / 12, 2)
                            );
                        }
                    } else if (goal.type.name === 'income_producing_property') {
                        if ($planGeneratorStore.plan.ipp_median_home_price === null) {
                            $planGeneratorStore.plan.ipp_median_home_price = goal.amount;
                        }
                        if ($planGeneratorStore.plan.ipp_current_mortgage_rate === null) {
                            $planGeneratorStore.plan.ipp_current_mortgage_rate =
                                goal.interestRate;
                        }
                        if ($planGeneratorStore.plan.ipp_property_tax_rate === null) {
                            $planGeneratorStore.plan.ipp_property_tax_rate =
                                goal.taxRate;
                        }
                        if ($planGeneratorStore.plan.ipp_homeowners_insurance === null) {
                            $planGeneratorStore.plan.ipp_homeowners_insurance =
                                goal.annualHomeownersInsurance;
                        }
                    }
                });

                $intakeDataStore.goals = wizard.goalManager.convertToDataStore(true);
                wizard = wizard;

                if (wizard.getExpenses('all') > wizard.getIncome('net', 'monthly')) {
                    $planGeneratorStore.pages.find(page => {
                        if (page.title === "SpendingGoal") {
                            page.show_in_plan = false;
                            return page;
                        }
                    });
                }

                $planGeneratorStore.plan.current_savings_rate = wizard.getSavingsRate()
                    ? Math.round(wizard.getSavingsRate() * 100) / 100
                    : 0;

                $planGeneratorStore.plan.retirement_current_salary = wizard.getFirstAnnualGrossIncomeAmount();
                $planGeneratorStore.plan.retirement_recommended_salary = wizard.getFirstAnnualGrossIncomeAmount();
                $planGeneratorStore.plan.retirement_current_contribution_percentage = wizard.getCurrentTotalContributionPercentage();

            }

            originalPlan = JSON.stringify({
                plan: $planGeneratorStore.plan,
                pages: linkedList.getArray()
            });
            originalGoals = JSON.stringify(wizard.goalManager.convertToDataStore(true));

            if (!wizard.liabilityManager.hasLiabilities(['student_loan'])) {
                $planGeneratorStore.plan.include_PSLF = false;
            }

            if (wizard.liabilityManager.getByStudentLoanType(['federal']).length === 0) {
                $planGeneratorStore.plan.student_loan_management_federal_amount = null;
                $planGeneratorStore.plan.income_goal_student_loan_management_federal_amount = null;
            }

            if (wizard.liabilityManager.getByStudentLoanType(['private']).length === 0) {
                $planGeneratorStore.plan.student_loan_management_private_amount = null;
                $planGeneratorStore.plan.income_goal_student_loan_management_private_amount = null;
            }

            if (wizard.getLiabilities(['charge_card', 'credit_card'], 'all') === 0) {
                $planGeneratorStore.plan.debt_crushing_extra = 0;
                $planGeneratorStore.plan.income_goal_debt_crushing_extra = 0;
            }
        }

        if (planResponse.status >= 400) {
            push('/not-found');
        }
    };

    const savePlan = async (destroyed = null) => {
        let planResponse = null;
        let intakeResponse = null;
        let gotPlan = false;
        $intakeDataStore.goals = wizard.goalManager.convertToDataStore(true);
        $planGeneratorStore.pages = linkedList.getArray();
        error = false;
        saving = true;
        savingDuplicatePage = true;
        errorMessage = '';

        if (!wizard.liabilityManager.hasLiabilities(['student_loan'])) {
            $planGeneratorStore.plan.include_PSLF = false;
        }

        if (wizard.liabilityManager.getByStudentLoanType(['federal']).length === 0) {
            $planGeneratorStore.plan.student_loan_management_federal_amount = null;
            $planGeneratorStore.plan.income_goal_student_loan_management_federal_amount = null;
        }

        if (wizard.liabilityManager.getByStudentLoanType(['private']).length === 0) {
            $planGeneratorStore.plan.student_loan_management_private_amount = null;
            $planGeneratorStore.plan.income_goal_student_loan_management_private_amount = null;
        }

        if (wizard.getLiabilities(['charge_card', 'credit_card'], 'all') === 0) {
            $planGeneratorStore.plan.debt_crushing_extra = 0;
            $planGeneratorStore.plan.income_goal_debt_crushing_extra = 0;
        }

        if (!wizard.goalManager.hasGoals(['home_savings'], 'activeGoals')) {
            $planGeneratorStore.plan.hog_median_home_price = null;
            $planGeneratorStore.plan.hog_current_mortgage_rate = null;
            $planGeneratorStore.plan.hog_mortgage_term = 30;
            $planGeneratorStore.plan.hog_property_tax_rate = null;
            $planGeneratorStore.plan.hog_homeowners_insurance = null;
            $planGeneratorStore.plan.hog_other_costs = null;
        }

        if (!wizard.goalManager.hasGoals(['income_producing_property'], 'activeGoals')) {
            $planGeneratorStore.plan.ipp_median_home_price = null;
            $planGeneratorStore.plan.ipp_current_mortgage_rate = null;
            $planGeneratorStore.plan.ipp_mortgage_term = 30;
            $planGeneratorStore.plan.ipp_property_tax_rate = null;
            $planGeneratorStore.plan.ipp_homeowners_insurance = null;
        }

        if (!wizard.goalManager.hasGoals(['moving'], 'activeGoals')) {
            $planGeneratorStore.plan.current_monthly_rent = null;
        }

        $planGeneratorStore.pages.forEach(page => {
            if (page.title.includes('_')) {
                const [ pageTitle ] = page.title.split('_');
                duplicatablePages[pageTitle].duplicatedPages = [];
                
                page.data = JSON.stringify(page.data);
                page = page;
            }
        });

        if (!$planGeneratorStore.plan.id) {
            // post
            planResponse = await api.createPlan($planGeneratorStore, props.id);
        } else {
            const planPayload = new PlanDeepCompare($planGeneratorStore, JSON.parse(originalPlan));

            if (planPayload.savePayload) {
                if ($planGeneratorStore.plan.id) {
                    planPayload.payload.plan.id = $planGeneratorStore.plan.id;

                    if (noSavedPages) {
                        planPayload.payload.pages = $planGeneratorStore.pages;
                        noSavedPages = false;
                    }
                    // put
                    planResponse = await api.updatePlan(planPayload.payload);
                }
            }
        }

        const goalsPayload = new GoalsDeepCompare($intakeDataStore.goals, JSON.parse(originalGoals));

        if (goalsPayload.savePayload) {
            intakeResponse = await api.updateIntake(props.id, { goals: goalsPayload.payload });
            $intakeDataStore.goals = goalsPayload.payload;
        }

        if (!destroyed) {
            if (intakeResponse === null && planResponse === null) {
                $planGeneratorStore.pages = pages.map(page => {
                    if (page.title.includes('_')) {
                        const [ pageTitle, index ] = page.title.split('_');
                        duplicatablePages[pageTitle].duplicatedPages[index] = true;
                    }
                    return page;
                });
                
                savingDuplicatePage = false;
                return 'synced';
            }

            if (planResponse) {
                if (planResponse.status === 200) {
                    await getPlan();
                    gotPlan = true;
                    error = false;
                }

                if (planResponse.status >= 400) {
                    error = true;
                    const { data } = planResponse;
                    if (typeof data === 'object') {
                        Object.keys(data).forEach((key, index) => {
                            if (index > 0) {
                                errorMessage += `<br/> ${key} - ${data[key]}`
                            } else {
                                errorMessage += `${key} - ${data[key]}`
                            }
                        });
                    }

                    if (typeof data === 'string') {
                        if (errorMessage.length > 0) {
                            errorMessage += `<br/> ${data}`;
                        } else {
                            errorMessage += data;
                        }
                    }
                }
            }

            if (intakeResponse) {
                if (intakeResponse.status === 200) {
                    if (!gotPlan) {
                        await getIntake();
                    }
                    originalGoals = JSON.stringify(wizard.goalManager.convertToDataStore(true));
                    error = error ? error : false;
                }

                if (intakeResponse.status >= 400) {
                    error = true;
                    if (errorMessage.length > 0) {
                        errorMessage += `<br/> ${intakeResponse.data}`;
                    } else {
                        errorMessage += intakeResponse.data;
                    }
                }
            }

            if (intakeResponse === undefined || planResponse === undefined) {
                error = true;
                errorMessage = '`An unexpected error occurred.`';
            }

            $planGeneratorStore.pages = pages.map(page => {
                if (page.title.includes('_')) {
                    const [ pageTitle, index ] = page.title.split('_');
                    duplicatablePages[pageTitle].duplicatedPages[index] = true;
                }
                return page;
            });
            
            savingDuplicatePage = false;

            if (error) {
                return 'error';
            }

            return 'success';
        }

    };

    let regenerateMessage = 'Regenerate';
    let regenerating = false;

    const regeneratePlan = async () => {
        let planId;
        regenerating = true;
        openModal = false;
        regenerateMessage = 'Regenerating';

        if ($planGeneratorStore.plan.id) {
            planId = $planGeneratorStore.plan.id;
            await api.remove(planId, 'plans');

            await getIntake();
        }

        $planGeneratorStore.pages.forEach(page => {
            if (page.title.includes('_')) {
                duplicatablePages[page.title.split('_')[0]].duplicatedPages = [];
            }
        });

        $planGeneratorStore = JSON.parse(defaultPlan);
        $planGeneratorStore.pages = defaultPages;
        linkedList.initLinkedList($planGeneratorStore.pages);
        updatePages();
        

        if (wizard.getExpenses('all') > wizard.getIncome('net', 'monthly')) {
            $planGeneratorStore.pages.find(page => {
                if (page.title === "SpendingGoal") {
                    page.show_in_plan = false;
                    return page;
                }
            });
        }

        wizard.goalManager.allGoals.forEach(goal => {
            goal.revertToDefaulPlanValues();
            if (goal.type.name === 'home_savings') {
                if ($planGeneratorStore.plan.hog_median_home_price === null) {
                    $planGeneratorStore.plan.hog_median_home_price = goal.amount;
                }
                if ($planGeneratorStore.plan.hog_current_mortgage_rate === null) {
                    $planGeneratorStore.plan.hog_current_mortgage_rate = goal.interestRate;
                }
                if ($planGeneratorStore.plan.hog_property_tax_rate === null) {
                    $planGeneratorStore.plan.hog_property_tax_rate = goal.taxRate;
                }
                if ($planGeneratorStore.plan.hog_homeowners_insurance === null) {
                    $planGeneratorStore.plan.hog_homeowners_insurance =
                        goal.annualHomeownersInsurance;
                }
                if ($planGeneratorStore.plan.hog_other_costs === null) {
                    $planGeneratorStore.plan.hog_other_costs = accounting.unformat(
                        accounting.toFixed(goal.annualMaintenanceFee / 12, 2)
                    );
                }
            } else if (goal.type.name === 'income_producing_property') {
                if ($planGeneratorStore.plan.ipp_median_home_price === null) {
                    $planGeneratorStore.plan.ipp_median_home_price = goal.amount;
                }
                if ($planGeneratorStore.plan.ipp_current_mortgage_rate === null) {
                    $planGeneratorStore.plan.ipp_current_mortgage_rate =
                        goal.interestRate;
                }
                if ($planGeneratorStore.plan.ipp_property_tax_rate === null) {
                    $planGeneratorStore.plan.ipp_property_tax_rate =
                        goal.taxRate;
                }
                if ($planGeneratorStore.plan.ipp_homeowners_insurance === null) {
                    $planGeneratorStore.plan.ipp_homeowners_insurance =
                        goal.annualHomeownersInsurance;
                }
            }
        });

        $intakeDataStore.goals = wizard.goalManager.convertToDataStore(true);
        wizard = wizard;

        $planGeneratorStore.plan.current_savings_rate = wizard.getSavingsRate()
            ? Math.round(wizard.getSavingsRate() * 100) / 100
            : 0;

        $planGeneratorStore.plan.retirement_current_salary = wizard.getFirstAnnualGrossIncomeAmount();
        $planGeneratorStore.plan.retirement_recommended_salary = wizard.getFirstAnnualGrossIncomeAmount();
        $planGeneratorStore.plan.retirement_current_contribution_percentage = wizard.getCurrentTotalContributionPercentage();

        await api.updateIntake(props.id, { goals: $intakeDataStore.goals });

        originalPlan = JSON.stringify({
            plan: $planGeneratorStore.plan,
            pages: linkedList.getArray()
        });



        originalGoals = JSON.stringify(wizard.goalManager.convertToDataStore(true));

        setTimeout(() => {
            regenerateMessage = 'Regenerated Successfully!';
            window.scrollTo(0, 0);
        }, 1000)

        setTimeout(() => {
            regenerating = false;
            regenerateMessage = 'Regenerate';
        }, 3000)
    }

    $: if (props.id !== clientId) {
        (async () => {
            await getPlan();
            clientId = props.id;
        })();
    }


    afterUpdate(() => {
        if ($location.split('/')[3] && initLoad && document.getElementById($location.split('/')[3])) {
            initLoad = false;
            clickOnSpendingEdit($location.split('/')[3]);
        }
    });

    const clickOnSpendingEdit = sectionID => {
        domFunctions.scrollIntoView(sectionID);
    };

    $: if ($location) {
        domFunctions.setBrowserTitle($location, $intakeDataStore.client_info.name, $planGeneratorStore.plan.meeting_date);
    }

    const slideFn = (node, page) => {
        if (page.duplicate) {
            return slide(node, { delay: 0, duration: 400, easing: easing.cubicOut});
        }
    }
</script>

<FormButtons
    on:showModal="{ (e) => openModal = e.detail }"
    saveFn={savePlan}
    id={clientId}
    {errorMessage}
    {error}
    page="plan"
    {regenerating}
    {regenerateMessage}
    bind:saving={saving} />

<Modal
    on:showModal="{ (e) => openModal = e.detail }"
    modalTitle={"warning"}
    primaryModalButtonStyle={"danger"}
    modalBody={"Regenerating the plan will delete all the work you've done!"}
    openModal={openModal}
    cancelButtonText={"Cancel, DO NOT Regenerate Plan"}
    confirmButtonText={"Regenerate The Plan!"}
    confirmButtonFunction={regeneratePlan} />

<div id="print-plan" class="{windowsOS && (browser === 'edge') ? 'windows-print' : ''}">
    {#each $planGeneratorStore.pages as page, index}
        {#if (page.duplicate && removingDuplicatePage)}
            <div 
                class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''} {page.duplicate ? 'light-green-background' : ''}"
                transition:slideFn|local={page}
                >
                <DuplicateUXPage text={'Removing Duplicate'} title={duplicatablePages[page.title.split('_')[0]].name} />
            </div>
        {:else if (page.duplicate && savingDuplicatePage)}
            <div 
                class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''} {page.duplicate ? 'light-green-background' : ''}"
                transition:slideFn|local={page}
                >
                <DuplicateUXPage text={'Saving Duplicate'} title={duplicatablePages[page.title.split('_')[0]].name} />
            </div>
        {:else if page.title === 'FinancialFitnessPlan'}
            <!-- Page 1 -->
            <div class="page panel no-border {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <FinancialFitnessPlan />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title === 'EncouragingNote'}
            <!-- Page 2 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <EncouragingNote />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title === 'CurrentSnapshot'}
            <!-- Page 3 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <CurrentSnapshot {wizard} />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title === 'CurrentBudget'}
            <!-- Page 4 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <CurrentBudget {wizard} />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title === 'WhatYoureWorkingFor'}
            <!-- Page 5 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <WhatYoureWorkingFor {wizard} />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title === 'DigitsScorecard'}
            <!-- Page 6 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <DigitsScorecard {wizard} />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title === 'SavingsRate'}
            <!-- Page 7 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <SavingsRate {wizard} />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title === 'BreakevenGoal'}
            <!-- Page 8 -->
            {#if wizard.getExpenses('fixed', 'monthly', true) >= wizard.getIncome('net', 'monthly')}
                <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                    <BreakevenGoal {wizard} />
                    <PageButtons {linkedList} {updatePages} bind:page={page} />
                </div>
            {/if}
        {:else if page.title === 'MonthsOfExpensesSaved'}
            <!-- Page 9 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <MonthsOfExpensesSaved {wizard} />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title.includes('SpendingGoal')}
            <!-- Page 10 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''} {page.duplicate ? 'light-green-background' : ''}">
                {#if !page.duplicate || (page.duplicate && duplicatedPage)}
                    <div in:slideFn|local={page}>
                        <SpendingGoal {wizard} {clickOnSpendingEdit} {page} />
                        <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                    </div>
                {:else if (page.duplicate && !duplicatedPage && !page.data)}
                    <div in:slideFn|local={page}>
                        <DuplicateUXPage text={'Duplicating'} title={duplicatablePages[page.title.split('_')[0]].name} />
                    </div>
                {:else if (page.duplicate && !duplicatedPage && page.data)}
                    <!-- Required else if to handle the UX for not showing Duplicating loader if page is already duplicated -->
                    <!-- If not here Quill gets janky -->
                    <div in:slideFn|local={page}>
                        <SpendingGoal {wizard} {clickOnSpendingEdit} {page} />
                        <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                    </div>
                {/if}
            </div>
        {:else if page.title === 'DebtCrushingGoal'}
            <!-- Page 11 -->
            {#if totalCreditCardDebt > 0}
                <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                    <DebtCrushingGoal {wizard} />
                    <PageButtons {linkedList} {updatePages} bind:page={page} />
                </div>
            {/if}
        {:else if page.title === 'DebtManagementGoal'}
            <!-- Page 12 - 14 -->
            {#if ((creditScore >= 680 && totalCreditCardDebt > 0) || ((!creditScore || creditScore < 680) && totalLiabilities === 0) || (creditScore >= 700 && totalCreditCardDebt === 0))}
                <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                    <DebtManagementGoal {wizard} />
                    <PageButtons {linkedList} {updatePages} bind:page={page} />
                </div>
            {/if}
        {:else if page.title === 'StudentLoanManagement'}
            <!-- Page 15 -->
            {#if wizard.liabilityManager.hasLiabilities(['student_loan'])}
                <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                    <StudentLoanManagement {wizard} />
                    <PageButtons {linkedList} {updatePages} bind:page={page} />
                </div>
            {/if}
        {:else if page.title === 'LifeMoneySavingsGoal'}
            <!-- Page 16 - 18 -->
            {#if wizard.goalManager.hasGoals(['car_purchase', 'family_planning', 'moving', 'pet_care', 'travel_savings', 'wedding', 'grad_school', 'mindset'], 'activeGoals')}
                <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                    <LifeMoneySavingsGoal {wizard} />
                    <PageButtons {linkedList} {updatePages} bind:page={page} />
                </div>
            {/if}
        {:else if page.title.includes('HomeownershipGoal')}
            <!-- Page 19 -->
            {#if wizard.goalManager.hasGoals(['home_savings'], 'activeGoals')}
                <div id="home-savings" class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''} {page.duplicate ? 'light-green-background' : ''}">
                    {#if !page.duplicate || (page.duplicate && duplicatedPage)}
                        <div in:slideFn|local={page}>
                            <RealEstateGoal slide='hog' {wizard} {page} />
                            <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                        </div>
                    {:else if (page.duplicate && !duplicatedPage && !page.data)}
                        <div transition:slideFn|local={page}>
                            <DuplicateUXPage text={'Duplicating'} title={duplicatablePages[page.title.split('_')[0]].name} />
                        </div>
                    {:else if (page.duplicate && !duplicatedPage && page.data)}
                        <!-- Required else if to handle the UX for not showing Duplicating loader if page is already duplicated -->
                        <!-- If not here Quill gets janky -->
                        <div in:slideFn|local={page}>
                            <RealEstateGoal slide='hog' {wizard} {page} />
                            <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                        </div>
                    {/if}
                </div>
            {/if}
        {:else if page.title.includes('IncomeProducingProperty')}
            <!-- Page 20 -->
            {#if wizard.goalManager.hasGoals(['income_producing_property'], 'activeGoals')}
                <div id="income-producing-property" class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''} {page.duplicate ? 'light-green-background' : ''}">
                    {#if !page.duplicate || (page.duplicate && duplicatedPage)}
                        <div in:slideFn|local={page}>
                            <RealEstateGoal slide='ipp' {wizard} {page} />
                            <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                        </div>
                    {:else if (page.duplicate && !duplicatedPage && !page.data)}
                        <div transition:slideFn|local={page}>
                            <DuplicateUXPage text={'Duplicating'} title={duplicatablePages[page.title.split('_')[0]].name} />
                        </div>
                    {:else if (page.duplicate && !duplicatedPage && page.data)}
                        <!-- Required else if to handle the UX for not showing Duplicating loader if page is already duplicated -->
                        <!-- If not here Quill gets janky -->
                        <div in:slideFn|local={page}>
                            <RealEstateGoal slide='ipp' {wizard} {page} />
                            <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                        </div>
                    {/if}
                </div>
            {/if}
        {:else if page.title.includes('EducationSavingsGoal')}
            <!-- Page 21 -->
            {#if wizard.goalManager.hasGoals(['college_savings'], 'activeGoals')}
                <div id="college-savings" class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''} {page.duplicate ? 'light-green-background' : ''}">
                    {#if !page.duplicate || (page.duplicate && duplicatedPage)}
                        <div in:slideFn|local={page}>
                            <EducationSavingsGoal {wizard} {page} />
                            <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                        </div>
                    {:else if (page.duplicate && !duplicatedPage && !page.data)}
                        <div transition:slideFn|local={page}>
                            <DuplicateUXPage text={'Duplicating'} title={duplicatablePages[page.title.split('_')[0]].name} />
                        </div>
                    {:else if (page.duplicate && !duplicatedPage && page.data)}
                        <!-- Required else if to handle the UX for not showing Duplicating loader if page is already duplicated -->
                        <!-- If not here Quill gets janky -->
                        <div in:slideFn|local={page}>
                            <EducationSavingsGoal {wizard} {page} />
                            <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                        </div>
                    {/if}
                </div>
            {/if}
        {:else if page.title.includes('FireGoal')}
            <!-- Page 22 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''} {page.duplicate ? 'light-green-background' : ''}">
                {#if !page.duplicate || (page.duplicate && duplicatedPage)}
                    <div in:slideFn|local={page}>
                        <FireGoal {wizard} {page} />
                        <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                    </div>
                {:else if (page.duplicate && !duplicatedPage && !page.data)}
                    <div transition:slideFn|local={page}>
                        <DuplicateUXPage text={'Duplicating'} title={duplicatablePages[page.title.split('_')[0]].name} />
                    </div>
                {:else if (page.duplicate && !duplicatedPage && page.data)}
                    <!-- Required else if to handle the UX for not showing Duplicating loader if page is already duplicated -->
                    <!-- If not here Quill gets janky -->
                    <div in:slideFn|local={page}>
                        <FireGoal {wizard} {page} />
                        <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                    </div>
                {/if}
            </div>
        {:else if page.title.includes('RetirementPlanningCurrent')}
            <!-- Page 23 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''} {page.duplicate ? 'light-green-background' : ''}">
                {#if !page.duplicate || (page.duplicate && duplicatedPage)}
                    <div in:slideFn|local={page}>
                        <RetirementPlanning slide="current" {wizard} {page} />
                        <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                    </div>
                {:else if (page.duplicate && !duplicatedPage && !page.data)}
                    <div transition:slideFn|local={page}>
                        <DuplicateUXPage text={'Duplicating'} title={duplicatablePages[page.title.split('_')[0]].name} />
                    </div>
                {:else if (page.duplicate && !duplicatedPage && page.data)}
                    <!-- Required else if to handle the UX for not showing Duplicating loader if page is already duplicated -->
                    <!-- If not here Quill gets janky -->
                    <div in:slideFn|local={page}>
                        <RetirementPlanning slide="current" {wizard} {page} />
                        <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                    </div>
                {/if}
            </div>
        {:else if page.title.includes('RetirementPlanningRecommended')}
            <!-- Page 24 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''} {page.duplicate ? 'light-green-background' : ''}">
                {#if !page.duplicate || (page.duplicate && duplicatedPage)}
                    <div in:slideFn|local={page}>
                        <RetirementPlanning slide="recommended" {wizard} {page} />
                        <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                    </div>
                {:else if (page.duplicate && !duplicatedPage && !page.data)}
                    <div transition:slideFn|local={page}>
                        <DuplicateUXPage text={'Duplicating'} title={duplicatablePages[page.title.split('_')[0]].name} />
                    </div>
                {:else if (page.duplicate && !duplicatedPage && page.data)}
                    <!-- Required else if to handle the UX for not showing Duplicating loader if page is already duplicated -->
                    <!-- If not here Quill gets janky -->
                    <div in:slideFn|local={page}>
                        <RetirementPlanning slide="recommended" {wizard} {page} />
                        <PageButtons {linkedList} {updatePages} bind:page={page} {duplicatablePages} bind:removingDuplicatePage={removingDuplicatePage}/>
                    </div>
                {/if}
            </div>
        {:else if page.title === 'InvestingGoal'}
            <!-- Page 25 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <InvestingGoal />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title === 'IncomeGoalComponent'}
            <!-- Page 26 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <IncomeGoalComponent {wizard} />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title === 'RiskManagementChecklist'}
            <!-- Page 27 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <RiskManagementChecklist {wizard} />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title === 'NextSteps'}
            <!-- Page 28 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <NextSteps />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title === 'CommunicationTimeline'}
            <!-- Page 29 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <CommunicationTimeline />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else if page.title === 'MoneyMotivationPlaylist'}
            <!-- Page 30 -->
            <div class="page panel {!page.show_in_plan ? 'hide-print gray-background' : ''}">
                <MoneyMotivationPlaylist />
                <PageButtons {linkedList} {updatePages} bind:page={page} />
            </div>
        {:else}
            <!-- Page ? -->
            <div class="page panel {page.data.length === 0 || page.data === JSON.stringify({"ops":[{"insert":"\n"}]}) ? 'hide-print' : ''}">
                <ExtraPage {page} {index}/>
                <PageButtons {linkedList} {updatePages} {page} />
            </div>
        {/if}
    {/each}
</div>

<style lang="stylus" scoped>
    .sticky-save
        position fixed
        top 10px
        right 10px
        z-index 10

    .spin
        text-align center
        display block
</style>
