<script>
    import { intakeDataStore } from '../../../shared/store';
    import accounting from '../../../shared/accounting_config';

    import NumberInput from '../../UXElements/NumberInput.svelte';

    export let wizard;

    $: gradSchoolGoals = wizard.goalManager.getByTypes(['grad_school'], 'activeGoals');

    $: gradSchoolGoals.forEach(goal => {
        goal.planMonthAmount = getSaveForDate(goal.planTotalAmount, goal.planMonthsRemaining);
    });

     function getSaveForDate(amount, monthsRemaining) {
        if (monthsRemaining === 0) {
            // Dividing by 0 should give the answer of:
            // You need this much money in less then a month
            monthsRemaining = 1;
        }

        return accounting.unformat(accounting.toFixed(amount / monthsRemaining, 2));
    }

    // Reactivly update the store whenever a goal's new contribution is changed for the plan side bar
    // Doing this in the PlanGenerator level doesn't work...?
    $: $intakeDataStore.goals = wizard.goalManager.convertToDataStore();
</script>

<div id="grad-school" class="stop-page-break life-money">
    <h2>Grad School/Continuing Education Goal</h2>
    <ul>
        <li>
            You are interested in furthering your education in order to be able to pivot your career into a new field.
        </li>
        <li>
            Your company may offer some kind of reimbursement program instead of you having to pay for it out of pocket. I recommend you look into this benefit with your company.
        </li>
        <li>
            This quarter, I suggest you research programs and costs. We want to approach this goal by making sure you make this investment with eyes wide open. You want to know how much you need to borrow, how much your monthly payment will be when you are out of school, and ensure you will earn enough to make that payment worth it.
            <ul>
                <li>
                    Once we have more details about cost we can shift your savings plan to encompass this goal.
                </li>
                {#each gradSchoolGoals as gradSchoolGoal}
                    <li class="grid-row grid-4 hide-print">
                        <!-- [Total Cost to Client] / [number of months from goal] = [estimated monthly savings required
                        -->
                        <div class="input-group">
                            <NumberInput type="dollar" bind:value={gradSchoolGoal.planTotalAmount} />
                        </div>
                        <span>/</span>
                        <div class="input-group">
                            <NumberInput type="months" bind:value={gradSchoolGoal.planMonthsRemaining} />
                        </div>
                        <span>= {accounting.formatMoney(gradSchoolGoal.planMonthAmount)}</span>
                    </li>
                    <li class="show-print">
                        {accounting.formatMoney(gradSchoolGoal.planTotalAmount)} / {gradSchoolGoal.planMonthsRemaining}
                        months = {accounting.formatMoney(gradSchoolGoal.planMonthAmount)}
                    </li>
                {/each}
            </ul>
        </li>
    </ul>
</div>
