<script>
    import ExpenseType from './ExpensesComponents/ExpenseType.svelte';

    export let show;
</script>

<div
    class="{show ? 'collapse show' : 'collapse'}"
    aria-labelledby="Expenses"
    data-parent="#expenses">
    <div class="card-body">
        <ExpenseType type="fixed" />
        <ExpenseType type="variable" />
    </div>
</div>
