class Base {
    constructor(item = {}) {
        this.amount = 0;
        this.timesPerYear = 12;

        if (item.amount !== undefined) {
            this.amount = item.amount;
        }
        if (item.timesPerYear !== undefined) {
            this.timesPerYear = item.timesPerYear;
        }
    }

    getAnnualAmount() {
        return parseFloat(this.amount * this.timesPerYear);
    }

    getMonthAmount() {
        const monthAmount = parseFloat(this.getAnnualAmount() / 12);
        return parseFloat(monthAmount.toFixed(2));
    }
}

module.exports = Base;
