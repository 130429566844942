/* jshint esversion: 6 */
const BaseManager = require('./BaseManager');
const Liability = require('../Models/Liability');

class LiabilityManager extends BaseManager {
    constructor() {
        super();
        this.liabilities = [];
        this.paidOffDate = null;
        this.today = new Date();
        this.totalBalance = 0;
        this.totalMinimumPayment = 0;
        this.totalNewPayment = 0;
        this.totalMonth = 0;
        this.totalAnnual = 0;
        this.totalCreditLimit = 0;
        this.categories = [];
        this.minPaymentByCategory = {};
    }

    addLiability(account) {
        // Construct an income
        const liability = new Liability(account);

        this.liabilities.push(liability);
        this.totalBalance += parseFloat(liability.getBalance(), 2);
        this.totalNewPayment += parseFloat(liability.newPayment, 2);
        this.totalCreditLimit += parseFloat(liability.creditLimit, 2);
        this.totalMinimumPaymentWithDeferred += parseFloat(liability.minimumPayment, 2);

        if (!liability.deferred && !liability.paidInFull) {
            this.totalMinimumPayment += parseFloat(liability.minimumPayment, 2);
            this.totalMonth += parseFloat(liability.getMonthAmount(), 2);
            this.totalAnnual += parseFloat(liability.getAnnualAmount(), 2);

            if (this.minPaymentByCategory[liability.minPaymentCategory] === undefined) {
                this.minPaymentByCategory[liability.minPaymentCategory] = 0;
            }
            this.minPaymentByCategory[liability.minPaymentCategory] += parseFloat(liability.minimumPayment, 2);
        }

        if (this.categories[liability.category] === undefined) {
            this.categories[liability.category] = [];
        }
        this.categories[liability.category].push(liability);

        return liability;
    }

    convertToDataStore() {
        const dataStore = [];
        this.getLiabilities().forEach(liability => {
            const liab = {};
            Object.entries(liability.serverStructure).forEach(entry => {
                liab[entry[1]] = liability[entry[0]];
            });
            dataStore.push(liab);
        });
        return dataStore;
    }

    hasLiabilities(types) {
        // Check the array for a liability type
        if (types.includes('all')) {
            return this.liabilities.length > 0;
        }
        return this.liabilities.filter(liability => types.includes(liability.category)).length > 0;
    }

    getByTypes(types = []) {
        const filteredArray = [];
        types.forEach(type => {
            this.liabilities.forEach(liability => {
                if (liability.category.toLowerCase() === type) {
                    filteredArray.push(liability);
                }
            });
        });
        return filteredArray;
    }

    getByStudentLoanType(types = []) {
        const filteredArray = [];
        types.forEach(type => {
            this.liabilities.forEach(liability => {
                if (liability.type.toString().toLowerCase() === type) {
                    filteredArray.push(liability);
                }
            });
        });
        return filteredArray;
    }

    getTotal(categories = [], timeRange = 'month', creditUtilizationRatio = null) {
        if (categories.length === 0) {
            if (timeRange === 'all') {
                return this.totalBalance;
            }
            if (timeRange === 'annual') {
                return this.totalAnnual;
            }
            if (timeRange === 'month') {
                return this.totalMonth;
            }
        }

        let total = 0;
        this.liabilities.forEach(liability => {
            if (categories.includes(liability.category)) {
                if (timeRange === 'month') {
                    total += liability.getMonthAmount();
                }
                if (timeRange === 'annual') {
                    total += liability.getAnnualAmount();
                }
                if (timeRange === 'all') {
                    total += liability.getBalance(creditUtilizationRatio);
                }
            }
        });
        return total;
    }

    getTotal2(categories = [], timeRange, deferred = null) {
        const liabilities = this.liabilities
            .filter(
                liability =>
                    // if categories is null include all, else filter through the listed
                    (categories.length === 0 ? true : categories.includes(liability.category))
                    &&
                    // if deferred is null include all, else filter through true or false
                    (deferred === null ? true : liability.deferred === deferred)
            );
        if (liabilities.length === 0) {
            return 0;
        }
        return liabilities
            .map(
                liability =>
                    // return the specified timerange amount
                    // eslint-disable-next-line no-nested-ternary
                    timeRange === 'month' ? liability.getMonthAmount() :
                        // eslint-disable-next-line no-nested-ternary
                        timeRange === 'annual' ? liability.getAnnualAmount() :
                            // eslint-disable-next-line no-nested-ternary
                            timeRange === 'all' ? liability.getBalance() : null
            )
            .reduce((rollingTotal, amount) => {
                // Add up all the amounts in the mapped array
                return rollingTotal + amount;
            }, 0);
    }

    getTotalNewPayment(category = null) {
        let newTotalPayment = 0;
        this.liabilities.forEach(liability => {
            if (category) {
                if (liability.category.includes(category)) {
                    if (liability.newPayment !== undefined) {
                        newTotalPayment += parseFloat(liability.newPayment, 2);
                    }
                }
            } else if (liability.newPayment !== undefined) {
                newTotalPayment += parseFloat(liability.newPayment, 2);
            }
        });
        return newTotalPayment;
    }

    lm() {
        return this;
    }

    getLiabilities(categories = [], orderby = 'balance', order = 'asc', date = null, checkForBalance = false) {
        let liabilities = this.liabilities
            .filter(
                liability =>
                    // if categories is null include all, else filter through the listed
                    (categories.length === 0 ? true : categories.includes(liability.category)));

        liabilities.sort((a, b) => {
            let valA = a[orderby];
            let valB = b[orderby];

            if (orderby === 'apr') {
                valA = a.getInterestRate(date);
                valB = b.getInterestRate(date);
            }

            if (order === 'asc') {
                return valA - valB;
            }
            return valB - valA;
        });

        if (checkForBalance) {
            liabilities = liabilities.filter(liability => liability.balance > 0 && !liability.paidInFull);
        }
        return liabilities;
    }

    getPaidOffDate() {
        if (this.paidOffDate === null) {
            let date = new Date();
            this.liabilities.forEach(liability => {
                if (liability.paidOffDate > date) {
                    date = liability.paidOffDate;
                }
            });
            this.paidOffDate = date;
        }
        return this.paidOffDate;
    }

    getTotalBalance() {
        return this.totalBalance;
    }

    getCreditUtilizationRatio() {
        if (!isNaN(this.getTotal(['credit_card'], 'all', true) / this.totalCreditLimit) && this.totalCreditLimit !== 0) {
            return this.getTotal(['credit_card'], 'all', true) / this.totalCreditLimit * 100;
        }
        return null;
    }



}

module.exports = LiabilityManager;
