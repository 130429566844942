<script>
    import { intakeDataStore } from '../../shared/store';
    export let show;
</script>

<div class="{show ? 'collapse show' : 'collapse'}" aria-labelledby="Notes" data-parent="#notes">
    <div class="panel">
        <form>
            <div class="field">
                <textarea
                    rows="8"
                    placeholder="Notes"
                    bind:value="{$intakeDataStore.notes[0].description}"></textarea>
            </div>
        </form>
    </div>
</div>

