const timeFrequencies = [
    { value: 'daily', name: 'Daily', timesPerYear: 365 },
    { value: 'weekly', name: 'Weekly', timesPerYear: 52 },
    { value: 'biweekly', name: 'Every Two Weeks', timesPerYear: 26 },
    { value: 'twicemonthly', name: 'Twice a Month', timesPerYear: 24 },
    { value: 'monthly', name: 'Monthly', timesPerYear: 12 },
    { value: 'bimonthly', name: 'Every Two Months', timesPerYear: 6 },
    { value: 'quarterly', name: 'Quarterly', timesPerYear: 4 },
    { value: 'tertile', name: 'Every 4 Months', timesPerYear: 3 },
    { value: 'biannually', name: 'Twice a Year', timesPerYear: 2 },
    { value: 'annually', name: 'Annually', timesPerYear: 1 }
];

const frequencyChoices = [
    { value: 'hour', name: 'Hour', timesPerYear: null },
    { value: 'annual', name: 'Annual', timesPerYear: 1 },
    { value: 'month', name: 'Month', timesPerYear: 12 },
    { value: 'paycheck', name: 'Paycheck', timesPerYear: null }
];

module.exports = {
    timeFrequencies,
    frequencyChoices
};
