import axios from 'axios';

import api_config from '../../shared/api_config';

class Request {
    constructor({ 
        type = 'get', 
        url, 
        params = null, 
        apiSource = 'pg', 
        successHandler, 
        errorHandler, 
        delayMin = 500
    }) {
        this.type = type;
        this.url = url;
        this.params = params;
        this.apiSource = apiSource;

        this.successHandler = successHandler;
        this.errorHandler = errorHandler;

        this.delayMin = delayMin;
    }   

    async requestData() {
        let requestDataResponse = null;

        const timeBeforeRequest = new Date().getTime();
        
        requestDataResponse = await this.request();

        if (requestDataResponse) {
            if (requestDataResponse.status >= 400) {
                this.errorHandler(requestDataResponse);
            } else {
                const success = this.successHandler(requestDataResponse);

                // If success is true you get to use the delay otherwise nothing'll happen!
                if (success) {
                    return this.delay(timeBeforeRequest);
                }
            }
        } else {
            return this.errorHandler();
        }

        return true;
    }

    delay(timeBeforeRequest) {
        const currentTime = new Date().getTime();
        
        // Show loading icon for a default min of 500ms before showing list
        const deltaTime = timeBeforeRequest + this.delayMin;

        if (currentTime < deltaTime) {
            return new Promise(resolve => 
                setTimeout(resolve, Math.max(deltaTime - currentTime, 0))
            ).then(() => true);
        }

        return true;
    }

    async request() {
        let api = api_config;

        if (this.apiSource === 'plaid') {
            api = axios;
        }

        return api[this.type](this.url, this.params)
            .then(response => {
                return response;
            })
            .catch(error => {
                return error.response;
            });
    };
}

export default Request;
