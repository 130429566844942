<script>
    import accounting from '../../shared/accounting_config';

    import NumberInput from '../UXElements/NumberInput.svelte';

    export let wizard;
    export let minDebtPayments = 0;
    export let page = {};
    export let duplicateMounted = false

    $: minPaymentByCategory = wizard.liabilityManager.minPaymentByCategory;
    $: if (Object.keys(minPaymentByCategory).length > 0) {
        minDebtPayments = Object.values(minPaymentByCategory).reduce((total, liability) => {
            return total + parseFloat(accounting.toFixed(liability, 0));
        }, 0);
    }

    $: if (page.duplicate && !duplicateMounted) {
        if (!page.id) {
            page.data = {
                ...page.data,
                credit_card: minPaymentByCategory.credit_card,
                student_loan: minPaymentByCategory.student_loan,
                other: minPaymentByCategory.other,
                mortgage: minPaymentByCategory.mortgage
            }
        }
    }
</script>

{#if minPaymentByCategory.credit_card > 0}
    <tr>
        <th>Credit Card Minimums</th>
        <td>
            {#if !page.duplicate}
                {accounting.formatMoney(minPaymentByCategory.credit_card)}
            {:else if duplicateMounted}
                <div class="input-group hide-print">
                    <NumberInput placeholder="0" type="dollar" bind:value={page.data.credit_card} />
                </div>
                <span class="show-print">{accounting.formatMoney(page.data.credit_card)}</span>
            {/if}
        </td>
    </tr>
{/if}

{#if minPaymentByCategory.student_loan > 0}
    <tr>
        <th>Student Loan Minimums</th>
        <td>
            {#if !page.duplicate}
                {accounting.formatMoney(minPaymentByCategory.student_loan)}
            {:else if duplicateMounted}
                <div class="input-group hide-print">
                    <NumberInput placeholder="0" type="dollar" bind:value={page.data.student_loan} />
                </div>
                <span class="show-print">{accounting.formatMoney(page.data.student_loan)}</span>
            {/if}
        </td>
    </tr>
{/if}

{#if minPaymentByCategory.other > 0}
    <tr>
        <th>Other Debt Minimums</th>
        <td>
            {#if !page.duplicate}
                {accounting.formatMoney(minPaymentByCategory.other)}
            {:else if duplicateMounted}
                <div class="input-group hide-print">
                    <NumberInput placeholder="0" type="dollar" bind:value={page.data.other} />
                </div>
                <span class="show-print">{accounting.formatMoney(page.data.other)}</span>
            {/if}
        </td>
    </tr>
{/if}

{#if minPaymentByCategory.mortgage > 0}
    <tr>
        <th>Mortgage Minimum</th>
        <td>
            {#if !page.duplicate}
                {accounting.formatMoney(minPaymentByCategory.mortgage)}
            {:else if duplicateMounted}
                <div class="input-group hide-print">
                    <NumberInput placeholder="0" type="dollar" bind:value={page.data.mortgage} />
                </div>
                <span class="show-print">{accounting.formatMoney(page.data.mortgage)}</span>
            {/if}
        </td>
    </tr>
{/if}
