const dayjs = require('dayjs');
const Base = require('./Base');
const { goalTypes } = require('../../../helpers/account_types');
const calculations = require('../../../helpers/calculations');


class Goal extends Base {
    constructor(name) {
        super(name);

        this.serverStructure = {
            allowedDeductions: 'allowed_deductions',
            amount: 'total_amount',
            monthAmount: 'month_amount',
            annualHomeownersInsurance: 'annual_homeowners_insurance',
            annualMaintenanceFee: 'annual_maintenance_fee',
            balance: 'current_amount',
            brokersFee: 'brokers_fee',
            clientId: 'client',
            customEndDate: 'custom_date',
            description: 'description',
            generalTravel: 'general_travel',
            id: 'id',
            include: 'include',
            interestRate: 'interest_rate',
            goalLength: 'goal_length',
            location: 'location',
            monthlyProjectedRent: 'monthly_projected_rent',
            moversCost: 'movers_cost',
            name: 'name',
            newContribution: 'new_contribution',
            taxRate: 'tax_rate',
            type: 'goal_type',
            startDate: 'start_date',
            endDate: 'end_date',

            // Plan Fields
            planMonthAmount: 'plan_month_amount',
            planTotalAmount: 'plan_total_amount',
            planMonthsRemaining: 'plan_months_remaining',
            planMonthlyProjectedRent: 'plan_monthly_projected_rent',
            planSecurityDeposit: 'plan_security_deposit',
            planBrokersFee: 'plan_brokers_fee',
            planMoversCost: 'plan_movers_cost',
            showInPlanIncomeGoals: 'show_in_plan_income_goals',
            showInPlanSpendingGoals: 'show_in_plan_spending_goals'
        };

        if (typeof name === 'object') {
            this.allowedDeductions = parseFloat(
                name.allowed_deductions ? name.allowed_deductions : 0
            );
            this.amount = parseFloat(name.total_amount ? name.total_amount : 0);
            this.annualHomeownersInsurance = parseFloat(
                name.annual_homeowners_insurance ? name.annual_homeowners_insurance : 0
            );
            this.annualMaintenanceFee = parseFloat(
                name.annual_maintenance_fee ? name.annual_maintenance_fee : 0
            );
            this.balance = parseFloat(name.current_amount ? name.current_amount : 0);
            this.brokersFee = parseFloat(name.brokers_fee ? name.brokers_fee : 0);
            this.clientId = name.client;
            this.customEndDate = name.custom_date ? name.custom_date : null;
            this.dateCreated = name.date_created ? name.date_created : null;
            this.description = name.description;
            this.generalTravel = name.general_travel;
            this.id = name.id;
            this.include = name.include;
            this.interestRate = parseFloat(name.interest_rate ? name.interest_rate : 0);
            this.location = name.location;
            this.monthlyProjectedRent = parseFloat(
                name.monthly_projected_rent ? name.monthly_projected_rent : 0
            );
            this.monthsRemaining = 0;
            this.moversCost = parseFloat(name.movers_cost ? name.movers_cost : 0);
            this.name = name.name;
            this.taxRate = parseFloat(name.tax_rate ? name.tax_rate : 0);
            this.type = name.goal_type;
            this.startDate = name.start_date ? name.start_date : null;
            this.endDate = name.end_date ? name.end_date : null;
            
            // Plan Fields
            this.planMonthlyProjectedRent = name.plan_monthly_projected_rent ? name.plan_monthly_projected_rent : parseFloat(name.monthly_projected_rent ? name.monthly_projected_rent : 0);
            this.planSecurityDeposit = name.plan_security_deposit ? name.plan_security_deposit : parseFloat(name.monthly_projected_rent ? name.monthly_projected_rent : 0);
            this.planBrokersFee = name.plan_brokers_fee ? name.plan_brokers_fee : parseFloat(name.brokers_fee ? name.brokers_fee : 0);
            this.planMoversCost = name.plan_movers_cost ? name.plan_movers_cost : parseFloat(name.movers_cost ? name.movers_cost : 0);
            this.showInPlanIncomeGoals = name.show_in_plan_income_goals;
            this.showInPlanSpendingGoals = name.show_in_plan_spending_goals;

            goalTypes.forEach(type => {
                if (type.value === name.goal_type) {
                    this.type = type.value;
                }
            });
            
            this.goalLength = name.goal_length;
            if (this.goalLength){
                switch (this.goalLength) {
                case '3_years':
                    this.monthsRemaining = 36;
                    break;
                case '5_years':
                    this.monthsRemaining = 60;
                    break;
                case '10_years':
                    this.monthsRemaining = 120;
                    break;
                case 'custom':
                    this.monthsRemaining = dayjs(this.customEndDate).diff(dayjs(this.dateCreated), 'months');
                    break;
                default:
                    throw new Error('Unknown goal_length');
                }
            } else {
                this.monthsRemaining = 1;
            }

            if (this.generalTravel && this.type.name === 'travel_savings'){
                this.monthsRemaining = 12;
            }
            
            // Added this back to stay in scope
            // endDate, startDate, and targetDate are all currently not being referenced in the project
            // A larger ticket could remove goalLength and customEndDate and use these or remove endDate, startDate, and targetDate
            if (this.endDate) {	
                const endDate = dayjs(this.endDate);	
                let startDate;	
                if (this.startDate){	
                    startDate = dayjs(this.startDate);	
                } else {	
                    startDate = dayjs(this.dateCreated);   	
                }	
                this.monthsRemaining = endDate.diff(startDate, 'months');	
            }	

            this.targetDate = new Date(	
                new Date(this.dateCreated).setMonth(	
                    new Date(this.dateCreated).getMonth() + this.monthsRemaining	
                )	
            );

            this.planMonthsRemaining = name.plan_months_remaining === null ? this.monthsRemaining : name.plan_months_remaining;

            let amountDiff = this.amount - this.balance;
            if (amountDiff < 0){
                amountDiff = 0;
            }


            if (this.type.name === 'pet_care' || this.type.name === 'car_purchase' || this.type.name === 'family_planning'){  // make backwards compatible because these type of old goals will have a "total_amount" value instead of "month_amount" value
                this.monthAmount = parseFloat(name.month_amount ? name.month_amount : 0).toFixed(2);
            } else if (name.month_amount !== null && name.month_amount > 0){
                this.monthAmount = parseFloat(name.month_amount ? name.month_amount : 0).toFixed(2);
            } else {
                const amt = amountDiff / this.monthsRemaining;
                this.monthAmount = isFinite(amt) ? parseFloat(amt).toFixed(2) : 0;
            }

            if (this.type.name === 'home_savings' || this.type.name === 'income_producing_property') {
                this.monthAmount = Math.round(calculations.getMonthlyAmount(this.amount, this.interestRate, this.taxRate, this.annualHomeownersInsurance, this.annualMaintenanceFee), 2)
            }
            
            if (this.type.name === 'college_savings') {
                this.monthAmount = Math.round(calculations.getMonthlyEducationsSavingsAmount(this.monthsRemaining, this.balance, this.amount))
            }

            this.planMonthAmount = name.plan_month_amount === null ? parseFloat(this.monthAmount) : parseFloat(name.plan_month_amount).toFixed(2);
            this.planTotalAmount = name.plan_total_amount === null ? parseFloat(amountDiff) : parseFloat(name.plan_total_amount).toFixed(2);

            this.newContribution = parseFloat(name.new_contribution ? name.new_contribution : this.getMonthAmount());
            
            // default value not needed to be saved.
            this.originalPlanAmount = this.planMonthAmount;
            this.originalPlanMonthsRemaining = this.planMonthsRemaining;
        }
    }

    getAnnualAmount() {
        return parseFloat(this.getMonthAmount() * 12);
    }

    // Maybe we should make these more descriptive, change to getSavePerMonthForDate()?
    getMonthAmount() {
        // Will return NaN if this.monthsRemaining is less than 1
        return this.monthAmount;
    }

    getGoalLength() {
        if (this.monthsRemaining <= 36) {
            return 'short';
        } 
        if (this.monthsRemaining <= 60) {
            return 'mid';
        } 
        return 'long';
    }

    getCompletionDateByPayment(paymentAmount = 0) {
        try {
            if (paymentAmount === 0 || isNaN(paymentAmount)) {
                throw `Can not be calculated`;
            }
            let balance = Math.abs(this.balance);
            const hundredYears = new Date();
            hundredYears.setFullYear(hundredYears.getFullYear() + 100);
            const date = new Date();
            let completionDate;

            while (balance < this.amount) {
                if (date > hundredYears) {
                    throw 'It will take more than 100 years to reach this goal.';
                }
                // Set this month
                date.setMonth(date.getMonth() + 1);

                // Reset the balance to 0 before running through the loans
                const interestPayment = balance * (this.interestRate/100);
                balance += paymentAmount + interestPayment; // goal's interest may not be compounded monthly?

                completionDate = date;
            }
            return completionDate;
        } catch (e) {
            return e;
        }
    }


    // Sunset function
    getMonthsToCompletion() {
        return this.monthsRemaining;
    }

    revertToDefaulPlanValues() {
        // Plan Fields
        this.planMonthlyProjectedRent = this.monthlyProjectedRent;
        this.planSecurityDeposit = this.monthlyProjectedRent;
        this.planBrokersFee = this.brokersFee;
        this.planMoversCost = this.moversCost;
        this.showInPlanIncomeGoals = true;
        this.showInPlanSpendingGoals = true;
        this.planMonthsRemaining = this.monthsRemaining;

        let amountDiff = this.amount - this.balance;

        this.planTotalAmount = parseFloat(amountDiff);
        
        if (amountDiff < 0) {
            amountDiff = 0;
        }

        if (this.type.name === 'pet_care' || this.type.name === 'car_purchase' || this.type.name === 'family_planning') {  // make backwards compatible because these type of old goals will have a "total_amount" value instead of "month_amount" value
            this.monthAmount = parseFloat(this.monthAmount ? this.monthAmount : 0).toFixed(2);
        } else if (this.monthAmount !== null && this.monthAmount > 0) {
            this.monthAmount = parseFloat(this.monthAmount ? this.monthAmount : 0).toFixed(2);
        }
        
        if (this.type.name === 'wedding' || this.type.name === 'travel_savings' || this.type.name === 'general_savings' || this.type.name === 'grad_school' || this.type.name === 'investing' || this.type.name === 'other') {
            const amt = amountDiff / this.monthsRemaining;
            this.monthAmount = isFinite(amt) ? parseFloat(amt).toFixed(2) : 0;
        }

        if (this.type.name === 'home_savings' || this.type.name === 'income_producing_property') {
            this.monthAmount = Math.round(calculations.getMonthlyAmount(this.amount, this.interestRate, this.taxRate, this.annualHomeownersInsurance, this.annualMaintenanceFee), 2)
        }

        if (this.type.name === 'college_savings') {
            this.monthAmount = Math.round(calculations.getMonthlyEducationsSavingsAmount(this.monthsRemaining, this.balance, this.amount))
        }

        this.planMonthAmount = this.monthAmount;
        
        this.newContribution = this.planMonthAmount;

        // default value not needed to be saved.
        this.originalPlanAmount = this.planMonthAmount;
        this.originalPlanMonthsRemaining = this.planMonthsRemaining;
    }
}

module.exports = Goal;
