<script>
    import Navigation from './Navigation.svelte';
    import ProfileActions from './ProfileActions.svelte';

    export let logOut;
</script>

<header class="desktop main-header">
    <div class="flex-wrapper container w90">
        <Navigation />
        <ProfileActions {logOut} />
    </div>
</header>
<!--
<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
  <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="javascript:void(0);" on:click|preventDefault={() => push('/')}>
  Financial Gym
</a>
  <ul class="navbar-nav px-3">
  {#if $loggedIn}
    <li class="nav-item text-nowrap">
        <a class="nav-link" href="javascript:void(0);" on:click={logOut}>log out</a>
    </li>
    {/if}
  </ul>
</nav>
-->

