<script>
    import { onMount, afterUpdate } from 'svelte';

    import domFunctions from '../../helpers/dom_functions';

    import Income from '../../components/Intake/Income.svelte';
    import BaseALComponent from '../../components/Intake/BaseALComponent.svelte';
    import Risks from '../../components/Intake/Risks.svelte';
    import Expenses from '../../components/Intake/Expenses.svelte';
    import Goals from '../../components/Intake/Goals.svelte';
    import NonNegotiables from '../../components/Intake/NonNegotiables.svelte';
    import CreditScores from '../../components/Intake/CreditScores.svelte';
    import Notes from '../../components/Intake/Notes.svelte';

    export let props;

    let { section } = props;
    const show = {
        assets: false,
        'credit-scores': false,
        expenses: false,
        goals: false,
        income: false,
        liabilities: false,
        'non-negotiables': false,
        notes: false,
        risks: false,
    };
    let showAll = false;
    let { isLaptop } = window.navigator;

    onMount(() => {
        expandElement();
    });

    afterUpdate(() => {
        if (props.section !== section) {
            expandElement();
            section = props.section;
        }
    });

    function expandElement(value) {
        if (!value) {
            if (props.section) {
                domFunctions.scrollIntoView(props.section);
                show[props.section] = true;
            }
        } else if (value === 'all') {
            Object.keys(show).forEach(elem => {
                show[elem] = !showAll;
            });
            showAll = !showAll;
        } else {
            show[value] = !show[value];
        }
    }

    function isMobileTablet(){
        
    }
</script>

<div class="row expand">
    <div class="col">
        <button
            class="button small primary"
            type="button"
            on:click="{e => expandElement('all')}">
            <span class:close="{ showAll }">&laquo;</span> {showAll ? 'Collapse' : 'Expand'} Sections
        </button>
    </div>
</div>

<div class="card" id="income">
    <div class="card-text-holder {!isLaptop ? 'is-not-laptop' : ''}">
        <h2 class="card-text" title="Expand section" on:click="{ e => expandElement('income') }">Income</h2>
        <span class="card-text-span"></span>
    </div>
    
    <Income bind:show="{show.income}" />
</div>

<div class="card" id="credit-scores">
    <div class="card-text-holder {!isLaptop ? 'is-not-laptop' : ''}">
        <h2 class="card-text" title="Expand section" on:click="{ e => expandElement('credit-scores') }">Credit Scores</h2>
        <span class="card-text-span"></span>
    </div>
    <CreditScores bind:show="{show['credit-scores']}" />
</div>

<div class="card" id="assets">
    <div class="card-text-holder {!isLaptop ? 'is-not-laptop' : ''}">
        <h2 class="card-text" title="Expand section" on:click="{ e => expandElement('assets') }">Assets</h2>
        <span class="card-text-span"></span>
    </div>
    <BaseALComponent bind:show="{show.assets}" type="assets" />
</div>

<div class="card" id="liabilities">
    <div class="card-text-holder {!isLaptop ? 'is-not-laptop' : ''}">
        <h2 class="card-text" title="Expand section" on:click="{ e => expandElement('liabilities') }">Liabilities</h2>
        <span class="card-text-span"></span>
    </div>
    <BaseALComponent bind:show="{show.liabilities}" type="liabilities" />
</div>

<div class="card" id="risks">
    <div class="card-text-holder {!isLaptop ? 'is-not-laptop' : ''}">
        <h2 class="card-text" title="Expand section" on:click="{ e => expandElement('risks') }">Risks</h2>
        <span class="card-text-span"></span>
    </div>
    <Risks bind:show="{show.risks}" />
</div>

<div class="card" id="expenses">
    <div class="card-text-holder {!isLaptop ? 'is-not-laptop' : ''}">
        <h2 class="card-text" title="Expand section" on:click="{ e => expandElement('expenses') }">Expenses</h2>
        <span class="card-text-span"></span>
    </div>
    <Expenses bind:show="{show.expenses}" />
</div>

<div class="card" id="goals">
    <div class="card-text-holder {!isLaptop ? 'is-not-laptop' : ''}">
        <h2 class="card-text" title="Expand section" on:click="{ e => expandElement('goals') }">Goals</h2>
        <span class="card-text-span"></span>
    </div>
    <Goals bind:show="{show.goals}" />
</div>

<div class="card" id="non-negotiables">
    <div class="card-text-holder {!isLaptop ? 'is-not-laptop' : ''}">
        <h2 class="card-text" title="Expand section" on:click="{ e => expandElement('non-negotiables') }">Non-Negotiables</h2>
        <span class="card-text-span"></span>
    </div>
    <NonNegotiables bind:show="{show['non-negotiables']}" />
</div>

<div class="card" id="notes">
    <div class="card-text-holder {!isLaptop ? 'is-not-laptop' : ''}">
        <h2 class="card-text" title="Expand section" on:click="{ e => expandElement('notes') }">Notes</h2>
        <span class="card-text-span"></span>
    </div>
    <Notes bind:show="{show.notes}" />
</div>

<style lang="stylus" scoped>
    h2
        border-left 5px solid var(--color-green-light)
        padding-left 1em
        color var(--color-green-light)

    .card
        border-bottom 1px solid var(--color-gray-medium)
        padding-bottom 1em

    .card-text
        display inline-block
        position relative
        z-index 2
    
    .card-text-holder
        display inline-block
        position relative
        width 100%
        cursor pointer

        &:hover
            .card-text-span
                background-color var(--color-green-light)
                width 100%

            .card-text
                color var(--color-white)
                display block

    
    .card-text-span
        width 0
        position absolute
        top 40px
        left 0
        display inline-block
        transition all .2s linear
        height 27px
        z-index 1

    button span
        display inline-block
        font-size 1.5em
        transform rotate(-90deg)

        &.close
            transform rotate(90deg)

    .is-not-laptop
        &:hover
            .card-text-span
                width unset
            
            .card-text
                color var(--color-green-light)
    
    @media screen and (max-width 1024px)
        .card-text-span
            top 36px
    
    @media screen and (max-width 768px)
        .card-text-span
            top 35px
</style>
