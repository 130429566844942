<script>
    import { location } from 'svelte-spa-router'

    import LogIn from '../components/Auth/LogIn.svelte';
    import ForgotPassword from '../components/Auth/ForgotPassword.svelte';
    import ResetPassword from '../components/Auth/ResetPassword.svelte'
    import SSOAuth from '../components/Auth/SSOAuth.svelte'

    export let params = null;
</script>


{#if $location.includes('forgot')}
    <ForgotPassword />
{:else if $location.includes('reset')}
    <ResetPassword token={params.key} />
{:else if $location.includes('sso')}
    <SSOAuth/>
{:else}
    <LogIn/>
{/if}

