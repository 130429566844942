<script>
    import dayjs from "dayjs"

    import { intakeDataStore } from '../../shared/store';
    import * as api from '../../shared/api_access';
    import { goalTypes, goalLengths } from '../../helpers/account_types';
    import goalSupplementaryInfo from '../../helpers/goal_supplementary_information';

    import FeatherIcon from '../UXElements/FeatherIcon.svelte';
    import NumberInput from '../UXElements/NumberInput.svelte';
    import LimitedCharInput from '../UXElements/LimitedCharInput.svelte';
    import SelectComponent from './GoalsComponents/SelectComponent.svelte';
    import SupplementaryInformationComponent from './GoalsComponents/SupplementaryInformationComponent.svelte';

    export let show;
    
    let goals = [];
    let shortGoals;
    let midGoals;
    let longGoals;
    let activeGoal = 0;

    const addGoal = () => {
        goals.push({
            goal_type: {
                name: '',
            },
            name: '',
            total_amount: null,
            current_amount: null,
            goal_length: '',
            custom_date: '',
            description: '',
            new_contribution: null,
            include: true,
            show_in_plan_income_goals: true,
            show_in_plan_spending_goals: true
        });
        $intakeDataStore.goals = goals;
        activeGoal = goals.length-1;
    };

    const removeGoal = async (goal_id, index) => {
        goals.splice(index, 1);

        $intakeDataStore.goals = goals;

        if (goal_id) {
            await api.remove(goal_id, 'goals');
        }
    };

    const onCalculationTypeChange = (index) => {
        if (goals[index].goal_type.name === 'mindset') {
            goals[index].total_amount = null;
            goals[index].current_amount = null;
            goals[index].new_contribution = null;

            if (goals[index].month_amount) {
                goals[index].month_amount = null;
            }

            Object.values(goalSupplementaryInfo).forEach(value => {
                value.forEach(element => {
                    if (goals[index][element.name]) {
                        delete goals[index][element.name];
                    }
                });
            });
        }
    }

    $: goals = $intakeDataStore.goals;

    $: if (goals) {
        shortGoals = [];
        midGoals = [];
        longGoals = [];
        goals.forEach((goal, index) => {
            let monthsRemaining = 1;
            if (goal.goal_length) {
                switch (goal.goal_length) {
                    case '3_years':
                        monthsRemaining = 36;
                        break;
                    case '5_years':
                        monthsRemaining = 60;
                        break;
                    case '10_years':
                        monthsRemaining = 120;
                        break;
                    case 'custom':
                        monthsRemaining = dayjs(goal.custom_date).diff(dayjs(new Date()), 'months');
                        break;
                }
            }

            if (monthsRemaining <= 36) {
                shortGoals[index] = goal;
            } else if (monthsRemaining <= 60) {
                midGoals[index] = goal;
            } else {
                longGoals[index] = goal;
            }
        })
    }
</script>

<div class="{show ? 'collapse show' : 'collapse'}" aria-labelledby="Goals" data-parent="#goals">
    <div class="flex-wrapper main-wrapper container">
        <div class="column column-single">
            <div class="plaid-status container">
                <div class="panel">
                    <button id="add-button" class="button small primary" on:click|preventDefault="{() => addGoal()}">
                        Add Goal
                    </button>
                    <table class="plaid-status-table">
                        <tr class="goal-header">
                            <td colspan="2">
                                <h4>
                                    <strong>SHORT-TERM (1-3 YEARS)</strong>
                                </h4>
                            </td>
                        </tr>
                        {#each shortGoals as goal, index}
                            {#if goal}
                                <tr on:click="{ () => { activeGoal = index; } }">
                                    <td class="goal-name">
                                        {goal.name}
                                    </td>
                                    <td class="indicator">
                                        {#if activeGoal === index}
                                            <span class="green"></span>
                                        {:else}
                                            <span class="outline"></span>
                                        {/if}
                                    </td>
                                </tr>
                            {/if}
                        {/each}
                        <tr class="goal-header">
                            <td colspan="2">
                                <h4>
                                    <strong>MID-TERM (3-5 YEARS)</strong>
                                </h4>
                            </td>
                        </tr>
                        {#each midGoals as goal, index}
                            {#if goal}
                                <tr on:click="{ () => { activeGoal = index; } }">
                                    <td class="goal-name">
                                        {goal.name}
                                    </td>
                                    <td class="indicator">
                                        {#if activeGoal === index}
                                            <span class="green"></span>
                                        {:else}
                                            <span class="outline"></span>
                                        {/if}
                                    </td>
                                </tr>
                            {/if}
                        {/each}
                        <tr class="goal-header">
                            <td colspan="2">
                                <h4>
                                    <strong>LONG-TERM (5+ YEARS)</strong>
                                </h4>
                            </td>
                        </tr>
                        {#each longGoals as goal, index}
                            {#if goal}
                                <tr on:click="{ () => { activeGoal = index; } }">
                                    <td class="goal-name">
                                        {goal.name}
                                    </td>
                                    <td class="indicator">
                                        {#if activeGoal === index}
                                            <span class="green"></span>
                                        {:else}
                                            <span class="outline"></span>
                                        {/if}
                                    </td>
                                </tr>
                            {/if}
                        {/each}
                    </table>
                </div>
            </div>
        </div>

        <div class="column column-double">
            <div class="panel">
            {#each goals as goal, index}
                {#if activeGoal === index}
                    <form>
                        <div class="field">
                            <LimitedCharInput
                                label="goal-name-{index}"
                                maxLength=100
                                placeholder="Name"
                                bind:value={goal.name} />
                        </div>

                        <div class="field">
                            <label for="goal-calculation-type-{index}">Calculation Type *</label>
                            <SelectComponent
                                id="goal-calculation-type-{index}"
                                type='Calculation Type'
                                bind:value="{goal.goal_type.name}"
                                selectOptions="{goalTypes}"
                                onChangeFn="{() => onCalculationTypeChange(index)}" />
                        </div>
                        {#if goal.goal_type.name !== 'mindset'}
                            <div class="field">
                                {#if goal.goal_type.name === 'pet_care' || goal.goal_type.name === 'car_purchase' || goal.goal_type.name === 'family_planning'}
                                    <label for="goal-projected-monthly-budget-{index}">Projected Monthly Budget</label>
                                    <NumberInput id="goal-projected-monthly-budget-{index}" bind:value={goal.month_amount} />
                                {:else}
                                    <label for="goal-total-amount-needed-{index}">Total Amount Needed</label>
                                    <NumberInput id="goal-total-amount-needed-{index}" bind:value={goal.total_amount} />
                                {/if}
                            </div>
                        {/if}

                        {#if !(goal.goal_type.name === 'travel_savings' && goal.general_travel)}
                            {#if goal.goal_type.name !== 'mindset'}
                                <div class="field">
                                    <label for="goal-current-amount-saved-{index}">Current Amount Saved</label>
                                    <NumberInput id="goal-current-amount-saved-{index}" bind:value={goal.current_amount} />
                                </div>
                            {/if}

                            <div class="field">
                                <label for="goal-length-{index}">Length</label>
                                <SelectComponent
                                    id="goal-length-{index}"
                                    type='Goal Length'
                                    bind:value="{goal.goal_length}"
                                    selectOptions="{goalLengths}" />
                            </div>
                        {/if}

                        <div class="field {goal.goal_length !== 'custom' ? 'display-none' : ''}">
                            <label for="goal-custom-date-{index}">Custom Date</label>
                            <input
                                id="goal-custom-date-{index}"
                                class="form-control"
                                type="date"
                                bind:value="{goal.custom_date}" />
                        </div>

                        <SupplementaryInformationComponent
                            {index}
                            type="{goal.goal_type.name}" 
                            bind:goal />

                        <div class="field">
                            <label for="goal-description-{index}">Description</label>
                            <textarea
                                id="goal-description-{index}"
                                class="form-control"
                                rows="4"
                                placeholder="Description"
                                bind:value="{goal.description}"></textarea>
                        </div>

                        <div class="field checkboxes">
                            <label class="check-label">
                                <input
                                    type="checkbox"
                                    bind:checked="{goal.include}"
                                    data-cy="goal-include-{goal.id}" />
                                <span>Include In Plan</span>
                            </label>
                        </div>

                        <button class="button small secondary" on:click|preventDefault="{async () => removeGoal(goal.id, index)}">
                            Delete
                        </button>
                    </form>
                {/if}
            {/each}
            </div>
        </div>
    </div>
</div>

<style lang="stylus" scoped>
    form {
        padding-bottom: 1rem;
    }
    #add-button
        margin 1em

    h4
        margin 0.2em
        text-transform capitalize

        input
            visibility hidden

    tr
        cursor pointer

    .goal-header
        cursor default
    
    .goal-name
        word-break break-word

    .secondary
        border-color var(--color-orange)
        color var(--color-orange)

        &:hover
            background-color var(--color-orange)
            border-color var(--color-orange)

    .plaid-status .panel
        padding 0

    .display-none
        display: none

    @media screen and (max-width: 1024px)
        .flex-wrapper
            display block

            .column-single
                margin-right 0

            .panel
                height auto
</style>
