<script>
    import accounting from 'accounting';
    import { onMount } from 'svelte';
    import { planGeneratorStore } from '../../shared/store';

    import NumberInput from '../UXElements/NumberInput.svelte';
    import TrainerCommentComponent from './TrainerCommentComponent.svelte';

    export let wizard;

    let hasFederalLoans = false;
    let hasPrivateLoans = false;

    $: studentLoans = wizard.liabilityManager.getByTypes(['student_loan']).forEach(loan => {
        if (loan.type === 'federal') {
            hasFederalLoans = true;
        }
        if (loan.type === 'private') {
            hasPrivateLoans = true;
        }
    });
</script>

<h1 id="student-loan-management" class="page-title">Student Loan Management</h1>

<p>
    You have student loans that need to be repaid. Depending on the loan provider, your credit
    score, and budget flexibility, there are different options to consider when it comes to managing
    your loans.
</p>

<div class="form-group form-check hide-print">
    <input
        id="include-pslf"
        class="form-check-input"
        type="checkbox"
        bind:checked="{$planGeneratorStore.plan.include_PSLF}" />
    <label class="form-check-label" for="include-pslf">Include PSLF Section</label>
</div>

<table class="table table-bordered">
    <tbody>
        {#if hasFederalLoans}
            <tr>
                <th>FEDERAL LOAN CONSOLIDATION</th>
                <td>
                    <div class="student-loan-management-td">
                        Consolidating your federal loans can lower your monthly payments as it gives
                        you up to 30 years to repay your loans. There are four income-driven
                        repayment plans:
                        <ul>
                            <li>
                                Income Based Repayment (IBR), Income-Contingent Repayment (ICR), Pay
                                As You Earn (PAYE), and Revised Pay As You Earn (REPAYE)
                            </li>
                        </ul>
                        The pro is that the monthly payment are set between 10% and 20% of your
                        income; the con is that you pay more in interest if you do not make
                        additional payments.

                        <p>Check out
                        <a
                        href="https://financialgym.bysavi.com/"
                        target="_blank">
                        Savi
                    </a>, a free tool for managing student loans that can help you save money on your monthly payments, determine your eligibility for loan forgiveness, and more!</p>
                    </div>
                </td>
            </tr>
        {/if}
        {#if hasPrivateLoans}
            <tr>
                <th>PRIVATE LOAN REFINANCE</th>
                <td>
                    <div class="student-loan-management-td">
                        Refinancing your private loans can be a great option to lower your interest
                        rate and monthly payment. We only recommend refinancing your private loans
                        if they are at a high interest rate and monthly payment. You can use
                        <a
                            href="https://www.credible.com/refi/prequal/?utm_source=financialgym&utm_medium=referral&utm_campaign=student_loan_refi"
                            target="_blank">
                            Credible
                        </a>
                        or
                        <a
                            href="https://financialgym.com/bff-approved-personal-loans"
                            target="_blank">
                             this site
                        </a>
                        to see if there are any opportunities to secure a lower monthly payment and
                        interest rate.

                        <p>Check out
                            <a
                            href="https://financialgym.bysavi.com/"
                            target="_blank">
                            Savi
                        </a>, a free tool for managing student loans that can help you save money on your monthly payments, determine your eligibility for loan forgiveness, and more!</p>
                    </div>
                </td>
            </tr>
        {/if}
        {#if $planGeneratorStore.plan.include_PSLF}
            <tr>
                <th>PUBLIC SERVICE LOAN FORGIVENESS (PSLF)</th>
                <td>
                    <div class="student-loan-management-td">
                        You are a full time employee at a 501(c)(3) tax exempt/public institution.
                        This means you can qualify for PSLF. Here is a checklist to complete to
                        qualify:
                        <ul>
                            <li>Loans must be direct federal loans:</li>
                            <li>Federal Direct subsidized Stafford/direct loans</li>
                            <li>Federal Direct unsubsidized Stafford/direct loans</li>
                            <li>Federal Direct PLUS loans</li>
                            <li>Federal Direct consolidation loans</li>
                        </ul>
                        You must have made 120 qualifying payments (on time and in full, but not
                        required to be consecutive.) You can check your progress at
                        <a href="https://studentaid.gov/" target="_blank">studentaid.gov</a>. If consolidated, must be done through income driven qualifying plan.
                        Complete the “Employment Certification for PSLF Form” each year.
                        <a
                            href="https://studentloans.gov/myDirectLoan/pslfFlow.action?&_ga=2.235162369.1822813002.1569513992-1925119205.1567124200#!/pslf/launch"
                            target="_blank">
                            This page
                        </a>
                        will help you with the forms.
                    </div>
                </td>
            </tr>
        {/if}
    </tbody>
</table>

<p>
    {#if hasFederalLoans}
        For your federal loans I recommend you consolidate and increase your monthly payment by
        <div class="input-group hide-print">
            <NumberInput type="dollar" bind:value="{$planGeneratorStore.plan.student_loan_management_federal_amount}" />
        </div>
        <span class="show-print">
            {accounting.formatMoney($planGeneratorStore.plan.student_loan_management_federal_amount)}.
        </span>
    {/if}
    <br />
    {#if hasPrivateLoans}
        For your private loans, I recommend you refinance and increase your monthly payment by
        <div class="input-group hide-print">
            <NumberInput type="dollar" bind:value="{$planGeneratorStore.plan.student_loan_management_private_amount}" />
        </div>
        <span class="show-print">
            {accounting.formatMoney($planGeneratorStore.plan.student_loan_management_private_amount)}.
        </span>
    {/if}
</p>

<TrainerCommentComponent type="{'student_loan_management_trainer_comments'}" />
