<script>
    import { planGeneratorStore } from '../../shared/store';

    import accounting from '../../shared/accounting_config';

    import TrainerCommentComponent from './TrainerCommentComponent.svelte';
    import NumberInput from '../UXElements/NumberInput.svelte';

    export let wizard;
    export let page;

    $: totalCurrentExpenses = wizard.getExpenses('all');

    $: requiredFireIncome = totalCurrentExpenses + (isNaN(parseFloat($planGeneratorStore.plan.expected_expense_change)) ? 0 : parseFloat($planGeneratorStore.plan.expected_expense_change)) - (isNaN(parseFloat($planGeneratorStore.plan.expected_income)) ? 0 : parseFloat($planGeneratorStore.plan.expected_income));

    $: if (page.duplicate) {
        if (!page.id) {
            if (page.data.length === 0) {
                page.data = {
                    name: `F.I.R.E. Goal - ${parseInt(page.title.split('_')[1]) + 1}`,
                    fire_multiplier: 300,
                    expected_expense_change: parseFloat($planGeneratorStore.plan.expected_expense_change).toFixed(0),
                    expected_income: parseFloat($planGeneratorStore.plan.expected_income).toFixed(0),
                    total_current_expenses: parseFloat(totalCurrentExpenses).toFixed(0),
                    required_fire_income: parseFloat(requiredFireIncome).toFixed(0),
                    assets_required_for_fire: parseFloat((requiredFireIncome * 300)).toFixed(0)
                };

                page.data[`${page.title}_quill_text_0`] = JSON.stringify({"ops":[{"insert":"The F.I.R.E. movement or Financial Independence/Retire Early is the idea that you are able to support yourself using the passive income generated through your investments, whether they be taxable investment accounts, tax-advantaged investments accounts (aka retirement accounts), income from investment properties, or any combination of the above!\nRetirement means different things to different people, this goal is important whether you plan to retire at 40 or if you plan to work until the day you die. Building up your assets gives you the flexibility to work less, take time off, plan for a semi-retirement, or even stop working entirely!\nTo become financially independent, our clients put an emphasis on increasing the gap between their income and expenses to dedicate as much free cash towards this goal as possible. In order to calculate your F.I.R.E. goal, we first need to determine what your anticipated monthly expenses will be when you are ready to stop working.\n"}]});

                page.data[`${page.title}_quill_text_1`] = $planGeneratorStore.plan.fire_goal_trainer_comments
            }
        }
    }

    const calculateFireIncome = () => {
        const totalCurrentExpenses = Number.isFinite(parseFloat(page.data.total_current_expenses)) ? parseFloat(page.data.total_current_expenses) : 0;
        const expectedExpenseChange = Number.isFinite(parseFloat(page.data.expected_expense_change)) ? parseFloat(page.data.expected_expense_change) : 0;
        const expectedIncome = Number.isFinite(parseFloat(page.data.expected_income)) ? parseFloat(page.data.expected_income) : 0;

        page.data.required_fire_income = totalCurrentExpenses + expectedExpenseChange - expectedIncome;

    }

    const calculateFire = () => {
        const requiredFireIncome = Number.isFinite(parseFloat(page.data.required_fire_income)) ? parseFloat(page.data.required_fire_income) : 0;
        const fireMultiplier = Number.isFinite(parseFloat(page.data.fire_multiplier)) ? parseFloat(page.data.fire_multiplier) : 0;

        page.data.assets_required_for_fire = requiredFireIncome * fireMultiplier;
    }

    $: if (page.data) {
        calculateFireIncome();
        calculateFire();
    }
</script>

<h1 class="page-title">
    {#if !page.duplicate}
        F.I.R.E. Goal
    {:else}
        <div class="input-group hide-print">
            <input type="text" placeholder="Name" bind:value={page.data.name} />
        </div>
        <span class="show-print">{page.data.name}</span>
    {/if}
</h1>

{#if !page.duplicate}
    <p>The F.I.R.E. movement or Financial Independence/Retire Early is the idea that you are able to support yourself using the passive income generated through your investments, whether they be taxable investment accounts, tax-advantaged investments accounts (aka retirement accounts), income from investment properties, or any combination of the above!</p>

    <p>Retirement means different things to different people, this goal is important whether you plan to retire at 40 or if you plan to work until the day you die. Building up your assets gives you the flexibility to work less, take time off, plan for a semi-retirement, or even stop working entirely!</p>

    <p>To become financially independent, our clients put an emphasis on increasing the gap between their income and expenses to dedicate as much free cash towards this goal as possible. In order to calculate your F.I.R.E. goal, we first need to determine what your anticipated monthly expenses will be when you are ready to stop working.</p>
{:else}
    <TrainerCommentComponent type="{page.title}_quill_text_0" />
{/if}

<table class="two-column-table">
    <thead>
        <tr>
            <th colspan={!page.duplicate ? "2" : ''}>FINANCIAL INDEPENDENCE</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th>Current Monthly Expenses</th>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(totalCurrentExpenses)}
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={page.data.total_current_expenses} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.total_current_expenses)}</span>
                {/if}
            </td>
        </tr>
        <tr>
            <th>Expected Additions/Reductions</th>
            <td>
                {#if !page.duplicate}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={$planGeneratorStore.plan.expected_expense_change} />
                    </div>
                    <span class="show-print">{accounting.formatMoney($planGeneratorStore.plan.expected_expense_change)}</span>
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={page.data.expected_expense_change} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.expected_expense_change)}</span>
                {/if}
            </td>
        </tr>
        <tr>
            <th>Expected Income (SS, Pension, etc)</th>
            <td>
                {#if !page.duplicate}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={$planGeneratorStore.plan.expected_income} />
                    </div>
                    <span class="show-print">{accounting.formatMoney($planGeneratorStore.plan.expected_income)}</span>
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={page.data.expected_income} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.expected_income)}</span>
                {/if}
            </td>
        </tr>
        <tr>
        </tr>
        <tr>
            <th>Required FIRE Income</th>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(requiredFireIncome)}
                {:else}
                    {accounting.formatMoney(page.data.required_fire_income)}
                {/if}
            </td>
        </tr>
        <tr>
            <th>FIRE Multiplier</th>
            <td>
                {#if !page.duplicate}
                    300
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="target" bind:value={page.data.fire_multiplier} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.fire_multiplier)}</span>
                {/if}
            </td>
        </tr>
        <tr>
        </tr>
        <tr>
            <th>Assets Required to Reach FIRE</th>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(requiredFireIncome * 300)}
                {:else}
                    {accounting.formatMoney(page.data.assets_required_for_fire)}
                {/if}
            </td>
        </tr>
    </tbody>
</table>

{#if !page.duplicate}
    <TrainerCommentComponent type='fire_goal_trainer_comments' />
{:else}
    <TrainerCommentComponent type="{page.title}_quill_text_1" />
{/if}

<style lang="stylus" scoped>

    @media screen and (max-width 480px)
        table
            display block
            overflow-x auto

</style>
