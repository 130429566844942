<script>
    import { link } from 'svelte-spa-router'

    import * as api from '../../shared/api_access';

    let email = '';
    let forgotError = false;
    let forgotSuccess = false;
    let submitting = false;
    let errorMessage = '';
    const successMessage = 'Your password has been successfully reset. Please check your e-mail for the reset link.';

    const forgotPassword = async () => {
        const submitBtn = document.getElementById('submit-button');
        submitBtn.disabled = 'disabled';
        submitting = true;
        forgotError = false;
        forgotSuccess = false;

        const response = await api.forgotPassword(email);

        if (response.status !== 200) {
            response.data.email[0] = response.data.email[0].includes('password') ? `${response.data.email[0].slice(0, 59)}.` : response.data.email[0];

            errorMessage = `${response.data.email.join(' ')} Please try again.`
            forgotError = true;
            submitBtn.disabled = null;
            submitting = false;
        } else {
            forgotSuccess = true;
            submitting = false;
        }
    };
</script>

<div class="column column-full container w90">
    <div class="page-content clearfix">
        <div class="form-wrapper">
            <h1 class="page-title">Forgot Password</h1>
            {#if forgotError}
                <div class="alert" role="alert">
                    {@html errorMessage}
                </div>
            {/if}

            {#if forgotSuccess}
                <div class="alert alert-success" role="alert">
                    {@html successMessage}
                </div>
            {:else}
                <form>
                    <div class="field">
                        <label for="email">Email address</label>
                        <input
                            type="email"
                            class="form-control"
                            id="email"
                            aria-describedby="email-help"
                            bind:value="{email}" />
                        <small id="email-help" class="form-text text-muted">
                            Please enter a valid email address
                        </small>
                    </div>
                    <button
                        type="submit"
                        class="button small primary"
                        id="submit-button"
                        on:click|preventDefault="{e => forgotPassword(e)}">
                        {#if submitting}
                            <span class="spinner icon-spinner9"></span>
                        {:else}Reset{/if}
                    </button>
                </form>
            {/if}

            <p>
                <a
                    class="badge badge-pill badge-light"
                    href="/login"
                    aria-label="Back to Log In"
                    use:link >
                    &laquo;
                    Log In
                </a>
            </p>
        </div>
    </div>
</div>
