/* jshint esversion: 6 */
const Expense = require('../Models/Expense');

class ExpenseManager {
    constructor() {
        this.expenses = [];
        this.expenseIds = [];
        this.today = new Date();
        this.totalMonth = 0;
        this.totalAnnual = 0;

        this.totalDebtAnnual = 0;
        this.totalGoalsAnnual = 0;
        this.totalFixedAnnual = 0;
        this.totalSavingsAnnual = 0;
        this.totalSpendAnnual = 0;
        this.totalVariableAnnual = 0;

        this.totalDebtMonth = 0;
        this.totalGoalsMonth = 0;
        this.totalFixedMonth = 0;
        this.totalSavingsMonth = 0;
        this.totalSpendMonth = 0;
        this.totalVariableMonth = 0;
    }

    addExpense(account) {
        // Construct an expense
        const expense = new Expense(account);

        this.expenses.push(expense);

        if (expense.type.name === 'debt') {
            this.totalDebtAnnual += expense.getAnnualAmount();
            this.totalDebtMonth += expense.getMonthAmount();
        } else if (expense.type.name === 'goals') {
            this.totalGoalsAnnual += expense.getAnnualAmount();
            this.totalGoalsMonth += expense.getMonthAmount();
        } else if (expense.type.name === 'fixed') {
            this.totalAnnual += expense.getAnnualAmount();
            this.totalMonth += expense.getMonthAmount();
            this.totalFixedAnnual += expense.getAnnualAmount();
            this.totalFixedMonth += expense.getMonthAmount();
        } else if (expense.type.name === 'savings') {
            this.totalSavingsAnnual += expense.getAnnualAmount();
            this.totalSavingsMonth += expense.getMonthAmount();
        } else if (expense.type.name === 'spend') {
            this.totalSpendAnnual += expense.getAnnualAmount();
            this.totalSpendMonth += expense.getMonthAmount();
        } else if (expense.type.name === 'variable') {
            this.totalAnnual += expense.getAnnualAmount();
            this.totalMonth += expense.getMonthAmount();
            this.totalVariableAnnual += expense.getAnnualAmount();
            this.totalVariableMonth += expense.getMonthAmount();
        }

        return expense;
    }

    getTotalAnnualAmount(type = false) {
        if (type) {
            if (type === 'debt') {
                return this.totalDebtAnnual;
            }
            if (type === 'goals') {
                return this.totalGoalsAnnual;
            }
            if (type === 'fixed') {
                return this.totalFixedAnnual;
            }
            if (type === 'savings') {
                return this.totalSavingsAnnual;
            }
            if (type === 'spend') {
                return this.totalSpendAnnual;
            }
            if (type === 'variable') {
                return this.totalVariableAnnual;
            }
            throw `The type ${type} is invalid`;
        }
        return this.totalAnnual;
    }

    getTotalMonthAmount(type = false) {
        try {
            if (type) {
                if (type === 'debt') {
                    return this.totalDebtMonth;
                }
                if (type === 'goals') {
                    return this.totalGoalsMonth;
                }
                if (type === 'fixed') {
                    return this.totalFixedMonth;
                }
                if (type === 'savings') {
                    return this.totalSavingsMonth;
                }
                if (type === 'spend') {
                    return this.totalSpendMonth;
                }
                if (type === 'variable') {
                    return this.totalVariableMonth;
                }
                throw `The type ${type} is invalid`;
            }
            return this.totalMonth;
        } catch (e) {
            console.error(e);
        }
    }

    getByType(type) {
        const filteredArray = [];
        for (let i = 0; i < this.expenses.length; i += 1) {
            if (this.expenses[i].type.name === type) {
                filteredArray.push(this.expenses[i]);
            }
        }
        return filteredArray;
    }

    getByName(name) {
        return this.expenses.filter(expense => expense.name.toLowerCase() === name);
    }

    getMostRecent() {
        let mostRecent = null;
        this.expenses.forEach(expense => {
            if (
                mostRecent === null ||
                new Date(expense.dateCreated) < new Date(mostRecent.dateCreated)
            ) {
                mostRecent = expense;
            }
        });
        return mostRecent;
    }
}

module.exports = ExpenseManager;
