const { DeepCompare } = require('./DeepCompare');

class ClientInfoDeepCompare extends DeepCompare {
    constructor(updatedObj, originalObj) {
        super(updatedObj, originalObj);
        this.dontConvertThese = [
            'birth_date',
            'city',
            'users',
            'employer',
            'id',
            'name',
            'occupation',
            'phone_number',
            'postal_code',
            'referral',
            'start_date',
            'trainer',
            'reason'
        ];
        this.deepCompareObj(updatedObj, originalObj);
    }

    deepCompareObj(updatedObj, originalObj) {
        Object.keys(updatedObj).forEach(key => {
            if (!this.dontConvertThese.includes(key)) {
                if (!isNaN(parseFloat(updatedObj[key])) || !isNaN(parseFloat(originalObj[key]))) {
                    updatedObj[key] = parseFloat(updatedObj[key]);
                    originalObj[key] = parseFloat(originalObj[key]);
                }
            }

            if (key !== 'users' && updatedObj[key] !== originalObj[key]) {
                this.payload[key] = updatedObj[key];
                this.savePayload = true;
                return false;
            }
            if (key === 'users' && originalObj[key][0].email !== updatedObj[key][0].email) {
                // Makes the payload email similar to how it would be on creating a client
                // This is to keep it consistent since this compare is not run when creating
                this.payload.users = [
                    {
                        email: updatedObj[key][0].email
                    }
                ]
                this.savePayload = true;
                return false;
            }
        });
    }

    prunePayload() {
        Object.keys(this.payload).forEach(key => {
            if (`${this.payload[key]}` === `NaN`) {
                this.payload[key] = null;
            }
        });
    }
}

module.exports = {
    ClientInfoDeepCompare
};
