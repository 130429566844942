<script>
    import { link } from 'svelte-spa-router'

    import * as api from '../../shared/api_access';

    export let token;

    let password = '';
    let confirmPassword = '';
    let hideForm = false;
    let resetError = false;
    let resettingPassword = false;
    let resetSuccess = false;

    const resetPassword = async () => {
        errorMessage = '';
        const submitBtn = document.getElementById('submit-button');
        submitBtn.disabled = 'disabled';
        resettingPassword = true;

        const response = await api.resetPassword(token, confirmPassword);

        if (response.status !== 200) {
            resetError = true;
            resettingPassword = false;
            submitBtn.disabled = null;
            if (response.data.status === 'notfound') {
                hideForm = true;
                errorMessage = `That reset token is no longer valid. Please request a new password reset link <a href="/#/forgot-password">here</a>.`;
            } else {
                errorMessage = `${response.data.password.join(' ')} Please try again or contact <a href="mailto:tech@fingyms.com">tech@fingyms.com</a>.`;
            }
        } else {
            hideForm = true;
            resetSuccess = true;
            resettingPassword = false;
            submitBtn.disabled = null;
        }
    };
    const passwordCheck = async () => {
        resetSuccess = false;
        resetError = false;
        // To check a password between 8 to 32 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character
        const validatorRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,32}$/;
        if (password === confirmPassword) {
            if (confirmPassword.match(validatorRegex)) {
                await resetPassword();
                return true;
            }
            resetError = true;
            errorMessage =
                'Your password needs to be between 8 to 32 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character';
        }

        resetError = true;
        return false;
    };
    $: errorMessage = 'Passwords do not match';
    $: successMessage =
        'Your password has succesfully been reset! Please login <a href="/#/login">here</a>.';
</script>

<div class="column column-full container w90">
    <div class="page-content clearfix">
        <div class="form-wrapper">
            <h1 class="page-title">Reset Password</h1>
            {#if resetError}
                <div class="alert alert-danger" role="alert">
                    {@html errorMessage}
                </div>
            {/if}

            {#if resetSuccess}
                <div class="alert alert-success" role="alert">
                    {@html successMessage}
                </div>
            {/if}

            {#if !hideForm}
            <form>
                <div class="field">
                    <label for="new-password">New Password</label>
                    <input
                        id="new-password"
                        type="password"
                        class="form-control"
                        required=""
                        bind:value="{password}" />
                </div>
                <div class="field">
                    <label for="confirm-new-password">Confirm New Password</label>
                    <input
                        id="confirm-new-password"
                        type="password"
                        class="form-control"
                        required=""
                        bind:value="{confirmPassword}" />
                    <small id="email-help" class="form-text text-muted">
                        Use 8 or more characters with a mix of letters, numbers & symbols
                    </small>
                </div>
                <button
                    type="submit"
                    class="button small primary"
                    id="submit-button"
                    on:click|preventDefault="{() => passwordCheck()}">
                    {#if resettingPassword}
                        <span class="spinner icon-spinner9"></span>
                    {:else}Reset Your Password{/if}
                </button>

                <p class="form-text form-muted">
                    <a
                        href="/login"
                        aria-label="Back to log in"
                        use:link >
                        &laquo;
                        Log In
                    </a>
                </p>
            </form>
            {/if}
        </div>
    </div>
</div>
