/* jshint esversion: 6 */
const BaseManager = require('./BaseManager');
const Asset = require('../Models/Asset');

class AssetManager extends BaseManager {
    constructor() {
        super();
        this.assets = [];
        this.assetIds = [];
        this.today = new Date();
        this.totalBalance = 0;
        this.totalMonth = 0;
        this.totalAnnual = 0;
        this.totalContribution = 0;
        this.categories = [];
        this.investmentTypes = [];
    }

    addAsset(account) {
        // Construct an income
        const asset = new Asset(account);

        this.assets.push(asset);
        this.totalBalance += asset.balance;
        // Can no longer use these since it may be a percent of a number (income)
        // that exists outside of this manager (must use wizard)
        // this.totalAnnual += asset.getAnnualAmount();
        // this.totalMonth += asset.getMonthAmount();
        this.totalContribution += asset.contribution;

        if (this.categories[asset.category] === undefined) {
            this.categories[asset.category] = [];
        }
        this.categories[asset.category].push(asset);

        if (this.investmentTypes[asset.investmentType] === undefined) {
            this.investmentTypes[asset.investmentType] = [];
        }
        this.investmentTypes[asset.investmentType].push(asset);

        return asset;
    }

    getByType(types = [], previous = false) {
        const filteredArray = [];
        types.forEach(type => {
            this.assets.forEach(asset => {
                if (asset.category.toLowerCase() === type && asset.previous === previous) {
                    filteredArray.push(asset);
                }
            });
        });
        return filteredArray;
    }

    getByInvestmentType(types = [], period = 'all') {
        const filteredArray = [];
        types.forEach(type => {
            this.assets.forEach(asset => {
                if (asset.investmentType.toLowerCase() === type) {
                    switch (period) {
                    case 'previous':
                        if (asset.previous) {
                            filteredArray.push(asset);
                        }
                        break;
                    case 'current':
                        if (!asset.previous) {
                            filteredArray.push(asset);
                        }
                        break;
                    default:
                        filteredArray.push(asset);
                        break;
                    }
                }
            });
        });
        return filteredArray;
    }

    getTotal(categories, timeRange, totalMonthlyGrossIncome, previous, includePreviousAndCurrent, employerContribution = false) {
        return this.assets.reduce((total, asset) => {
            // Check for conditions when the asset would not be used
            if (categories.length > 0 && !categories.includes(asset.category) && !categories.includes(asset.investmentType)) {
                return total;
            }
            if (!includePreviousAndCurrent && asset.previous !== previous) {
                return total;
            }

            // Return asset value for reduce
            if (timeRange === 'month') {
                return total + asset.getMonthAmount(totalMonthlyGrossIncome);
            }
            if (timeRange === 'annual') {
                return total + asset.getAnnualAmount(totalMonthlyGrossIncome, employerContribution);
            }
            if (timeRange === 'all') {
                return total + asset.getBalance();
            }

            // Return fail safe if no condition is met
            return total;
        }, 0);
    }

    getBalanceByType(categories = [], previous = false) {
        return this.assets.reduce((total, asset) => {
            if ((categories.includes(asset.category) || categories.includes(asset.investmentType)) && asset.previous === previous) {
                return total + asset.balance;
            }
            return total;
        }, 0);
    }

    getContributionByType(categories = [], previous = false, grossMonthlyIncome) {
        return this.assets.reduce((total, asset) => {
            if ((categories.includes(asset.category) || categories.includes(asset.investmentType)) && asset.previous === previous) {
                return total + asset.getMonthAmount(grossMonthlyIncome);
            }
            return total;
        }, 0);
    }
}

module.exports = AssetManager;
