<script>
    import accounting from '../../shared/accounting_config';
    import calculations from '../../helpers/calculations';

    import TrainerCommentComponent from './TrainerCommentComponent.svelte';
    import NumberInput from '../UXElements/NumberInput.svelte';

    export let wizard;
    export let page;

    $: educationGoal = wizard.goalManager
        .getByTypes(['college_savings'], 'activeGoals')
        .reduce((prev, current) => {
            const monthlyAmount = accounting.unformat(
                accounting.toFixed(
                    (calculations.getPMT(current.monthsRemaining, current.balance, current.amount))
                    , 0)
            );

            const originalCurrentPlanMonthAmount = parseFloat(current.planMonthAmount);
            const originalPrevPlanMonthAmount = parseFloat(prev.planMonthAmount);

            if (monthlyAmount >= 0){
                current.planMonthAmount = originalCurrentPlanMonthAmount === Math.round(monthlyAmount) ? Math.round(monthlyAmount) : originalCurrentPlanMonthAmount;
                prev.planMonthAmount = originalPrevPlanMonthAmount === Math.round(monthlyAmount) ? Math.round(monthlyAmount) : originalPrevPlanMonthAmount;
            } else {
                current.planMonthAmount = 0;
                prev.planMonthAmount = 0;
            }

            if(current.monthlyAmount > 0) {
                if (monthlyAmount !== current.monthlyAmount) {
                    current.planMonthAmount = Math.round(monthlyAmount);
                    prev.planMonthAmount = Math.round(monthlyAmount);
                }
            }

            current.monthlyAmount = monthlyAmount;

            return prev.monthsRemaining < current.monthsRemaining ? prev : current;
        }, {});



    $: yearsRemaining = Math.round(educationGoal.monthsRemaining / 12);
    $: currentAmount = educationGoal.balance;
    $: targetAmount = educationGoal.amount;
    $: monthlyContribution = accounting.toFixed(calculations.getPMT(educationGoal.monthsRemaining, currentAmount, targetAmount), 0);
    $: annualContribution = monthlyContribution * 12;

    $: if (page.duplicate) {
        if (!page.id) {
            if (page.data.length === 0) {
                page.data = {
                    name: `Education Savings - ${parseInt(page.title.split('_')[1]) + 1}`,
                    show_table: true,
                    years_remaining: parseFloat(yearsRemaining).toFixed(0),
                    target_amount: parseFloat(targetAmount).toFixed(0),
                    current_amount: parseFloat(currentAmount).toFixed(0),
                    expected_return: 7,
                    annual_contribution: parseFloat(annualContribution).toFixed(0),
                    monthly_contribution: parseFloat(monthlyContribution).toFixed(0)
                };

                page.data[`${page.title}_quill_text_0`] = JSON.stringify({"ops":[{"insert":"You'd like to begin saving for your children’s college. Right now, the average cost for a 4 year private college is approximately $35,000 per year, or $140,000 total.\nGiven that you have about "},{"attributes":{"bold":true},"insert":"XX"},{"insert":" years until your child begins college, you should be building these savings in an investment account of some kind. There are several options to consider, here’s a breakdown of the pros and cons of each type:"},{"attributes":{"list":"bullet"},"insert":"\n"}]});

                page.data[`${page.title}_quill_text_1`] = JSON.stringify({"ops":[{"insert":"Now let's see how much you’d need to commit to saving on a monthly basis in order to achieve your goal of $"},{"attributes":{"bold":true},"insert":"XXX"},{"insert":".\n"}]});
            }
        }
    }

    const calculateEducationContributions = () => {
        const monthsRemaining = Number.isFinite(parseFloat(page.data.years_remaining)) ? parseFloat(page.data.years_remaining) * 12 : 0;
        const currentAmount = Number.isFinite(parseFloat(page.data.current_amount)) ? parseFloat(page.data.current_amount) : 0;
        const targetAmount = Number.isFinite(parseFloat(page.data.target_amount)) ? parseFloat(page.data.target_amount) : 0;
        const interest = Number.isFinite(parseFloat(page.data.expected_return)) ? parseFloat(page.data.expected_return) : 0;

        page.data.monthly_contribution = accounting.toFixed(calculations.getPMT(monthsRemaining, currentAmount, targetAmount, interest), 0);
        page.data.annual_contribution = accounting.toFixed(parseFloat(page.data.monthly_contribution) * 12, 0)
    }

    $: if (page.data) {
        calculateEducationContributions();
    }
</script>

<h1 class="page-title">
    {#if !page.duplicate}
        Education Savings
    {:else}
        <div class="input-group hide-print">
            <input type="text" placeholder="Name" bind:value={page.data.name} />
        </div>
        <span class="show-print">{page.data.name}</span>
    {/if}
</h1>

{#if !page.duplicate}
    <p>
        You'd like to begin saving for your children’s college. Right now, the average cost for
        a 4 year private college is approximately $35,000 per year, or $140,000 total.
    </p>
    <ul>
        <li>
            Given that you have about {yearsRemaining} years until your child begins college,
            you should be building these savings in an investment account of some kind. There
            are several options to consider, here’s a breakdown of the pros and cons of each
            type:
        </li>
    </ul>
{:else}
    <TrainerCommentComponent type="{page.title}_quill_text_0" />
{/if}

{#if page.duplicate}
    <div class="hide-print field checkboxes">
        <label class="check-label">
            <input
                type="checkbox"
                bind:checked="{page.data.show_table}" />
            <span>
                {#if page.data.show_table}
                    Hide
                {:else}
                    Show
                {/if} Table
            </span>
        </label>
    </div>
{/if}

<table class="{page.duplicate ? page.data.show_table ? '' : 'hide' : ''}">
    <thead>
        <tr>
            <td></td>
            <th>529</th>
            <th>ESA</th>
            <th>UGMA/UTMA</th>
            <th>Brokerage</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>OWNERSHIP</td>
            <td>- Contributor/transferrable</td>
            <td>- Child</td>
            <td>- Child</td>
            <td>- Contributor</td>
        </tr>
        <tr>
            <td>TAX ADVANTAGES</td>
            <td>
                - Yes
                <br />
                - Can be tax deductible (depends on state)
                <br />
                - Tax free growth if used for education
                <br />
            </td>
            <td>
                - Yes, but not tax deductible
                <br />
                - Tax free growth if used for education
                <br />
            </td>
            <td>- Taxed at child's tax rate</td>
            <td>- None</td>
        </tr>
        <tr>
            <td>INVESTMENTS</td>
            <td>- Limited selection of model funds</td>
            <td>- Wider mix of traditional investments</td>
            <td>- No limits</td>
            <td>- No limits</td>
        </tr>
        <tr>
            <td>FAFSA IMPACT</td>
            <td>- Low</td>
            <td>- Low</td>
            <td>- Low</td>
            <td>- High</td>
        </tr>
        <tr>
            <td>DISBURSEMENTS</td>
            <td>
                -Educational expenses only
                <br />
                - Penalty for unauthorized or early withdrawal
            </td>
            <td>
                - Educational expenses only
                <br />
                - Penalty for unauthorized or early withdrawal
            </td>
            <td>- No limits</td>
            <td>- No limits</td>
        </tr>
    </tbody>
</table>

{#if !page.duplicate}
    <p>
        Now let's see how much you’d need to commit to saving on a monthly basis in order to
        achieve your goal of {accounting.formatMoney(targetAmount)}.
    </p>
{:else}
    <TrainerCommentComponent type="{page.title}_quill_text_1" />
{/if}

<table class="two-column-table">
    <thead>
        <tr>
            <th>CURRENT SAVINGS</th>
            <th>
                {#if !page.duplicate}
                    {accounting.formatMoney(currentAmount)}
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={page.data.current_amount} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.current_amount)}</span>
                {/if}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Expected Future Value</td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(targetAmount)}
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="dollar" bind:value={page.data.target_amount} />
                    </div>
                    <span class="show-print">{accounting.formatMoney(page.data.target_amount)}</span>
                {/if}
            </td>
        </tr>
        <tr>
            <td>Years Until College Start Date</td>
            <td>
                {#if !page.duplicate}
                    {yearsRemaining}
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="target" bind:value={page.data.years_remaining} />
                    </div>
                    <span class="show-print">{page.data.years_remaining}</span>
                {/if}
            </td>
        </tr>
        <tr>
            <td>Expected Return (%)</td>
            <td>
                {#if !page.duplicate}
                    7%
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="percent" bind:value={page.data.expected_return} />
                    </div>
                    <span class="show-print">{page.data.expected_return}%</span>
                {/if}
            </td>
        </tr>
        <tr>
            <td>EXPECTED ANNUAL CONTRIBUTION</td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(annualContribution)}
                {:else}
                    {accounting.formatMoney(page.data.annual_contribution)}
                {/if}
            </td>
        </tr>
        <tr>
            <td>EXPECTED MONTHLY CONTRIBUTION</td>
            <td>
                {#if !page.duplicate}
                    {accounting.formatMoney(monthlyContribution)}
                {:else}
                    {accounting.formatMoney(page.data.monthly_contribution)}
                {/if}
            </td>
        </tr>

    </tbody>
</table>

<style lang="stylus" scoped>
    .hide
        display none

    @media screen and (max-width: 1024px)
        table
            display block
            overflow-x auto
</style>
