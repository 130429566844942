<script>
    import FeatherIcon from '../UXElements/FeatherIcon.svelte';

    import { intakeDataStore } from '../../shared/store';
    import * as api from '../../shared/api_access';

    const type = 'nonnegotiables';
    let nonnegotiables = [];
    export let show;

    const addNonNegotiable = () => {
        nonnegotiables.push({
            name: '',
            description: ''
        });
        $intakeDataStore[type] = nonnegotiables;
    };

    const removeNonNegotiable = async (nonnegotiableID, index) => {
        nonnegotiables.splice(index, 1);
        $intakeDataStore[type] = nonnegotiables;
        if (nonnegotiableID) {
            await api.remove(nonnegotiableID, type);
        }
    };

    $: nonnegotiables = $intakeDataStore.nonnegotiables;
</script>

<div
    class="{show ? 'collapse show' : 'collapse'}"
    aria-labelledby="Non-Negotiables"
    data-parent="#non-negotiables">

    {#each nonnegotiables as nonnegotiable, index}
        <form class="panel">
            <div class="field">
                <label for="nonnegotiables-name-{index}">
                    Name
                    <button
                        class="button icon-button alert remove"
                        title="Remove this non-negotiable"
                        aria-label="Remove this non-negotiable"
                        on:click|preventDefault="{async () => removeNonNegotiable(nonnegotiable.id, index)}"
                    >
                        <FeatherIcon icon="x" />
                    </button>
                </label>
                <input
                    id="nonnegotiables-name-{index}"
                    type="text"
                    placeholder="Name"
                    bind:value="{nonnegotiable.name}" />
            </div>

            <div class="field">
                <label for="nonnegotiables-description-{index}">Description</label>
                <textarea
                    id="nonnegotiables-description-{index}"
                    class="form-control"
                    rows="4"
                    placeholder="Description"
                    bind:value="{nonnegotiable.description}"></textarea>
            </div>
        </form>
    {/each}

    <button class="button small primary" on:click="{() => addNonNegotiable()}">
        Add a Non-Negotiable
    </button>

</div>

<style lang="stylus" scoped>
    form
        margin-bottom 1rem
</style>
