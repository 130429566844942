<script>
    import { intakeDataStore } from '../../shared/store';
    import * as api from '../../shared/api_access';
    import * as creditTypes from '../../helpers/credit_types';

    import FeatherIcon from '../UXElements/FeatherIcon.svelte';
    import NumberInput from '../UXElements/NumberInput.svelte';

    export let show;

    let type = 'credit_scores';

    let credit_scores = [];
    let errors = [];
    let messages = [];

    let distanceThreshold = 40;
    let distanceError = false;
    let distanceMessage = 'These credit scores are further apart than normal';

    const addCreditScore = () => {
        $intakeDataStore[type].push({
            bureau: {
                name: 'equifax'
            },
            name: '',
            credit_score: null
        });
        credit_scores = $intakeDataStore[type];
    };

    const removeCreditScore = async (index, id) => {
        $intakeDataStore[type].splice(index, 1);
        credit_scores = $intakeDataStore[type];
        if (id) {
            await api.remove(id, 'credit-scores');
        }
    };

    $: {
        credit_scores = $intakeDataStore[type];

        errors = [];
        messages = [];
        let minScore = null;
        let maxScore = null;
        for (let i = 0; i < credit_scores.length; i++) {
            // Individual error messaging
            if (credit_scores[i].credit_score < 300) {
                errors.push(true);
                messages.push('greater than 300');
            } else if (credit_scores[i].credit_score > 850) {
                errors.push(true);
                messages.push('less than 850');
            } else {
                errors.push(false);
                messages.push('');
            }

            // Score distance error messaging
            if (credit_scores[i].credit_score < minScore || minScore === null) {
                minScore = credit_scores[i].credit_score;
            }
            if (credit_scores[i].credit_score > maxScore || maxScore === null) {
                maxScore = credit_scores[i].credit_score;
            }
        }
        if (maxScore - minScore > distanceThreshold) {
            distanceError = true;
        } else {
            distanceError = false;
        }
    }
</script>

<div
    class="{show ? 'collapse show' : 'collapse'}"
    aria-labelledby="Credit Scores"
    data-parent="#credit-scores">

    {#if distanceError}
        <div class="alert alert-danger"><p>{distanceMessage}</p></div>
    {/if}

    <div class="panel">
        <div class="group">
            <div class="field split half-width">
                <label for="credit-bureau">Bureau</label>
            </div>
            <div class="field split half-width last">
                <label for="credit-score">Score</label>
            </div>
        </div>

        {#each credit_scores as entry, index}
            {#if errors[index]}
                <div class="alert alert-danger">
                    Please include a credit score {messages[index]}
                </div>
            {/if}

            <div class="group">
                <div class="field split half-width">
                    <select id="credit-bureau-{index}" class="form-control" bind:value="{entry.bureau.name}">
                        {#each creditTypes.creditTypes as option}
                            <option value="{option.value}">{option.name}</option>
                        {/each}
                    </select>
                </div>
                <div class="field split half-width last">
                    <NumberInput
                        id="credit-score-{index}"
                        placeholder="Credit Score"
                        min="300"
                        max="850"
                        type="target" 
                        bind:value={entry.credit_score} />
                    <button
                        class="alert button icon-button"
                        title="Remove Credit Score"
                        aria-label="Remove Credit Score"
                        on:click|preventDefault="{async () => removeCreditScore(index, entry.id)}">
                        <FeatherIcon icon="x" />
                    </button>
                </div>
            </div>
        {/each}

        <!-- Add Credit Score -->
        <button
            class="button small primary" 
            on:click|preventDefault="{() => addCreditScore()}">
            Add Credit Score
        </button>

    </div>

</div>

<style lang="stylus" scoped>
    label
        padding-left 1em

    .last
        column-gap 10px
        display grid
        grid-template-columns 10fr 1fr
        margin-right 0

        .button
            align-self center
            color var(--color-orange)
            &:hover
                color var(--color-gray-dark)
</style>
