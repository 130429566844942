<script>
    import { onMount, onDestroy } from 'svelte';
    import { push } from 'svelte-spa-router'
    import { loggedIn, trainerDataStore } from '../shared/store';
    export let logOut;

    $: trainerName = $trainerDataStore.first_name + ' ' + $trainerDataStore.last_name;

    onMount(() => {
        window.addEventListener("click", handleClick);
    });
    onDestroy(() => {
        window.removeEventListener("click", handleClick);
    });

    const handleClick = (e) => {
        if ($loggedIn) {
            const link = document.getElementById("deskProfileLink");
            const menu = document.getElementById("deskProfile");
            if (menu.classList.contains('open') && !link.contains(e.target) && !menu.contains(e.target)) {
                document.getElementById("deskProfile").classList.remove('open');
            }
        }   
    };
</script>

<div class="user-actions">
    {#if $loggedIn}
    <!-- No alerts just yet
    <a class="ico-alert new-alert" href="alerts">
        <span class="screen-reader-text">See all alerts</span>
    </a>
    -->
    <div class="profile-actions">

        <div class="profile-info">
            <button 
                class="open-link open-profile button icon-button" 
                id="deskProfileLink"
                title="Open Menu"
                aria-label="Open Menu"
                rel="deskProfile" >
                <img
                    class="image-round image-round-med"
                    src="{$trainerDataStore.users.length > 0 ? $trainerDataStore.users[0].gravatar_url : `${S3BUCKET_URL}/logo-mark.jpg`}"
                    alt="User's Name" />
                <h3>{ trainerName }</h3>
            </button>
            <!-- Don't have location just yet
            <h4 class="gray-dark">Manhattan</h4>
            -->
        </div>

        <div class="actions" id="deskProfile">
            <!-- Don't have job title just yet
            <div class="container">
                <p class="accent">Level 2 Trainer</p>
            </div>
            <hr/>
            -->
            <div class="container">
                <div class="profile-links">
                    <!-- Don't have the ability to edit a profile just yet
                    <p><a href="#">My Profile</a></p>
                    -->
                    <!-- Don't have the ability to switch to a user account just yet
                    <p><a href="#">Switch to User Account</a></p>
                    -->
                </div>
            </div>
            <hr/>
            <div class="container">
                <div class="logout">
                    <button
                        class="button icon-button"
                        on:click|preventDefault="{() => {logOut()}}">
                        Log Out
                    </button>
                </div>
            </div>
        </div>

    </div>
    {/if}
</div>

<style lang="stylus" scoped>
    .icon-button
        &:hover
            color var(--color-green-light)

    .open-link
        align-items center
        display flex
</style>
