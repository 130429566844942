<script>
    import * as api from '../../shared/api_access';
    import FeatherIcon from './FeatherIcon.svelte';
    
    export let linkedList;
    export let updatePages;
    export let page;
    export let duplicatablePages = {};
    export let removingDuplicatePage = null;

    const toggleShowHidePage = () => {
        page.show_in_plan = !page.show_in_plan;
    }
</script>

<div class="container-fluid hide-print">
    <div class="row alert alert-light" role="alert">
        <div class="page-buttons-text">
            <p>
                <em>You can add a slide below this one or prevent this slide from printing. If this slide was not part of the generated plan, removing will delete the content!</em>
            </p>
        </div>
        <div>
            <button
                class="toggle-on button icon-button"
                title="Add Blank Slide"
                on:click|preventDefault="{() => {
                    linkedList.add(page, '', '');
                    updatePages();
                }}"
            >
                <FeatherIcon icon="plus-circle" />
            </button>
            {#if !page.title || page.title.includes('_')}
                <button
                    class="toggle-off button icon-button"
                    title="Remove Slide"
                    on:click|preventDefault="{async () => {
                        if (page.title.includes('_')) {
                            removingDuplicatePage = true;
                        }

                        if (page.id) {
                            await api.remove(page.id, 'plan_pages');
                        }

                        linkedList.remove(page);
                        updatePages();

                        if (page.title.includes('_')) {
                            setTimeout(() =>{
                                removingDuplicatePage = false;
                            }, 1000);
                        }
                    }}"
                >
                    <FeatherIcon icon="x" />
                </button>
            {:else}
                <button
                    class="toggle-{page.show_in_plan ? 'off' : 'on'} button icon-button"
                    title={page.show_in_plan ? "Hide Slide" : "Show Slide"}
                    on:click|preventDefault="{() => toggleShowHidePage()}"
                >
                    {#if page.show_in_plan}
                        <FeatherIcon icon="x" />
                    {:else}
                        <FeatherIcon icon="rotate-ccw" />
                    {/if}
                </button>
            {/if}

            {#if duplicatablePages[page.title]}
                <button
                    id="duplicate-button"
                    class="toggle-on button icon-button"
                    title="Duplicate Slide"
                    on:click|preventDefault="{() => {
                        duplicatablePages[page.title].duplicatedPages.push(true);
                        linkedList.add(page, `${page.title}_${duplicatablePages[page.title].duplicatedPages.length - 1}`, '', true, true);
                        updatePages(true);
                    }}"
                >
                    <FeatherIcon icon="zap" />
                </button>
            {/if}
        </div>
    </div>
</div>

<style lang="stylus" scoped>
    .container-fluid
        opacity 0.15

    #duplicate-button
        float right

    .page-buttons-text
        visibility hidden

    .container-fluid p
        margin-bottom 0 !important

    .container-fluid:hover
        opacity 1

    .container-fluid:hover .page-buttons-text
        visibility visible
</style>
