/* jshint esversion: 6 */
const Income = require('../Models/Income');

class IncomeManager {
    constructor() {
        this.incomes = [];
        this.incomeIds = [];
        this.today = new Date();
        this.totalAnnualGross = 0;
        this.totalAnnualNet = 0;
        this.totalMonthGross = 0;
        this.totalMonthNet = 0;
        this.totalPaycheckGross = 0;
        this.totalPaycheckNet = 0;
    }

    addIncome(account) {
        // Construct an income
        const income = new Income(account);

        this.incomes.push(income);
        this.incomeIds.push(income.getId());
        this.totalAnnualGross += income.getAnnualGross();
        this.totalMonthGross += income.getMonthGross();
        this.totalPaycheckGross += income.getPaycheckGross();
        this.totalAnnualNet += income.getAnnualNet();
        this.totalMonthNet += income.getMonthNet();
        this.totalPaycheckNet += income.getPaycheckNet();

        return income;
    }

    // Gross
    getTotalAnnualGross() {
        return isNaN(this.totalAnnualGross) ? 0 : this.totalAnnualGross;
    }

    getTotalMonthGross() {
        return isNaN(this.totalMonthGross) ? 0 : this.totalMonthGross;
    }

    getTotalPaycheckGross() {
        return this.totalPaycheckGross;
    }

    // Net
    getTotalAnnualNet() {
        return this.totalAnnualNet;
    }

    getTotalMonthNet() {
        return this.totalMonthNet;
    }

    getTotalPaycheckNet() {
        return this.totalPaycheckNet;
    }
}

module.exports = IncomeManager;
