/**
 * `index` is hard-coded due to backwards compatibility. On the server,
 * the different account types were stored as numbers. On the server,
 * store only the 'value'.
 */

const assetCategories = [
    { name: 'Checking', value: 'checking', previousValue: 'checkings' },
    { name: 'Savings', value: 'savings', previousValue: 'savings' },
    { name: 'Investment', value: 'investment', previousValue: 'investments' },
    { name: 'Real Estate', value: 'real_estate', previousValue: 'real_estate' },
    { name: 'Other', value: 'other', previousValue: 'other' }
];

const goalTypes = [
    { name: 'Car Purchase', value: 'car_purchase' },
    { name: 'College', value: 'college_savings' },
    { name: 'Family Planning', value: 'family_planning' },
    { name: 'General Savings', value: 'general_savings' },
    { name: 'Grad School', value: 'grad_school' },
    { name: 'Home', value: 'home_savings' },
    { name: 'Income Producing Property', value: 'income_producing_property' },
    { name: 'Investing', value: 'investing' },
    { name: 'Mindset', value: 'mindset' },
    { name: 'Moving', value: 'moving' },
    { name: 'Pet Care', value: 'pet_care' },
    { name: 'Travel', value: 'travel_savings' },
    { name: 'Wedding', value: 'wedding' },
    { name: 'Other', value: 'other' }
];

const goalLengths = [
    { name: '1-3 Years', value: '3_years' },
    { name: '3-5 Years', value: '5_years' },
    { name: 'Long Term', value: '10_years' },
    { name: 'Custom Date', value: 'custom' }
];

const investmentTypes = [
    { name: '401k Roth', value: '401k_roth' },
    { name: '401k Traditional', value: '401k_trad' },
    { name: '403b Roth', value: '403b_roth' },
    { name: '403b Traditional', value: '403b_trad' },
    { name: '457b', value: '457b' },
    { name: '529', value: '529' },
    { name: 'Coverdell ESA', value: 'coverdell_esa' },
    { name: 'Cryptocurrency', value: 'crypto' },
    { name: 'Family Trust', value: 'family_trust' },
    { name: 'HSA', value: 'hsa' },
    { name: 'Pension', value: 'pension' },
    { name: 'Roth IRA', value: 'roth_ira' },
    { name: 'SEP', value: 'sep' },
    { name: 'SIMPLE IRA', value: 'simple_ira' },
    { name: 'Solo 401k', value: 'solo_401k' },
    { name: 'Taxable', value: 'taxable' },
    { name: 'Traditional IRA', value: 'trad_ira' },
    { name: 'TSP', value: 'tsp' },
];

const liabilityCategories = [
    { name: 'Auto Loan/Lease', value: 'auto_loan', previousValue: 'auto_loans_or_lease' },
    { name: 'Charge Card', value: 'charge_card', previousValue: 'charge_cards' },
    { name: 'Credit Card', value: 'credit_card', previousValue: 'credit_cards' },
    { name: 'Family Loan', value: 'family', previousValue: 'family' },
    { name: 'Medical', value: 'medical', previousValue: 'medical' },
    { name: 'Mortgage', value: 'mortgage', previousValue: 'mortgage' },
    { name: 'Personal Loan', value: 'personal_loan', previousValue: 'personal_loans' },
    { name: 'Student Loan', value: 'student_loan', previousValue: 'student_loans' },
    { name: 'Tax', value: 'tax', previousValue: 'tax' },
    { name: 'Other', value: 'other', previousValue: 'other' }
];

const riskCategories = [
    { name: 'life', value: 'Life Insurance' },
    { name: 'will', value: 'Will' },
    { name: 'disability', value: 'Disability Insurance' },
    { name: 'renters', value: 'Renters Insurance' },
    { name: 'homeowners', value: 'Homeowners Insurance' },
]

const contributionTypes = [
    { name: 'Dollar', value: 'dollar' },
    { name: 'Percent', value: 'percent' }
];

const studentLoanTypes = [
    { index: 0, name: 'Federal', value: 'federal' },
    { index: 1, name: 'Private', value: 'private' }
];

module.exports = {
    assetCategories,
    goalTypes,
    goalLengths,
    investmentTypes,
    liabilityCategories,
    contributionTypes,
    studentLoanTypes,
    riskCategories
};
