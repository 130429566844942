const { DeepCompare } = require('./DeepCompare');

class IntakeDeepCompare extends DeepCompare {
    constructor(updatedObj, originalObj) {
        super(updatedObj, originalObj);
        this.dontConvertThese = [
            'id',
            'name',
            'goal_length',
            'description',
            'investment_type',
            'start_date',
            'end_date',
            'custom_date',
            'date_created',
        ];

        Object.keys(updatedObj).forEach(key => {
            this.payload[key] = {};
            if (Array.isArray(updatedObj[key])) {
                this.payload[key] = [];
            }
        });

        this.deepCompare(updatedObj, originalObj);
    }

    deepCompareObj(updatedObj, originalObj, index = null, arrayElement) {
        Object.keys(updatedObj).forEach(key => {
            if (this.payload[key]) {
                this.tracker = key;
                this.deepCompare(updatedObj[key], originalObj[key]);
                return false;
            }

            if (this.arrayTracker !== index) {
                if (typeof updatedObj[key] === 'object' && updatedObj[key] !== null) {
                    this.deepCompareObj(updatedObj[key], originalObj[key], index, arrayElement);
                    return false;
                }

                if (!this.dontConvertThese.includes(key)) {
                    if (!isNaN(parseFloat(updatedObj[key])) || !isNaN(parseFloat(originalObj[key]))) {
                        updatedObj[key] = parseFloat(updatedObj[key]);
                        originalObj[key] = parseFloat(originalObj[key]);
                    }
                }

                if (key.includes('date')) {
                    if (typeof updatedObj[key] !== "string" && typeof originalObj[key] === "string") {
                        updatedObj[key] = originalObj[key];
                    }
                }

                if (updatedObj[key] !== originalObj[key]) {
                    if (this.tracker === 'goals' || this.tracker === 'credit_scores') {
                        if (!key.includes('plan_') || key !== 'new_contribution') {
                            this.payload[this.tracker].push(arrayElement);
                            this.arrayTracker = index;
                            this.savePayload = true;
                        }
                        return false;
                    }

                    this.payload[this.tracker].push(updatedObj);
                    this.savePayload = true;
                    this.arrayTracker = index;
                    return false;
                }


            }
        });
    }

    deepCompareArrayOfObj(updatedObj, originalObj) {
        updatedObj.forEach((element, index) => {
            if (this.tracker !== 'expenses') {
                let originalElement = originalObj[index];
                if (element.id) {
                    originalElement = originalObj.find(ogElement => ogElement.id === element.id);
                } else {
                    this.payload[this.tracker].push(element);
                    this.savePayload = true;
                    return false;
                }
                if (originalElement !== undefined) {
                    if (this.tracker === 'goals' || this.tracker === 'credit_scores') {
                        this.deepCompare(element, originalElement, index, element);
                    } else {
                        this.deepCompare(element, originalElement, index);
                    }
                }
            }
            if (this.tracker === 'expenses') {
                if (element.edited) {
                    this.payload[this.tracker].push(element);
                    this.savePayload = true;
                    return false;
                }
                if (originalObj[index] !== undefined) {
                    if (element.id !== undefined && originalObj[index].id === undefined) {
                        this.deepCompare(element, originalObj[index], index);
                    }
                    if (element.id === originalObj[index].id) {
                        this.deepCompare(element, originalObj[index], index);
                    }
                }
            }
        });
    }

    prunePayload() {
        Object.keys(this.payload).forEach(key => {
            if (this.payload.goals) {
                this.payload.goals = this.payload.goals.map(goal => {
                    Object.keys(goal).forEach(key => {
                        if (key.includes('plan_') || key === 'new_contribution') {
                            delete goal[key];
                        }
                    });
                    return goal;
                });
            }
            if (this.payload[key].length === 0) {
                delete this.payload[key];
            }
        });
    }
}

module.exports = {
    IntakeDeepCompare
};
