const creditTypes = [
    { name: 'Equifax', value: 'equifax' },
    { name: 'Experian', value: 'experian' },
    { name: 'FICO', value: 'fico' },
    { name: 'Transunion', value: 'transunion' },
    { name: 'Other', value: 'other' }
];

module.exports = {
    creditTypes
};