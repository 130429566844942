<script>
    import { planGeneratorStore } from '../../shared/store';
    import accounting from '../../shared/accounting_config';

    import NumberInput from '../UXElements/NumberInput.svelte';
    import AssetImage from '../UXElements/AssetImage.svelte';
    import TrainerCommentComponent from './TrainerCommentComponent.svelte';

    export let wizard;

    $: monthlyNetIncome = wizard.getIncome('net', 'monthly');
    $: monthlyFixedExpenses = wizard.getExpenses('fixed', 'monthly');
    $: debtMinimums = wizard.getLiabilities();

    let incomeExpenseChanges = 0;
    $: {
        if (wizard.getExpenses('all') > wizard.getIncome('net', 'monthly')) {
            incomeExpenseChanges = (
                monthlyNetIncome -
                monthlyFixedExpenses -
                debtMinimums -
                parseFloat(
                    isNaN($planGeneratorStore.plan.minimum_variable_spend_number) ?
                        0 : $planGeneratorStore.plan.minimum_variable_spend_number
                )
            );
            incomeExpenseChanges = Math.abs(incomeExpenseChanges);
        }
    }
</script>

<h1 class="page-title">Breakeven Goal</h1>
<div class="image-center">
    <AssetImage 
        imgClass="float-right breakeven-pyramid" 
        src="images/breakeven-pyramid.png"
        alt="A pyramid that displays the steps to financial independence." />
</div>
<p>We understand that life happens and there are times where you have to rely on credit and other debt to make ends meet. There’s no shame in having to do this, but we can’t stay in that situation or mindset AND achieve the goals you hope to achieve in life! The first step we have to take is to get your income and expenses to at least match.</p>

<table class="two-column-table">
    <tbody>
        <tr>
            <th>Total Net Income</th>
            <td>{accounting.formatMoney(monthlyNetIncome)}</td>
        </tr>
        <tr>
            <th>Fixed Expense</th>
            <td>{accounting.formatMoney(monthlyFixedExpenses)}</td>
        </tr>
        <tr>
            <th>Debt Minimums</th>
            <td>{accounting.formatMoney(debtMinimums)}</td>
        </tr>
        <tr>
            <th>Leftover for Variable Spending & Goals</th>
            <td>{accounting.formatMoney(monthlyNetIncome - monthlyFixedExpenses - debtMinimums)}</td>
        </tr>
        <tr>
            <th>Minimum Variable Spend Number</th>
            <td>
                <div class="input-group hide-print">
                    <NumberInput type="dollar" bind:value={$planGeneratorStore.plan.minimum_variable_spend_number} />
                </div>
                <span class="show-print">{accounting.formatMoney($planGeneratorStore.plan.minimum_variable_spend_number)}</span>
            </td>
        </tr>
        <tr>
            <th>Cut your expenses or increase your income by</th>
            <td>
                {accounting.formatMoney(incomeExpenseChanges)}
            </td>
        </tr>
    </tbody>
</table>

<h3>Easy Ways to Save</h3>
<ul>
    <li><strong>Insurance:</strong> Unlike other industries that reward customers for loyalty, the car insurance industry tends to increase rates to customers who have been with them for a long time. To them, this indicates you are not “price sensitive.” Shopping around periodically can help prevent you from being marked not price sensitive.</li>
    <li><strong>Cell Phones <a href="https://www.frugalwoods.com/2019/07/08/my-frugal-cell-phone-service-trick-how-i-pay-10-65-a-month/" target="_blank">My Frugal Cell Phone Service Trick: How I Pay $10.65 A Month</a></strong>
    </li>
    <li><strong>Cable/Internet</strong>
    </li>
    <li><strong>Subscriptions:</strong>  Audit your subscriptions to see if you are getting the most use out of all of these services or if you could cancel some.
    </li>
    <li><strong>DIY Services</strong>
    </li>
</ul>

<TrainerCommentComponent type={'breakeven_goal_trainer_comments'} />

<style lang="stylus" scoped>
    @media print
        h3
            margin .5em 0 .5em
</style>