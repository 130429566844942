<script>
    /* eslint-disable import/first */
    import { onMount, afterUpdate } from 'svelte';
    import Router, { location, push } from 'svelte-spa-router';
    import dayjs from 'dayjs';

    import { new_routes as routes } from './router/router';
    import { loggedIn, userDataStore, intakeDataStore, planGeneratorStore, trainerDataStore, rerouteLink } from './shared/store';
    import * as api from './shared/api_access';
    import * as domFunctions from './helpers/dom_functions';

    import AppHeader from './containers/AppHeader.svelte';
    import Sidebar from './containers/Sidebar/Main.svelte';
    import Modal from './components/UXElements/Modal.svelte';

    let seconds = 90;

    // Inactivity Modal Variables
    let timeoutId;
    let listenerSet = false;
    let openModal = false;
    let openExpireModal = false;
    let extendSessionForm = false;

    // Extend Session Modal Variables
    let expirationIntervalId = null;
    let secondsIntervalId = null;
    let extendedSessionSuccessfully = false;
    let extendingSession = false;
    let expireModalBody;
    let expireModalConfirmButton;
    let expireModalCancelButton;

    function startTimer() {
        // window.setTimeout returns an Id that can be used to start and stop a timer
        timeoutId = window.setTimeout(doInactive, 3.54e6);
    }

    function doInactive() {
        // does whatever you need it to actually do - probably signs them out or stops polling the server for info
        openModal = true;
        document.title = 'Action Needed';
    }

    function resetTimer() {
        window.clearTimeout(timeoutId);
        startTimer();
    }

    function setupTimers() {
        document.addEventListener('mousemove', resetTimer, false);
        document.addEventListener('mousedown', resetTimer, false);
        document.addEventListener('keypress', resetTimer, false);
        document.addEventListener('touchmove', resetTimer, false);

        startTimer();
    }

    function startExpirationTimer() {
        expirationIntervalId = window.setInterval(expirationTimer, 1000);
    }

    function resetExpirationTimer() {
        window.clearInterval(expirationIntervalId);
        startExpirationTimer();
    }

    function expirationTimer() {
        if (localStorage.getItem('token')) {
            if (dayjs().toDate() - dayjs(localStorage.getItem('expiring')).toDate() > 0) {
                openExpireModal = true;
                document.title = 'Action Needed';
                window.clearInterval(expirationIntervalId);
            }
        } else {
            window.clearInterval(expirationIntervalId);
        }
    }
    onMount(() => {
        if (localStorage.getItem('token') && !listenerSet) {
            setupTimers();
            loggedIn.set(true);
            listenerSet = true;
            startExpirationTimer();
        } else if (!localStorage.getItem('token')) {
            listenerSet = false;
        }
    });

    afterUpdate(() => {
        if (localStorage.getItem('token') && !listenerSet) {
            setupTimers();
            loggedIn.set(true);
            listenerSet = true;
            startExpirationTimer();
        } else if (!localStorage.getItem('token')) {
            listenerSet = false;
        }
    });

    const logOut = () => {
        loggedIn.set(false);
        $userDataStore = {
            email: '',
            trainer: null,
            client: null
        };
        localStorage.removeItem('expiring');
        localStorage.removeItem('token');
        window.clearTimeout(timeoutId);
        window.clearInterval(expirationIntervalId);
        document.removeEventListener('mousemove', resetTimer, false);
        document.removeEventListener('mousedown', resetTimer, false);
        document.removeEventListener('keypress', resetTimer, false);
        document.removeEventListener('touchmove', resetTimer, false);
        openModal = false;
        openExpireModal = false;
        extendSessionForm = false;
        push('/login');
    };

    const reroutePaths = ['clients', 'intake', 'plan'];

    async function routeLoaded(event) {
        const token = localStorage.getItem('token');
        if (!token && !$location.includes('password') && !$location.includes('sso')) {
            if (reroutePaths.includes($location.split('/')[1])) {
                $rerouteLink = $location;
            }
            push('/login');

        } else if (token) {
            if ($location.includes('password') || $location.split('/')[1] === 'login' || event.detail.location == ('/sso/authenticate')) {
                push('/');
            }
        }
    }

    $: if (localStorage.getItem('token') !== null) {
        getTrainerData(localStorage.getItem('token'));
    }

    async function getTrainerData(token) {
        let logOutUser = true;
        const { status: userStatus, data: userData } = await api.getUserInfo(token);
        if (userStatus < 400 && userData.trainer !== null) {
            const { status: trainerStatus, data: trainerData } = await api.request('get', `trainers/${userData.trainer}/`);
            if (trainerStatus < 400) {
                $userDataStore = userData;
                $trainerDataStore = trainerData;
                logOutUser = false;
            }
        }
        if (logOutUser) {
            logOut();
        }
    }

    $: {
        // Check url for http to change to https
        if (
            ENV === 'production' &&
            window.location.protocol !== 'https:'
        ) {
            window.location.replace(
                `https:${window.location.href.substring(window.location.protocol.length)}`
            );
        }
    }

    $: if ($location) {
        domFunctions.setBrowserTitle($location);
    }

    $: if (openExpireModal || openModal) {
        if (!secondsIntervalId) {
            seconds = 90;
            secondsIntervalId = window.setInterval(() => {
                seconds -= 1;
                if (seconds === 0) {
                    window.clearInterval(secondsIntervalId);
                    logOut();
                }
            }, 1000);
        }
    } else {
        window.clearInterval(secondsIntervalId);
        secondsIntervalId = null;
    }

    async function extendUserSession(email = null, password = null) {
        extendSessionForm = true;
        expireModalConfirmButton = 'Please Extend My Session';
        let error = false;
        let errorMessage = 'Something went wrong when we tried to extend your session: ';

        if (extendedSessionSuccessfully) {
            openExpireModal = false;
        }

        if (!extendedSessionSuccessfully && (email && password)) {
            const loginResponse = await api.login(email, password);
            extendingSession = true;
            extendSessionForm = false;
            expireModalBody = `Extending Your Session! </br> <div class="spin"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-aperture"><circle cx="12" cy="12" r="10"/><line x1="14.31" y1="8" x2="20.05" y2="17.94"/><line x1="9.69" y1="8" x2="21.17" y2="8"/><line x1="7.38" y1="12" x2="13.12" y2="2.06"/><line x1="9.69" y1="16" x2="3.95" y2="6.06"/><line x1="14.31" y1="16" x2="2.83" y2="16"/><line x1="16.62" y1="12" x2="10.88" y2="21.94"/></svg></div>`;
            expireModalConfirmButton = 'Extending...';
            if (loginResponse.status === 200) {
                setTimeout(() => {
                    extendedSessionSuccessfully = true;
                    expireModalCancelButton = 'Close';
                    expireModalBody = 'Your Session Has Been Successfully Extended!';
                    expireModalConfirmButton = 'Success!';
                    resetExpirationTimer();

                    setTimeout(() => {
                        extendingSession = false;
                        extendedSessionSuccessfully = false;
                        openExpireModal = false;
                        domFunctions.setBrowserTitle($location, $intakeDataStore.client_info.name, $planGeneratorStore.plan.meeting_date);
                    }, 2000);
                }, 2000);
            }
            if (loginResponse.status >= 400) {
                const { data: loginErrorData } = loginResponse;
                if (Array.isArray(loginErrorData)) {
                    loginErrorData.forEach((errorData) => {
                        if (errorMessage) {
                            errorMessage += `<br /> ${errorData}`;
                        } else {
                            errorMessage = `${errorData}`;
                        }
                    });
                } else {
                    Object.keys(loginErrorData).forEach((errorData) => {
                        if (errorMessage) {
                            errorMessage += `<br /> ${loginErrorData[errorData]}`;
                        } else {
                            errorMessage = `${loginErrorData[errorData]}`;
                        }
                    })
                }
                error = true;
            }
            if (loginResponse === undefined) {
                errorMessage = 'Something went wrong when we tried to extend your session, please try again!';
                error = true;
            }
            if (error) {
                setTimeout(() => {
                    expireModalBody = errorMessage;
                    expireModalConfirmButton = 'Extend My Session';
                    setTimeout(() => {
                        extendingSession = false;
                    }, 2000);
                }, 2000);
            }
        }
    }

    function cancelButtonFunction () {
        if (!extendedSessionSuccessfully) {
            logOut();
        }
    }

    $: if (!extendingSession) {
        expireModalBody = `Your session is going to expire in ${seconds}. <br/> Please extend or end your session.`;
        expireModalCancelButton = 'End My Session';
        if (!extendSessionForm) {
            expireModalConfirmButton = "Extend My Session";
        }
    }

    $: if (window.location.pathname !== '/') {
        window.location.pathname = '/';
    }
</script>

<Sidebar {logOut} />

<main class="main">
    <AppHeader {logOut} />
    <Modal
        on:showModal="{ (e) => openModal = e.detail }"
        modalTitle={"warning"}
        primaryModalButtonStyle={"danger"}
        modalBody={`You're about to be logged out for inactivity in ${seconds} seconds`}
        openModal={openModal}
        cancelButtonText={"Cancel, I am still here"}
        confirmButtonText={"OK, Log me out"}
        confirmButtonFunction={() => logOut()} />

    <Modal
        on:showModal="{ (e) => openExpireModal = e.detail }"
        modalTitle={"warning"}
        modalBody={expireModalBody}
        modalType={"extend"}
        openModal={openExpireModal}
        secondaryModalButtonStyle={"danger"}
        cancelButtonText={expireModalCancelButton}
        cancelButtonFunction={ !extendedSessionSuccessfully ? cancelButtonFunction : null }
        confirmButtonText={expireModalConfirmButton}
        confirmButtonFunction={(email, password) => extendUserSession(email, password)}
        extendSessionForm={extendSessionForm}
        renderButtons={!extendingSession} />

    <Router {routes} on:routeLoaded="{routeLoaded}" />
</main>
