<script>
    import { onMount, onDestroy, afterUpdate } from 'svelte';
    import { push, location } from 'svelte-spa-router';

    import Wizard from '../classes/Wizard/Wizard';
    import { IntakeDeepCompare } from '../classes/DeepCompare/IntakeDeepCompare';
    import { intakeDataStore } from '../shared/store';
    import * as api from '../shared/api_access';
    import * as domFunctions from '../helpers/dom_functions';
    import * as accountTypes from '../helpers/account_types';

    import Intake from './Form/Intake.svelte';
    import PlanGenerator from './Form/PlanGenerator.svelte';
    import FormButtons from '../components/UXElements/FormButtons.svelte';
    import FeatherIcon from '../components/UXElements/FeatherIcon.svelte';
    import FlyOutNotes from '../components/FlyOutNotes.svelte';

    export let params;

    let { id: clientId } = params;
    let loaded = false;
    const defaultIntake = JSON.stringify($intakeDataStore);
    let originalIntake;
    let saving = false;
    let error = false;
    let errorMessage = '';
    let wizard;

    const riskCategories = [...accountTypes.riskCategories];

    onMount(async () => {
        if (isNaN(parseInt(params.id))) {
            push('/not-found');
            return;
        }

        await getIntake();

        // Init event listeners
        window.addEventListener('beforeunload', domFunctions.promptUponClose);
    });

    afterUpdate(() => {
        // prevent non-numeric texts to be typed
        domFunctions.inputNumbersOnly();
    });

    onDestroy(async () => {
        // Save changes
        await updateIntake(true);
        $intakeDataStore = JSON.parse(defaultIntake);

        // Destroy event listeners
        window.removeEventListener('beforeunload', domFunctions.promptUponClose);
    });


    const getIntake = async () => {
        const { intakeResponse, clientResponse } = await api.getIntake(params.id);
        if (intakeResponse === undefined) {
            error = true;
            errorMessage = `An unexpected error occurred.`;
            saving = false;
        }

        if (intakeResponse.status === 200 && clientResponse.status === 200) {
            const intakeStore = {
                ...intakeResponse.data,
                client_info: clientResponse.data
            };

            // Add a notes object if empty
            if (intakeStore.notes.length === 0) {
                intakeStore.notes.push({
                    description: ''
                });
            }

            // Iterate though to check if one or many risk categories need to be added
            if (intakeStore.risks.length !== riskCategories.length) {
                riskCategories.forEach(entry => {
                    const filteredResultFound = intakeStore.risks.some(risk => {
                        return risk.risk_type.name === entry.name;
                    });
                    if (!filteredResultFound) {
                        intakeStore.risks.push({
                            possess: false,
                            description: '',
                            risk_type: {
                                name: entry.name,
                                value: entry.value
                            }
                        });
                    }
                });
            }

            if (intakeStore.expenses.length > 0) {
                intakeStore.expenses.forEach(expense => {
                    expense.edited = false;
                });
            }

            $intakeDataStore = intakeStore;
            originalIntake = JSON.stringify($intakeDataStore);

            clientName =
                $intakeDataStore.client_info.name !== undefined
                    ? $intakeDataStore.client_info.name
                    : '';
            wizard = new Wizard({ ...$intakeDataStore });
            setTimeout(() => {
                loaded = true;
            }, 500);
        }

        if (intakeResponse.status === 401 || clientResponse.status === 401) {
            push('/not-found');
        }

        if (intakeResponse.status > 401 || clientResponse.status > 401) {
            error = true;
            const { data } = intakeResponse;
            if (typeof data === 'object') {
                Object.keys(data).forEach((key, index) => {
                    if (index > 0) {
                        errorMessage += `<br/> ${key} - ${data[key]}`
                    } else {
                        errorMessage += `${key} - ${data[key]}`;
                    }
                });
            }

            if (typeof data === 'string') {
                if (errorMessage.length > 0) {
                    errorMessage += `<br/> ${data}`;
                } else {
                    errorMessage += data;
                }
            }
        }
    };

    const updateIntake = async (destroyed = null) => {
        let intakeResponse = null;
        error = false;
        saving = true;
        errorMessage = '';

        const intakePayload = new IntakeDeepCompare($intakeDataStore, JSON.parse(originalIntake));

        if (intakePayload.savePayload) {
            intakePayload.prunePayload();
            intakeResponse  = await api.updateIntake(params.id, {...intakePayload.payload});
        }

        if (!destroyed) {
            if (intakeResponse === null) {
                return 'synced';
            }

            if (intakeResponse) {
                if (intakeResponse.status === 200) {
                    await getIntake();
                    error = false;
                    errorMessage = '';
                }

                if (intakeResponse.status >= 400) {
                    error = true;
                    const { data } = intakeResponse;

                    if (typeof data === 'object') {
                        Object.keys(data).forEach((key, index) => {
                            if (index > 0) {
                                errorMessage += `<br/> ${key} - ${data[key]}`
                            } else {
                                errorMessage += `${key} - ${data[key]}`;
                            }
                        });
                    }

                    if (typeof data === 'string') {
                        if (errorMessage.length > 0) {
                            errorMessage += `<br/> ${data}`;
                        } else {
                            errorMessage += data;
                        }
                    }
                }
            }

            if (intakeResponse === undefined) {
                error = true;
                errorMessage = `An unexpected error occurred.`;
            }

            if (error) {
                return 'error';
            }

            return 'success';
        }

    };

    $: clientName =
        $intakeDataStore.client_info.name !== undefined ? $intakeDataStore.client_info.name : '';

    $: if (isNaN(parseInt(params.id))) {
        // redirect to Not Found
        // need to create a not found
        push('/not-found');
    }

    $: if (params.id !== clientId) {
        (async () => {
            if ($location.includes('intake') || $location.includes('plan')) {
                await getIntake();
            }
            clientId = params.id;
        })();
    }

    $: if ($location) {
        domFunctions.setBrowserTitle($location, $intakeDataStore.client_info.name);
    }
</script>

<div class="column column-full container w90">
    <div class="page-content">
        {#if ($location.split('/')[1] === 'intake') && loaded}
            <h1 class="page-title hide-print">
                { clientName }'s Intake
            </h1>

            <FormButtons
                saveFn={updateIntake}
                id={clientId}
                {errorMessage}
                {error}
                page="intake"
                bind:saving={saving}/>
        {/if}

        {#if loaded}
            {#if $location.includes('intake')}
                <Intake props="{params}" />
            {/if}
            {#if $location.includes('plan')}
                <PlanGenerator {wizard} props="{params}" {getIntake}/>
            {/if}
        {:else}
            <div class="spin"><FeatherIcon icon="aperture" /></div>
        {/if}
    </div>
</div>

<FlyOutNotes />

<style lang="stylus" scoped>
    .spin
        text-align center
        display block
</style>
