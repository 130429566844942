import Dashboard from '../containers/Dashboard.svelte';
import ClientInfo from '../components/ClientInfo.svelte';
import Form from '../containers/Form.svelte';
import Auth from '../containers/Auth.svelte';
import NotFound from '../containers/NotFound.svelte';


export const new_routes = {
    // Auth
    '/login': Auth,
    '/forgot-password': Auth,
    '/reset-password/:key': Auth,
    '/sso/authenticate': Auth,

    // Dashboard
    '/': Dashboard,

    // Client Info
    '/new-client': ClientInfo,
    '/clients/:id': ClientInfo,

    // Intake
    '/intake/:id/:section?': Form,
    // Plan
    '/plan/:id/:section?': Form,
    
    //Not Found
    '/not-found': NotFound,
    '*': NotFound
}
