const { DeepCompare } = require('./DeepCompare');

class GoalsDeepCompare extends DeepCompare {
    constructor(updatedObj, originalObj) {
        super(updatedObj, originalObj);
        this.dontConvertThese = [
            'id',
            'name',
            'goal_length',
            'description',
            'start_date',
            'end_date',
            'custom_date',
            'date_created'
        ];

        this.payload = [];

        this.deepCompare(updatedObj, originalObj);
    }

    deepCompareObj(updatedObj, originalObj, index = null) {
        Object.keys(updatedObj).forEach(key => {
            if (this.arrayTracker !== index) {
                if (key === 'goal_type') {
                    this.deepCompare(updatedObj[key], originalObj[key], index);
                    return false;
                }
                if (!this.dontConvertThese.includes(key)) {
                    if (!isNaN(parseFloat(updatedObj[key])) || !isNaN(parseFloat(originalObj[key]))) {
                        updatedObj[key] = parseFloat(updatedObj[key]);
                        originalObj[key] = parseFloat(originalObj[key]);
                    }
                }
                if (updatedObj[key] !== originalObj[key]) {
                    this.payload.push(updatedObj);
                    this.savePayload = true;
                    this.arrayTracker = index;
                    return false;
                }
            }
        });
    }

    deepCompareArrayOfObj(updatedObj, originalObj) {
        updatedObj.forEach((element, index) => {
            if (originalObj[index] !== undefined) {
                this.deepCompare(element, originalObj[index], index);
            } else {
                this.payload[this.tracker].push(element);
                this.savePayload = true;
                return false;
            }
        });
    }
}

module.exports = {
    GoalsDeepCompare
};
