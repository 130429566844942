<script>
    import accounting from '../../../shared/accounting_config';
    import calculations from '../../../helpers/calculations';

    import { planGeneratorStore } from '../../../shared/store';

    import NumberInput from '../../UXElements/NumberInput.svelte';

    export let slide;
    export let recommendedTotalContributionPercentage;
    export let totalBalance;
    export let totalAnnualContribution;
    export let futureValue;
    export let page = {};
    export let duplicateMounted = false;

    const calculateCurrentSavings = () => {
        const currentBalance = Number.isFinite(parseFloat(page.data.current_balance)) ? parseFloat(page.data.current_balance) : 0;
        const previousBalance = Number.isFinite(parseFloat(page.data.previous_balance)) ? parseFloat(page.data.previous_balance) : 0;
        const otherBalance = Number.isFinite(parseFloat(page.data.other_balance)) ? parseFloat(page.data.other_balance) : 0;
        const preTaxBalance = Number.isFinite(parseFloat(page.data.pre_tax_balance)) ? parseFloat(page.data.pre_tax_balance) : 0;
        const postTaxBalance = Number.isFinite(parseFloat(page.data.post_tax_balance)) ? parseFloat(page.data.post_tax_balance) : 0;

        page.data.total_balance = currentBalance + previousBalance + otherBalance + preTaxBalance + postTaxBalance;
    }

    const calculateTotalContributionPercentage = () => {
        const currentPercentage = Number.isFinite(parseFloat(page.data.current_contribution_percentage)) ? parseFloat(page.data.current_contribution_percentage) : 0;
        const otherPercentage = Number.isFinite(parseFloat(page.data.other_contribution_percentage)) ? parseFloat(page.data.other_contribution_percentage) : 0;
        const preTaxPercentage = Number.isFinite(parseFloat(page.data.pre_tax_contribution_percentage)) ? parseFloat(page.data.pre_tax_contribution_percentage) : 0;
        const postTaxPercentage = Number.isFinite(parseFloat(page.data.post_tax_contribution_percentage)) ? parseFloat(page.data.post_tax_contribution_percentage) : 0;

        page.data.total_contribution_percentage = currentPercentage + otherPercentage + preTaxPercentage + postTaxPercentage;
    }

    const calculateAnnualContribution = () => {
        const currentSalary = Number.isFinite(parseFloat(page.data.retirement_current_salary)) ? parseFloat(page.data.retirement_current_salary) : 0;
        const recommendedSalary = Number.isFinite(parseFloat(page.data.retirement_recommended_salary)) ? parseFloat(page.data.retirement_recommended_salary) : 0;
        const totalContributionPercentage = Number.isFinite(parseFloat(page.data.total_contribution_percentage)) ? parseFloat(page.data.total_contribution_percentage) : 0;

        page.data.total_annual_contribution = Math.round((slide === 'current' ? currentSalary : recommendedSalary) * totalContributionPercentage / 100)
    }

    const calculateFutureValue = () => {
        const yearsUntilRetirement = Number.isFinite(parseFloat(page.data.years_until_retirement)) ? parseFloat(page.data.years_until_retirement) : 0;
        const totalAnnualContribution = Number.isFinite(parseFloat(page.data.total_annual_contribution)) ? parseFloat(page.data.total_annual_contribution) : 0;
        const totalBalance = Number.isFinite(parseFloat(page.data.total_balance)) ? parseFloat(page.data.total_balance) : 0;
        const expectedReturn = Number.isFinite(parseFloat(page.data.expected_return)) ? parseFloat(page.data.expected_return) : 0;

        page.data.future_value = Math.round(
            calculations.getFutureValue(
                yearsUntilRetirement,
                totalAnnualContribution,
                totalBalance,
                expectedReturn
            )
        );
    }


    $: if (page.duplicate && !duplicateMounted) {
        if (!page.id) {
            page.data = {
                ...page.data,
                years_until_retirement: parseFloat($planGeneratorStore.plan.years_until_retirement).toFixed(0),
                expected_return: 7,
                retirement_current_salary: parseFloat($planGeneratorStore.plan.retirement_current_salary).toFixed(0),
                retirement_recommended_salary: parseFloat($planGeneratorStore.plan.retirement_recommended_salary).toFixed(0),
                total_contribution_percentage: slide === 'current' ? parseFloat($planGeneratorStore.plan.retirement_current_contribution_percentage).toFixed(2) : parseFloat(recommendedTotalContributionPercentage).toFixed(2),
                total_balance: parseFloat(totalBalance).toFixed(0),
                total_annual_contribution: parseFloat(totalAnnualContribution).toFixed(0),
                future_value: parseFloat(futureValue).toFixed(0),
            };

        }
    }

    $: if (page.data) {
        calculateTotalContributionPercentage();
        calculateCurrentSavings();
        calculateAnnualContribution();
        calculateFutureValue();
    }
</script>

<table class="two-column-table">
    <thead>
        <tr>
            <th>Current Savings</th>
            <th>
                {#if !page.duplicate}
                    {accounting.formatMoney(totalBalance)}
                {:else}
                    {accounting.formatMoney(page.data.total_balance)}
                {/if}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Annual Contributions</td>
            <td>
                {#if !page.duplicate}
                    <div class="hide-print grid-row grid-contribution">
                        {#if slide === 'current'}
                            <NumberInput
                                placeholder="Salary"
                                type="dollar"
                                bind:value={$planGeneratorStore.plan.retirement_current_salary} />
                            x
                            <NumberInput
                                placeholder="Contribution"
                                type="percent"
                                bind:value={$planGeneratorStore.plan.retirement_current_contribution_percentage} />
                        {:else}
                            <NumberInput
                                placeholder="Salary"
                                type="dollar"
                                bind:value={$planGeneratorStore.plan.retirement_recommended_salary} />
                                x
                            <span>
                                {accounting.toFixed(recommendedTotalContributionPercentage, 2)}%
                            </span>
                        {/if}
                        =
                        {accounting.formatMoney(totalAnnualContribution)}
                    </div>
                    <div class="show-print">
                        {accounting.formatMoney(totalAnnualContribution)}
                    </div>
                {:else}
                    <div class="hide-print grid-row grid-contribution-duplicate">
                        {#if slide === 'current'}
                            <NumberInput
                                placeholder="Salary"
                                type="dollar"
                                bind:value={page.data.retirement_current_salary} />
                            x
                            {accounting.toFixed(page.data.total_contribution_percentage, 2)}%
                        {:else}
                            <NumberInput
                                placeholder="Salary"
                                type="dollar"
                                bind:value={page.data.retirement_recommended_salary} />
                            x
                            {accounting.toFixed(page.data.total_contribution_percentage, 2)}%
                        {/if}
                        =
                        {accounting.formatMoney(page.data.total_annual_contribution)}
                    </div>
                    <div class="show-print">
                        {accounting.formatMoney(page.data.total_annual_contribution)}
                    </div>
                {/if}
            </td>
        </tr>
        <tr>
            <td>Years Until Retirement</td>
            <td>
                {#if !page.duplicate}
                    <NumberInput placeholder="Years Until Retirement" type="target" bind:value={$planGeneratorStore.plan.years_until_retirement} />
                    <span class="show-print">
                        {$planGeneratorStore.plan.years_until_retirement ? $planGeneratorStore.plan.years_until_retirement : ''}
                    </span>
                {:else}
                    <NumberInput placeholder="Years Until Retirement" type="target" bind:value={page.data.years_until_retirement} />
                    <span class="show-print">
                        {page.data.years_until_retirement ? page.data.years_until_retirement : ''}
                    </span>
                {/if}
            </td>
        </tr>
        <tr>
            <td>Expected Return (%)</td>
            <td>
                {#if !page.duplicate}
                    7%
                {:else}
                    <div class="input-group hide-print">
                        <NumberInput placeholder="0" type="percentage" bind:value={page.data.expected_return} />
                    </div>
                    <span class="show-print">{page.data.expected_return}%</span>
                {/if}
            </td>
        </tr>
        <tr>
            <td><strong>Expected Future Value</strong></td>
            <td>
                {#if !page.duplicate}
                    <strong>{accounting.formatMoney(futureValue)}</strong>
                {:else}
                    <strong>{accounting.formatMoney(page.data.future_value)}</strong>
                {/if}
            </td>
        </tr>
    </tbody>
</table>

<style lang="stylus" scoped>
    .grid-row
        margin-bottom 0

    @media screen and (max-width 480px)
        td
            padding 8px
    @media screen and (min-width 640px)
        .grid-row.grid-contribution
            grid-template-columns: 4fr 1fr 3fr 2fr;
    @media screen and (min-width 800px)
        .grid-row.grid-contribution-duplicate
            grid-template-columns: 5fr 3fr
</style>
