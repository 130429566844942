<script>
    import dayjs from 'dayjs';

    import { intakeDataStore, trainerDataStore, planGeneratorStore } from '../../shared/store';
    
    import AssetImage from '../UXElements/AssetImage.svelte';
</script>

<header class="center">
    <AssetImage 
        src="images/FG_Logo_750x64.png"
        alt="FinGymLogo" />
    <h2>Financial Fitness Plan</h2>
    <p>
        Prepared for: {$intakeDataStore.client_info.name}
        <br />
        By: {$trainerDataStore.first_name} {$trainerDataStore.last_name}
        <br />
        Meeting Date:
        <input
            id="meeting-date"
            class="hide-print"
            type="date"
            bind:value="{$planGeneratorStore.plan.meeting_date}" />
        <span class="show-print">
            {dayjs($planGeneratorStore.plan.meeting_date).format('MMMM D, YYYY')}
        </span>
    </p>
</header>

<style lang="stylus" scoped>
    .center
        text-align center

    #meeting-date
        width 175px
</style>