<script>
    import QuillComponent from '../UXElements/QuillComponent.svelte';

    export let page;
    export let index;
</script>

<div>
    <div>{page.title}</div>
    <QuillComponent type="{`${index}`}" data={page.data}/>
</div>
