<script>
    import { createEventDispatcher } from 'svelte';
    import { location } from 'svelte-spa-router';

    import FeatherIcon from './FeatherIcon.svelte';
    import { userDataStore, intakeDataStore, planGeneratorStore } from '../../shared/store';
    import * as domFunctions from '../../helpers/dom_functions';

    const dispatch = createEventDispatcher();

    export let modalTitle = null;
    export let modalType = null;
    export let primaryModalButtonStyle = null;
    export let secondaryModalButtonStyle = null;
    export let modalBody = null;
    export let cancelButtonText = 'Close';
    export let cancelButtonFunction = null;
    export let confirmButtonFunction = null;
    export let confirmButtonText = null;
    export let openModal = null;
    export let extendSessionForm = null;
    export let renderButtons = true;

    let password = null;
    let email = null;

    const closeModal = () => {
        dispatch('showModal', false);
        if (cancelButtonFunction) {
            cancelButtonFunction();
        }

        if (document.title === 'Action Needed') {
            domFunctions.setBrowserTitle($location, $intakeDataStore.client_info.name, $planGeneratorStore.plan.meeting_date);
        }
    }

    $: if (!extendSessionForm) {
        password = null;
    }

    $: email = $userDataStore.email;
</script>

{#if openModal}
    <div class="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="panel" role="document">
            <div class="modal-header">
                <h2 class="page-title" id="modalLabel">
                    <FeatherIcon icon='alert-triangle'/>{modalTitle.toUpperCase()}
                </h2>
                {#if modalType !== 'extend'}
                    <button
                        on:click|preventDefault="{() => closeModal() }"
                        class="alert button icon-button"
                        data-dismiss="modal"
                        title="Close"
                        aria-label="Close modal"
                    >
                        <FeatherIcon icon="x" />
                    </button>
                {/if}
            </div>
            <form>
                <div class="modal-body">
                    {@html modalBody}
                    {#if extendSessionForm}
                        <div class="field">
                            <label for="email">Email address</label>
                            <input
                                type="email"
                                class="form-control"
                                id="email"
                                disabled
                                aria-describedby="email-help"
                                bind:value="{email}" />
                        </div>
                        <div class="field">
                            <label for="password">Password</label>
                            <input
                                type="password"
                                class="form-control"
                                id="password"
                                bind:value="{password}" />
                        </div>
                    {/if}
                </div>
                <div class="modal-footer">
                    {#if renderButtons}
                        <button
                            on:click|preventDefault="{() => closeModal() }"
                            type="button"
                            class="button small secondary btn-{!secondaryModalButtonStyle ? '' : secondaryModalButtonStyle}"
                            data-dismiss="modal"
                        >{cancelButtonText}</button>
                        <button
                            type="{extendSessionForm ? 'submit' : 'button'}"
                            class="button small primary btn-{!primaryModalButtonStyle ? 'success' : primaryModalButtonStyle}"
                            data-dismiss="modal"
                            on:click|preventDefault={() => confirmButtonFunction(email, password)}
                            >
                            {confirmButtonText}
                        </button>
                    {/if}
                </div>
            </form>
        </div>
    </div>
{/if}

<style lang="stylus" scoped>        
    .modal
        background rgba(0, 0, 0, 0.75)
        bottom 0
        left 0
        position fixed
        right 0
        top 0
        z-index 501

        .panel
            display block
            left 50%
            position fixed
            text-align center
            transform: translate(-50%, -50%);
            top 50%
            width 50%

        .modal-body
            padding 1em 1em 3em 1em
            
            &::first-letter
                text-transform capitalize

        .page-title
            color var(--color-red)
            font-size 32px

        .alert
            color var(--color-orange)
            position absolute
            right 5px
            top 5px

    @media screen and (max-width 480px)
        button
            &:first-child
                line-height 25px
                padding 10px 0

        .modal
            .panel
                width 75%

    @media screen and (max-width 768px)
        button
            width 100%
            &:first-child
                margin-bottom 10px


</style>
