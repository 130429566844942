<script>
    import { onMount } from 'svelte';

    import { intakeDataStore } from '../../shared/store';

    import * as accountTypes from '../../helpers/account_types';

    import ALRowComponent from './BaseALComponents/ALRowComponent.svelte';

    export let type;
    export let show;

    let categories = [];
    let ownedCategories = [];

    if (type === 'assets') {
        categories = [...accountTypes.assetCategories];
    } else if (type === 'liabilities') {
        categories = [...accountTypes.liabilityCategories];
    }

    let activeCategory = categories.length > 0 ? categories[0].value : '';

    /**
     * Restructure categories
     */
    categories.forEach((category, index) => {
        categories[index] = {
            name: category.value,
            numberOf: 0
        };
    });

    const updateCategories = () => {
        ownedCategories = [];

        $intakeDataStore[type].forEach(storeKey => {
            categories.forEach((entry, index) => {
                if (entry.name === storeKey.category) {
                    if (!ownedCategories.includes(storeKey.category)) {
                        ownedCategories.push(storeKey.category);
                        categories[index].numberOf = 1;
                    } else {
                        categories[index].numberOf++;
                    }
                }
            });
        });
    }

    const subtractCategory = (e) => {
        categories.forEach((category, index) => {
            if (category.name === e.detail.categoryName) {
                categories[index].numberOf--;
            }
        });
    }

    onMount(() => {
        // Cycle through the prop type's category in the store and
        // check the boxes for ones that already exist
        updateCategories();
    });

    $: if ($intakeDataStore) {
        updateCategories();
    }
</script>

<div class="{show ? 'collapse show' : 'collapse'}" aria-labelledby="Assets" data-parent="#assets">
    <div class="flex-wrapper main-wrapper container">
        <div class="column column-single">
            <div class="plaid-status container">
                <h3 class="panel-title">Categories</h3>
                <div class="panel">
                    <table class="plaid-status-table">
                        {#each categories as category}
                        <tr on:click="{ () => { activeCategory = category.name; updateCategories(); } }">
                            <td>
                                <h4>
                                    {category.name.split('_').join(' ')} <span class="text-muted">({category.numberOf})</span>
                                </h4>
                            </td>
                            <td class="indicator">
                                {#if activeCategory === category.name}
                                    <span class="green"></span>
                                {:else if ownedCategories.includes(category.name)}
                                    <span class="outline"></span>
                                {:else}
                                    <span class="outline inactive"></span>
                                {/if}
                            </td>
                        </tr>
                        {/each}
                    </table>
                </div>
            </div>
        </div>

        <div class="column column-double">
            <h3 class="panel-title">{ activeCategory.split('_').join(' ') }</h3>
            <div class="panel">
            {#each categories as category}
                {#if activeCategory === category.name}
                    <ALRowComponent {type} category="{category.name}" on:subtractCategory="{ subtractCategory }" />
                {/if}
            {/each}
            </div>
        </div>
    </div>
</div>

<style lang="stylus" scoped>
    h4
        cursor pointer
        margin 0.2em
        text-transform capitalize

        input
            visibility hidden
    tr
        cursor pointer

    .panel-title
        text-transform capitalize

    .plaid-status .panel
        padding 0

    .text-muted
        color var(--color-gray-medium)

    @media screen and (max-width: 1024px)
        .flex-wrapper
            display block

            .column-single
                margin-right 0

            .panel
                height auto

</style>
